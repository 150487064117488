import UserToolbarService from './user-toolbar/user-toolbar.srv';
import userToolbarDirectiveFactory from './user-toolbar/user-toolbar.drv';
import headerDirectiveFactory from './header/header.drv';
import footerDirectiveFactory from './footer/footer.drv';
import ArticleService from '../article/article-service/article.srv';
import SubmitTicketService from '../submit/submit-a-ticket-service/submit-a-ticket.srv';

angular.module('structure', ['common', 'ngSanitize', 'core', 'auth'])
	.directive('mainHeader', headerDirectiveFactory)
	.directive('mainFooter', footerDirectiveFactory)
	.directive('userToolbar', userToolbarDirectiveFactory)
	.service('submitTicketService', SubmitTicketService)
	.service('articleService', ArticleService)
	.service('userToolbarService', UserToolbarService);
