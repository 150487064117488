import { encodeEntity } from './utils/code-renderer';
import { fixDoubleSpacesInHtml, convertTrixImagesToSimpleImgTags } from './utils/common-utils';
import { stepByStepLayouts } from './utils/sbs-layouts';
import { decodeEntity } from './utils/code-renderer';
import { informativeTypeToTitle, infromativeTypeToColor } from './utils/informative-utils';
/* tslint:disable */
export var componentsDeserializer = function ($, locale) {
    return {
        code: function ($node) {
            var decodedCode = decodeEntity($node.find('code').text());
            var lang = $node.attr('data-language');
            var legacyTypes = ['markup', 'text'];
            return {
                language: legacyTypes.indexOf(lang) !== -1 ? 'html' : lang,
                lineNumbers: $node.attr('data-line-numbers') === 'true' ? true : false,
                lineWrapping: $node.attr('data-line-wrapping') === 'true' ? true : false,
                code: decodedCode
            };
        },
        collapsible: function ($node) {
            var title = $node.find('.collapsible-title').text();
            var htmlContent = $node.find('.collapsible-content').html();
            var htmlWithConvertedTrixImages = convertTrixImagesToSimpleImgTags(htmlContent, $);
            return {
                title: title,
                html: htmlWithConvertedTrixImages
            };
        },
        heading: function ($node) {
            return {
                text: $node.text(),
                anchorId: $node.attr('id') || '',
                level: parseInt($node.attr('level'), 10) || 3
            };
        },
        html: function ($node) {
            return {
                html: $('<div>').append($node).html() || ''
            };
        },
        iframe: function ($node) {
            var $iframe = $node.find('iframe');
            var width = ($iframe.css('width').indexOf('%') === -1) ? parseInt($iframe.css('width'), 10) : null;
            var height = ($iframe.css('height').indexOf('%') === -1) ? parseInt($iframe.css('height'), 10) : null;
            return {
                url: $iframe.attr('src'),
                width: width,
                height: height,
                align: $node.css('text-align') || 'center',
                scrolling: $iframe.attr('scrolling') || 'auto'
            };
        },
        image: function ($node) {
            var $img = $node.is('img') ? $node : $node.find('img');
            var $link = $node.find('a');
            return {
                src: $img.attr('src'),
                alt: $img.attr('alt') || '',
                link: $link.attr('href') || '',
                align: 'center'
            };
        },
        informative: function ($node) {
            var maybeType = $node.attr('info-type');
            var color = '';
            if (maybeType) {
                color = infromativeTypeToColor(maybeType);
            }
            else {
                $node.toArray().map(function (elem) {
                    var $elem = $(elem);
                    if ($elem.attr('info-color')) {
                        color = $elem.attr('info-color');
                    }
                });
            }
            var title = maybeType ? informativeTypeToTitle(maybeType, locale) : $node.find('.info-title')[0] ? $node.find('.info-title').text() : null;
            var htmlContent = $('.info-content', $node).html();
            var htmlWithConvertedTrixImages = convertTrixImagesToSimpleImgTags(htmlContent, $);
            return {
                html: htmlWithConvertedTrixImages,
                color: color,
                title: title
            };
        },
        line: function ($node) {
            return { style: $node.attr('line-style') || 'thin' };
        },
        markdown: function ($node) {
            var oldHtmlNodeToValue = function ($node) {
                var encodedMarkdown = $node.find('.encoded-markdown');
                if (encodedMarkdown.toArray().length === 0) {
                    return null;
                }
                encodedMarkdown = encodedMarkdown.text();
                var decodedMarkdown = atob(encodedMarkdown);
                return {
                    markdown: decodedMarkdown,
                    renderedHtml: ''
                };
            };
            var oldValue = oldHtmlNodeToValue($node);
            if (oldValue === null || oldValue === void 0 ? void 0 : oldValue.markdown) {
                return oldValue;
            }
            else {
                var originalMarkdown = $node.find('.original-markdown').html();
                var originalMarkdownDecoded = decodeEntity(originalMarkdown);
                var renderedHtmlNode = $node.find('.markdown-rendered-html');
                var renderedHtml = renderedHtmlNode ? renderedHtmlNode.html() : '';
                return {
                    markdown: originalMarkdownDecoded,
                    renderedHtml: renderedHtml
                };
            }
        },
        'step-by-step': function ($node) {
            var steps = $node.find('.step')
                .toArray()
                .map(function (step) {
                var $step = $(step);
                var $img = $step.find('.step-image');
                var $desc = $step.find('.step-description');
                var $title = $step.find('.step-title');
                return {
                    html: $desc.html(),
                    title: $title.text(),
                    image: {
                        src: $img.attr('src'),
                        alt: $img.attr('alt') || '',
                        link: $img.attr('href') || ''
                    }
                };
            });
            var sbsLayout = stepByStepLayouts.filter(function (layout) {
                return layout.key === $node.attr('data-layout');
            })[0].key || stepByStepLayouts[0].key;
            return {
                layout: sbsLayout,
                allowCircular: $node.attr('data-allow-circular') === 'true',
                steps: steps
            };
        },
        table: function ($node) {
            var headers = $node.find('th').toArray();
            var hasOverridenWidth = headers.some(function (header) { return !!$(header).data('column-width'); });
            var columnWidths = hasOverridenWidth
                ? headers.map(function (elm) { return +$(elm).data('column-width'); })
                : undefined;
            return {
                rows: $node.find('tbody tr').length,
                columns: headers.length,
                headings: headers.map(function (elm) {
                    return $(elm).html();
                }),
                cells: $node
                    .find('tbody tr')
                    .toArray()
                    .map(function (elm) { return $(elm).find('td')
                    .toArray()
                    .map(function (elm) { return $(elm).html(); }); }),
                columnWidths: columnWidths
            };
        },
        tabs: function ($node) {
            var tabsValue = {
                headings: [],
                contents: [],
                count: 0,
                recommendedTab: undefined
            };
            $node.find('.tab-heading')
                .toArray()
                .map(function (heading, index) {
                var _a;
                if ((_a = heading.className) === null || _a === void 0 ? void 0 : _a.includes("recommended")) {
                    tabsValue.recommendedTab = index;
                }
                tabsValue.headings[index] = $(heading).text();
            });
            $node.find('.tab-content')
                .toArray()
                .map(function (tabContentComponents, tabIndex) {
                tabsValue.contents[tabIndex] =
                    Array.from(tabContentComponents.children).map(function (component) {
                        var type = $(component).attr('data-component-type');
                        var value = componentsDeserializer($, locale)[type]($(component).contents());
                        return { type: type, value: value };
                    });
            });
            tabsValue.count = tabsValue.headings.length;
            return tabsValue;
        },
        text: function ($node) {
            var htmlWithFixedDoubleSpaces = fixDoubleSpacesInHtml($node.parent().html());
            var htmlWithConvertedTrixImages = convertTrixImagesToSimpleImgTags(htmlWithFixedDoubleSpaces, $);
            return {
                html: htmlWithConvertedTrixImages || ''
            };
        },
        video: function ($node) {
            var $iframe = $node.find('iframe');
            var width = ($iframe.css('width').indexOf('%') === -1) ? parseInt($iframe.css('width'), 10) : null;
            var height = ($iframe.css('height').indexOf('%') === -1) ? parseInt($iframe.css('height'), 10) : null;
            return {
                url: $iframe.attr('src'),
                width: width,
                height: height,
                align: $node.css('text-align'),
                invalidUrl: false
            };
        }
    };
};
export var componentsSerializer = {
    code: function (value) {
        var encodedCode = encodeEntity(value.code);
        return "<div class=\"code-snippet\" data-language=\"" + value.language + "\" data-line-numbers=\"" + value.lineNumbers + "\" data-line-wrapping=\"" + value.lineWrapping + "\"><pre><code>" + encodedCode + "</code></pre></div>";
    },
    collapsible: function (value) {
        return "<div><h4 class=\"collapsible-title\">" + value.title + "</h4><div class=\"collapsible-content\">" + value.html + "</div></div>";
    },
    heading: function (value) {
        var encoded = encodeEntity(value.text);
        return "<h2 id=\"" + value.anchorId + "\" level=\"" + value.level + "\">" + encoded + "</h2>";
    },
    html: function (value) {
        return value.html;
    },
    iframe: function (value) {
        var width = value.width ? value.width + 'px' : '600px';
        var height = value.height ? value.height + 'px' : '400px';
        return "<div class=\"iframe-wrapper\" style=\"text-align:" + value.align + "\"><iframe src=\"" + value.url + "\" style=\"width:" + width + ";height:" + height + ";\" scrolling=\"" + value.scrolling + "\"></iframe></div>";
    },
    image: function (value) {
        var imageHtml = "<img src=\"" + value.src + "\" " + (value.alt ? 'alt="' + value.alt + '"' : '') + "/>";
        var innerHtml = imageHtml;
        if (value.link) {
            innerHtml = "<a href=\"" + value.link + "\">" + innerHtml + "</a>";
        }
        return "<div class=\"img-container\" style=\"text-align:" + value.align + "\">" + innerHtml + "</div>";
    },
    informative: function (value) {
        var maybeTitle = value.title !== null ? "<div class=\"info-title\">" + value.title + "</div>" : '';
        return "<div class=\"info-container\" info-color=\"" + value.color + "\">" + maybeTitle + "<div class=\"info-content\">" + value.html + "</div></div>";
    },
    line: function (value) {
        return "<hr line-style=\"" + value.style + "\" />";
    },
    markdown: function (value) {
        var html = value.renderedHtml ? value.renderedHtml : '';
        var originalMarkdownEncoded = encodeEntity(value.markdown);
        return "<div><div class=\"markdown-rendered-html\">" + html + "</div><div class=\"original-markdown\" style=\"display: none\">" + originalMarkdownEncoded + "</div></div>";
    },
    'step-by-step': function (value) {
        var steps = value.steps;
        var stepsHtml = steps.map(function (step) {
            return "<section class=\"step\"><div class=\"step-title\">" + step.title + "</div><img class=\"step-image\" src=\"" + step.image.src + "\" href=\"" + step.image.link + "\" alt=\"" + step.image.alt + "\" /><div class=\"step-description\">" + step.html + "</div></section>";
        }).join('');
        value.layout = value.layout || 'vertical';
        return "<div class=\"step-by-step-container\" data-layout=\"" + value.layout + "\" data-allow-circular=\"" + value.allowCircular + "\">" + stepsHtml + "</div>";
    },
    table: function (value) {
        var headContent = '', bodyContent = '';
        var columnWidths = value.columnWidths;
        for (var col = 0; col < value.columns; col++) {
            var maybeWidthDataAttr = columnWidths ? " data-column-width=" + columnWidths[col] : '';
            headContent += "<th" + maybeWidthDataAttr + ">" + (value.headings[col] || '') + "</th>";
        }
        for (var row = 0; row < value.rows; row++) {
            bodyContent += '<tr>';
            for (var col = 0; col < value.columns; col++) {
                bodyContent += "<td>" + (value.cells[row] && value.cells[row][col] ? value.cells[row][col] : '') + "</td>";
            }
            bodyContent += '</tr>';
        }
        return "<div class=\"table-wrapper\"><table><thead><tr>" + headContent + "</tr><tbody>" + bodyContent + "</tbody></table></div>";
    },
    tabs: function (value) {
        var headings = '<div class="headings-wrapper">';
        value.headings.map(function (heading, index) {
            var isRecommended = value.recommendedTab === index ? 'recommended' : '';
            headings += "<div class=\"tab-heading heading-" + index + " " + isRecommended + "\">" + heading + "</div>";
        });
        headings += '</div>';
        var contents = '<div class="tab-contents-wrapper">';
        value.contents.map(function (content, index) {
            contents += "<div class=\"tab-content\">";
            contents += content.map(function (component) {
                var type = component.type;
                var value = component.value;
                var innerValue = componentsSerializer[type](value);
                return "<div data-component-type=\"" + type + "\">" + innerValue + "</div>";
            }).join('');
            contents += "</div>";
        });
        contents += '</div>';
        return "<div class=\"tabs-wrapper\">" + headings + contents + "</div>";
    },
    text: function (value) {
        return fixDoubleSpacesInHtml(value.html);
    },
    video: function (value) {
        var width = value.width ? value.width + 'px' : '100%';
        var height = value.height ? value.height + 'px' : '100%';
        return "<div style=\"text-align:" + value.align + "\"><span class=\"video-overlay\" style=\"width:" + value.width + "px; height:" + value.height + "px\">\n\t\t</span><iframe src=\"" + value.url + "\" style=\"width:" + width + ";height:" + height + ";\"></iframe></div>";
    }
};
