import Auth from '../../auth/auth.srv';
import * as ans from '@wix/answers-api';
import { ToolbarContextSections, CategoryContext, ArticleContext, ContextData, TicketContext } from '@wix/answers-common-components';
import ArticleService from '../../article/article-service/article.srv';
export default class UserToolbarService {
	renderComp: () => void = null;

	/*@ngInject*/
	constructor(private $rootScope, private articleService: ArticleService, private $timeout) {}

	init(renderComp: () => void) {
		this.renderComp = renderComp;
	}

	safelyRerenderToolbar() {
		if (this.renderComp) {
			this.renderComp();
		}
	}

	setNoneContextView() {
		this.$rootScope.userToolbarVisibileContext = ToolbarContextSections.NONE;
		this.$rootScope.contextData = {};
		this.safelyRerenderToolbar();
	}

	setCategoryContextView(categoryContext: CategoryContext) {
		this.$rootScope.userToolbarVisibileContext = ToolbarContextSections.CATEGORY;
		this.$rootScope.contextData = {categoryContext};
		this.safelyRerenderToolbar();
	}

	toggleCategoryFollow(isFollowing: boolean) {
		if (this.$rootScope.contextData && this.$rootScope.contextData.categoryContext) {

			const categoryContext: CategoryContext = {
				onFollowToggle: this.$rootScope.contextData.categoryContext.onFollowToggle,
				isFollowing
			};

			this.$rootScope.contextData = {categoryContext};
			this.safelyRerenderToolbar();
		}
	}

	setArticleContextView(context: ArticleContext) {
		let articleCounters = undefined;

		this.$rootScope.userToolbarVisibileContext = ToolbarContextSections.ARTICLE;

		this.articleService.getArticleItemCounters(context.article)
			.then((counters: ans.Counters) => {
				articleCounters = counters;
			})
			.catch((e) => {
				console.error('Error getting article counters for User toolbar', e);
			})
			.finally(() => {
				this.$rootScope.contextData = {
					articleContext: {...context, counters: articleCounters}
				};
				this.safelyRerenderToolbar();
			});
	}

	toggleArticleFollow(updatedContext: ArticleContext) {
		if (this.$rootScope.contextData && this.$rootScope.contextData.articleContext) {
			this.$rootScope.contextData = {articleContext: updatedContext};
			this.safelyRerenderToolbar();
		}
	}

	setTicketContextView(ticketContext: TicketContext) {
		const contextData: ContextData = {
			ticketContext
		};

		this.$rootScope.userToolbarVisibileContext = ToolbarContextSections.TICKET;
		this.$rootScope.contextData = contextData;
		this.safelyRerenderToolbar();
	}

	toggleTicketFollow(updatedContext: TicketContext) {
		if (this.$rootScope.contextData && this.$rootScope.contextData.ticketContext) {
			this.$rootScope.contextData = {ticketContext: updatedContext};
			this.safelyRerenderToolbar();
		}
	}
}
