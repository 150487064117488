/// <reference path="../references.ts" />
import UsersService from './users.srv';
import * as ans from '@wix/answers-api';
import SeoManager from '../seo/seo-manager.srv';
import Tickets from './../ugc/ticket-service/tickets.srv';
import Prerender from './../common/prerender/prerender.srv';
import Auth from './../auth/auth.srv';

/*@ngInject*/
function userPageRouting($stateProvider: ng.ui.IStateProvider) {

	$stateProvider
	.state('app.user-bw-compat', {
		url: '/ugc/:id',
		onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
			const correctUrl = appConfig.helpCenterUrls[appConfig.lang] + '/user/' + $stateParams.id;
			if (prerender.isPrerenderMode()) {
				prerender.setPermanentRedirect(correctUrl);
			} else {
				$state.go('app.user', {id: $stateParams.id}, {location: 'replace'});
			}
		}
	})
	.state('app.user-me', {
		url: '/user/me',
		resolve: {
			user: function(auth: Auth) {
				return auth.init();
			}
		},
		controller: function (user: any, $state, auth: Auth) {
			if (user) {
				$state.go('app.user', {id: user.id});
			} else {
				auth.goToSignin();
			}
		}
	})
	.state('app.user', {
		url: '/user/:id',
		template: '<user-page user-tickets="::page.userTickets" user="::page.user" followed-content="::page.followedContent"></user-page>',
		resolve: {
			userTickets: function (tickets: Tickets, $stateParams) {
				return tickets.searchByUser($stateParams.id);
			},
			user: function (usersService: UsersService, $stateParams, $state: ng.ui.IStateService, seoManager: SeoManager, appConfig, prerender: Prerender, auth: Auth) {
				return auth.init()
				.then(_ => {
					if (auth.isLoggedIn() && auth.getCurrentUser().id === $stateParams.id) {
						return usersService.getCurrentUser()
						.then((user) => {
							return user;
						})
						.catch((res) => {
							switch (res.status) {
								case ans.HttpStatus.NOT_FOUND:
									if (prerender.isPrerenderMode()) {
										prerender.setStatusCode(ans.HttpStatus.NOT_FOUND);
									}
									$state.go('app.404');
									break;
								default:
									$state.go('app.error', {error: res});
									break;
							}
						});
					} else {
						if (prerender.isPrerenderMode()) {
							$state.go('app.user-restricted');
						} else {
							auth.init()
							.then(_ => {
								return auth.isLoggedIn() ? $state.go('app.user-restricted') : auth.accountRedirect('signin');
							});
						}
					}
				});
			},
			followedContent: function (usersService: UsersService, $stateParams) {
				return usersService.getUserFollowedContent($stateParams.id);
			}
		},
		controller: function(userTickets, user, followedContent) {
			this.userTickets = userTickets;
			this.user = user;
			this.followedContent = followedContent;
		},
		controllerAs: 'page'
	})
	.state('app.user-restricted', {
		onEnter: function ($state) {
			$state.go('app.403');
		}
	})
	.state('app.user.settings', {
		url: '/settings',
		template: '<user-settings-modal user="page.user"></user-settings-modal>',
		data: {
			statusCode: 403
		},
		controller: function (user) {
			this.user = user;
		},
		controllerAs: 'page'
	});
};

export = userPageRouting;
