/// <reference path="../../references.ts" />
'use strict';

import { ContactMethodType, ContactLogger, ContactFormInitialFieldData, Article, ResolvedCustomField, CustomFieldNameToValueMap, ContactFormSubmitData } from '@wix/answers-api';

import { renderContactFormAndReturnUnmount } from '@wix/answers-common-components/dist/contact-form';

import Auth from '../../auth/auth.srv';
import CustomFieldsService from '../../common/services/custom-fields/custom-fields.srv';
import UploadFileService from '../../common/services/upload-file/upload-file.srv';
import SubmitTicketService from '../submit-a-ticket-service/submit-a-ticket.srv';
import { getUrlContactFormParams } from './contact-form-utils';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import Sdk from '../../common/sdk/sdk.srv';

class ContactFormDirectiveController {
	locale: string;
	onClose: () => void;
	onSubmit: (data: {ticketData: ContactFormSubmitData}) => ng.IPromise<string>;
	isGuestMode: boolean;
	logEvent: ({type: PublicEventType, data: any}) => void;

	/* @ngInject */
	constructor($window, $element, $scope, private reCaptchaInvisibleKey, private $state, private auth: Auth, private reCaptchaKey, private reCaptchaV3Key, customFieldsService: CustomFieldsService, private uploadFileService: UploadFileService, private submitTicketService: SubmitTicketService, private translateFilter, helpcenterContactSettings, private sdk: Sdk) {
		const user = auth.getCurrentUser();
		const locale = this.locale;
		const primaryLocale = this.locale;

		const reCaptchaData = {
			isRequired: true,
			key: reCaptchaInvisibleKey,
			keyV3: reCaptchaV3Key
		};

		const onCancel = () => {
			if ($state.current.name !== 'app.article') {
				this.$state.go('app.home');
			} else {
				this.onClose();
			}
		};

		const onAttachmentUpload = (file, captchaToken, progressCb?: (precentage: number) => void) => {
			return this.uploadFileService.uploadBase64File(file, captchaToken, progressCb).then((data) => {
				return {
					name: data.filename,
					url: data.filelink
				};
			}) as unknown as Promise<any>;
		};

		const contactLogger: ContactLogger = {
			submit: (data) => this.logEvent({type: PublicEventType.SUBMIT_TICKET, data}),
			validationError: (data, invalidFields) => this.logEvent({type: PublicEventType.SUBMIT_TICKET_VALIDATION_ERROR, data: {...data, invalidFields}}),
			submitSuccess: (data, ticketId) => this.logEvent({type: PublicEventType.SUBMIT_TICKET_SUCCESS, data: {...data, ticketId}}),
			submitFailure: (data) => this.logEvent({type: PublicEventType.SUBMIT_TICKET_FAIL, data}),
			cancel: (data) => this.logEvent({type: PublicEventType.SUBMIT_TICKET_CANCEL, data}),
			suggestedArticleSelected: (data, article) => this.logEvent({type: PublicEventType.CONTACT_SUGGESTED_ARTICLE_CLICKED, data: {...data, article}}),
		}

		const onArticleSearch = (title: string, description: string, customFields: CustomFieldNameToValueMap) => {
			return this.submitTicketService.getSuggestedAnswers(title).then(articles => articles.items) as unknown as Promise<Article[]>;
		};

		const onContactFormSubmit = (ticketData: ContactFormSubmitData) => {
			return this.onSubmit({ ticketData }) as unknown as Promise<string>;
		};

		const onFieldsChange = (formData: ContactFormInitialFieldData) => {
			this.sdk.triggerTicketFieldsChanged(formData);
		}

		const resolveContactFormProps = () => {
			const customFields = helpcenterContactSettings.contactMethod.type === ContactMethodType.INTERNAL ? helpcenterContactSettings.contactMethod.resolvedFormFields : [];

			const initialFieldDataFromUrl = getUrlContactFormParams(customFields);


			const initialFieldDataFromSdk = sdk.getTicketFields() || {};

			const customFieldsFromSdk = initialFieldDataFromSdk.customFields || {};


			const initialFieldData: ContactFormInitialFieldData = {
				fullname: initialFieldDataFromUrl.fullname || initialFieldDataFromSdk.fullname,
				email: initialFieldDataFromUrl.email || initialFieldDataFromSdk.email,
				title: initialFieldDataFromUrl.title || initialFieldDataFromSdk.title,
				description: initialFieldDataFromUrl.description || initialFieldDataFromSdk.description,
				customFields: {...customFieldsFromSdk, ...initialFieldDataFromUrl.customFields}
			};

			return {
				locale,
				primaryLocale,
				translate: translateFilter,
				customFields,
				onSubmit: onContactFormSubmit,
				onCancel,
				reCaptchaData,
				user,
				onAttachmentUpload,
				onFieldsChange,
				onGetSuggestedArticles: onArticleSearch,
				initialFieldData,
				logger: contactLogger
			};
		}

		let unmount = renderContactFormAndReturnUnmount($element[0], resolveContactFormProps());
		$scope.$on('$destroy', () => unmount());

		sdk.setContactFormPropsCB(() => {
			unmount();
			unmount = renderContactFormAndReturnUnmount($element[0], resolveContactFormProps());
		})
	}
}

export default function contactFormDirectiveFactory(): ng.IDirective {
	return <ng.IDirective> {
		restricnt: 'E',
		scope: {
			locale: '=',
			onSubmit: '&',
			onClose: '&',
			isGuestMode: '=?',
			logEvent: '&'
		},
		controller: ContactFormDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
