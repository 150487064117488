import * as ans from '@wix/answers-api';
import { isFeatureEnabled } from '@wix/answers-experiments';

export default class UploadFileService {

	/*@ngInject*/
	constructor(private appConfig: ans.AppConfig, private Upload, private $http, private $q) { }

	_uploadBase64File(base64file, captchaToken?: string, progressCallback?: (percentage) => void): ng.IPromise<any> {
		return this.Upload.http({
			url: this.appConfig.apiPath + '/files/base64',
			data: {base64file}
		})
		.then(response => {
			return response.data;
		}, (response) => {
			// Error handling
		}, (evt) => {
			// Progress
			if (progressCallback) {
				const progress = (100 * evt.loaded) / evt.total;
				progressCallback(progress);
			}
		});
	};

	uploadBase64File(base64file, captcha?: string, progressCallback?: any): ng.IPromise<any> {
		const xhr = new XMLHttpRequest();

		return new this.$q((resolve, reject) => {
			const onerror = () => {
				try {
					reject(JSON.parse(xhr.responseText));
				} catch (e) {
					reject(e);
				}
			};

			const checkForValidResponse = () => {
				if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 201) {
					onerror();
				}
			};

			xhr.onload = function() {
				checkForValidResponse();

				try {
					resolve(JSON.parse(xhr.responseText));
				} catch (e) {
					reject(e);
				}
			};

			xhr.onerror = onerror;

			xhr.upload.onprogress = function (event) {
				if (event.lengthComputable) {
					var percent = Math.round((event.loaded / event.total) * 100);
					if (progressCallback) {
						progressCallback(percent);
					}
				}
			};
			xhr.open('POST', this.appConfig.apiPath + '/files/base64');
			xhr.setRequestHeader('Content-Type', 'application/json');
			xhr.send(JSON.stringify({ base64file, captcha, useCaptchaV3: isFeatureEnabled('ReCaptchaV3'), useInvisible: true, secured: true}));
		});

	}
}
