import { isFeatureEnabled } from '@wix/answers-experiments';

export interface TemplateConfig {
	disableParentCategoryLinks: boolean;
	showSubCategoryArticlesInHome: boolean;
	showCollapsibleCategoryMenu: boolean;
	showAllCategoryTypes: boolean;
	ignoreStickyElementsList: string[];
	showCategoryDropdownMenu: boolean;
	showCategoryBreadcrumbs: boolean;
	showBounceCategoryMenu: boolean;
	categoriesPageSize: number;
	useNewScrollingLogic: boolean;
	showCategoryTypeAnchorMenu: boolean;
}

// export type TemplateKey = 'tiny' | 'teralabs' | 'angels-eye';

export type TemplateConfigMap = (templateKey: string) => { 'desktop' : TemplateConfig, 'mobile': TemplateConfig };

export const templateConfig: TemplateConfigMap = (templateKey: string) => {
	const showCategoryAnchors = isFeatureEnabled('showCategoryPageAnchors');

	const templateConfigMap = {
		'angels-eye': {
			'desktop': {
				disableParentCategoryLinks: false,
				showSubCategoryArticlesInHome: false,
				showCollapsibleCategoryMenu: false,
				showAllCategoryTypes: false,
				ignoreStickyElementsList: ['search-box', 'agent-section'],
				showCategoryDropdownMenu: false,
				showCategoryBreadcrumbs: false,
				showBounceCategoryMenu: true,
				categoriesPageSize: 20,
				useNewScrollingLogic: false,
				showCategoryTypeAnchorMenu: false

			},
			'mobile': {
				disableParentCategoryLinks: false,
				showSubCategoryArticlesInHome: false,
				showCollapsibleCategoryMenu: true,
				showAllCategoryTypes: true,
				ignoreStickyElementsList: ['search-box', 'agent-section'],
				showCategoryDropdownMenu: true,
				showCategoryBreadcrumbs: true,
				showBounceCategoryMenu: false,
				categoriesPageSize: 20,
				useNewScrollingLogic: false,
				showCategoryTypeAnchorMenu: false
			}
		},

		'teralabs': {
			'desktop': {
				disableParentCategoryLinks: false,
				showSubCategoryArticlesInHome: false,
				showCollapsibleCategoryMenu: false,
				showAllCategoryTypes: true,
				ignoreStickyElementsList: ['search-box', 'agent-section', ''],
				showCategoryDropdownMenu: false,
				showCategoryBreadcrumbs: false,
				showBounceCategoryMenu: true,
				categoriesPageSize: 200,
				useNewScrollingLogic: true,
				showCategoryTypeAnchorMenu: showCategoryAnchors
			},
			'mobile': {
				disableParentCategoryLinks: true,
				showSubCategoryArticlesInHome: false,
				showCollapsibleCategoryMenu: true,
				showAllCategoryTypes: true,
				ignoreStickyElementsList: ['search-box', 'agent-section', 'category-header'],
				showCategoryDropdownMenu: true,
				showCategoryBreadcrumbs: true,
				showBounceCategoryMenu: false,
				categoriesPageSize: 20,
				useNewScrollingLogic: false,
				showCategoryTypeAnchorMenu: false
			}
		},

		'tiny': {
			'desktop': {
				disableParentCategoryLinks: false,
				showSubCategoryArticlesInHome: true,
				showCollapsibleCategoryMenu: true,
				showAllCategoryTypes: true,
				ignoreStickyElementsList: [],
				showCategoryDropdownMenu: false,
				showCategoryBreadcrumbs: true,
				showBounceCategoryMenu: false,
				categoriesPageSize: 20,
				useNewScrollingLogic: false,
				showCategoryTypeAnchorMenu: false
			},
			'mobile': {
				disableParentCategoryLinks: true,
				showSubCategoryArticlesInHome: false,
				showCollapsibleCategoryMenu: true,
				showAllCategoryTypes: true,
				ignoreStickyElementsList: [],
				showCategoryDropdownMenu: true,
				showCategoryBreadcrumbs: true,
				showBounceCategoryMenu: false,
				categoriesPageSize: 20,
				useNewScrollingLogic: false,
				showCategoryTypeAnchorMenu: false
			}
		}
	};

	return templateConfigMap[templateKey];
};
