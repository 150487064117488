import * as ans from '@wix/answers-api';

class LoaderController {
	ignoreByStateData: boolean;

	/* @ngInject */
	constructor($element, $rootScope, $timeout) {
		$element.hide();

		$rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
			var ignoreLoader = this.ignoreByStateData && toState.data && fromState.data && toState.data.ignoreLoader && fromState.data.ignoreLoader;
			if (!ignoreLoader) {
				$element.fadeIn();
				$element.next().fadeTo(500, 0, function() {
					$(this).css('visibility', 'hidden');
				});
			}
		});

		$rootScope.$on('$stateChangeSuccess', (event, toState) => {
			$element.fadeOut();
			$element.next().fadeIn();
		});
	}
}

export default function LoaderDirectiveFactory(): ng.IDirective {
	return <ng.IDirective> {
		restrict: 'E',
		template: require('./loader.tpl.ts'),
		controller: LoaderController,
		scope: {
			ignoreByStateData: '='
		},
		controllerAs: 'loader',
		bindToController: true
	};
};
