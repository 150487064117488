
import CategoryService from './category.srv';
import * as ans from '@wix/answers-api';

/*@ngInject*/
export default function categoryTitleFromIdFilterDirective(categoryService: CategoryService) {
		return function(id: string) {
				let category: ans.Category = categoryService.categoryMap[id];
				return category ? category.name : id;
		};
}
