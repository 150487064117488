
/* tslint:disable */
const templateData = `
<user-avatar user="ctrl.user"></user-avatar>

<!-- <user-link ng-if="!agoFormat" user="::item.createdByUser" class="author-display-name" prefix-key="{{::prefixKey}}"></user-link> -->
<span ng-if="!ctrl.agoFormat" class="author-display-name">{{ctrl.user | userDisplayName}}</span>
<p ng-if="!ctrl.agoFormat" class="creation-date">{{::ctrl.item.creationDate | amDateFormat: 'LT, LL'}}</p>

<p ng-if="ctrl.agoFormat" class="creation-date" prefix-key="{{::prefixKey}}">{{::ctrl.item.creationDate | amTimeAgo}}</p>
<span ng-if="ctrl.agoFormat" class="author-display-name">{{ctrl.user | userDisplayName}}</span>
<!-- <user-link ng-if="agoFormat" user="::item.createdByUser" class="author-display-name"></user-link> -->

<ng-transclude></ng-transclude>

`;
export = templateData;
