
/* tslint:disable */
const templateData = `
<section class="sitemap" sticky-inner-header ng-class="{'search-mode': ctrl.searchMode || ctrl.searchTerm}">
	<span class="sitemap-links">
		<a inner-link="/">
			<svg-icon name="home"></svg-icon>
		</a>
		<a class="sm-category">{{'public.discussions' | translate}}</a>
	</span>
<!-- </section>
<section class="search-filters" ng-class="{'search-mode': ctrl.searchMode}"> -->
	<a class="back-mode" ng-click="ctrl.exitSearchMode();">
		<svg-icon name="back"></svg-icon>
		{{::'common.back' | translate}}
	</a>
	<div class="tabs">
		<a id="featured" ng-class="{'selected': ctrl.type === 'featured'}" ui-sref="app.tickets.results({type: 'featured', searchTerm: ctrl.searchTerm})">{{'public.tickets.featured' | translate}}</a>
		<a id="recent" ng-class="{'selected': ctrl.type === 'recent'}" ui-sref="app.tickets.results({type: 'recent', searchTerm: ctrl.searchTerm})">{{'public.tickets.recent' | translate}}</a>
		<span class="ticket-search-box">
			<input type="text" class="ticket-search"
				ng-model="ctrl.searchTerm"
				ng-model-options="{ debounce: ctrl.searchDebounce }"
				ng-change="ctrl.getSearchResult();"
				ng-focus="ctrl.searchMode=true;"
				ng-keydown="ctrl.onKeyDown($event);"
				placeholder="{{::'public.tickets.search.placeholder' | translate}}"/>
			<span ng-click="ctrl.enterSearchMode()" class="ticket-search-button" ng-hide="ctrl.searchTerm">
				<svg-icon name="search"></svg-icon>
			</span>
			<span class="ticket-empty-search-button" ng-show="ctrl.searchTerm" ng-click="ctrl.searchTerm = ''; ctrl.getSearchResult();">
				<svg-icon name="cancel"></svg-icon>
			</span>
		</span>
	</div>
</section>
<section class="tickets-results" ng-class="{'search-mode-view': !ctrl.isFromSearch && ctrl.searchMode}">
	<loader class="tickets-loader"></loader>
	<ui-view></ui-view>
</section>
<!-- <ticket-results page="ctrl.page" results="ctrl.results"></ticket-results> -->

`;
export = templateData;
