 /* tslint:disable */ const templateData = `
<main ng-class="{'with-anchors': page.hasAnchors, 'no-related-articles': page.relatedItems.length === 0, 'with-article-resolution': page.article.resolution}">
	<a class="back-home mobile-only" ng-click="ctrl.showParent = true" inner-link="/">
		<svg-icon name="back"></svg-icon>
		{{::'public.header.home.link' | translate}}
	</a>
	<section class="sitemap breadcrumbs" itemscope itemtype="http://data-vocabulary.org/Breadcrumb" sticky-inner-header sticky-key='sitemap'>
		<a inner-link="/" itemprop="url">
			<svg-icon name="home"></svg-icon>
		</a>
		<a class="sm-category" ng-repeat="category in page.categoryParents" itemprop="url" inner-link="{{category.uri}}" sdk-event-trigger="categoryNavigated"
		sdk-event-data="{category: category, parentCategory: page.categoryParents[0] === category ? null : page.categoryParents[0]}">
			<span itemprop="title">{{::category.name}}</span>
		</a>
	</section>

	<header class="article-header">
		<h1>{{::page.article.title}}</h1>
		<div class="article-resolution resolution-{{page.article.resolution}}" ng-if="::page.article.resolution">
			<span class="resolution-type">{{::'common.article-type.' + page.article.type | translate}}</span>
			<span class="resolution-text" ng-bind-html="'public.article-resolution.' + page.article.resolution | translate"></span>
		</div>
	</header>

	<article-viewer components="page.articleComponents" locale="page.article.locale" class="article"></article-viewer>

	<section class="anchor-container">
		<anchor-navigation sticky-inner-header sticky-bottom-selector=".article-actions" ng-if="page.hasAnchors" components="::page.articleComponents" locale="::page.article.locale"></anchor-navigation>
	</section>

	<section class="article-actions">
		<div class="article-actions-inner" ng-class="{'split-row-action': page.isUserAgent}">
			<item-rating ng-hide="page.hideRating" class="rating-row" item="page.article" show-unhelpful="!page.isTicketingEnabled || !page.contactFromArticlePageEnabled"></item-rating>

			<div class="submit-row" is-ticketing-enabled ng-show="page.notHelpful" ng-if="page.contactFromArticlePageEnabled">
				<div class="contact-form" ng-if="(page.isLoggedIn || page.guestContactFormEnabled) && !page.calculatedContactUsLink" ng-hide="page.isSubmited || page.isSelfResolvedIssue" ng-class="{'new-contact-form': page.newContactForm}">
					<span class="submit-row-msg-title">{{'public.article.unhelpful-title' | translate}}</span>
					<span class="submit-row-msg-sub-title">{{'public.article.unhelpful.submit-title' | translate}}</span>
					<legacy-contact-form ng-if="!page.newContactForm" on-submit="page.submitLinkedTicket(ticketData)" is-guest-mode="page.isGuestContactForm" disable-article-search="true"></legacy-contact-form>
					<contact-form ng-if="page.newContactForm" log-event="page.logEvent(type, data)" locale="page.contactFormLocale" on-submit="page.submitLinkedTicket(ticketData)" on-issue-resolved="page.handleIssueResolved()" is-guest-mode="page.isGuestContactForm" on-close="page.onContactFormClose()"></contact-form>
				</div>
				<!--<form id="submit-form" name="submit" ng-show="!page.isSubmited" ng-submit="page.submitLinkedTicket(submit.$valid)" novalidate ng-class="{'invalid-content': !submit.content.$valid}"
				ng-if="(page.isLoggedIn || page.guestContactFormEnabled) && !page.contactUsLink" helper-placeholder="{{::'public.ticket.submit.title.placeholder' | translate}}">
					<span class="submit-row-msg-title">{{'public.article.unhelpful-title' | translate}}</span>
					<span class="submit-row-msg-sub-title">{{'public.article.unhelpful.submit-title' | translate}}</span>
					<div class="input-title-row">
						<input type="text" name="title"
							required="" html-min-length="10" novalidate
							class="ticket-title"
							ng-model="page.newTicketTitle"
							ng-class="{error: submit.$submitted && submit.title.$invalid}"
							placeholder="{{::'public.ticket.submit.title.placeholder' | translate}}">
						<div class="submit-ticket-error" ng-show="submit.$submitted && submit.title.$invalid">{{::'public.submit.title.error' | translate}}</div>
					</div>
					<div class="input-title-row">
						<textarea redactor="page.textEditorOptions" name="content" required="" html-min-length="10" ng-model="page.newTicketContent"
						placeholder="{{::'public.ticket.submit.content.placeholder' | translate}}"></textarea>
						<div class="text-editor-toolbar"></div>
						<div class="submit-ticket-error" ng-show="submit.$submitted && submit.content.$invalid">{{::'public.submit.content.error' | translate}}</span></div>
					</div>

					<div class="input-user-row" ng-if="page.guestContactMode">
						<div class="submit-label">{{::'public.submit.user.label' | translate}}</div>
						<div class="input-user-input-container">
							<div class="input-user-input">
								<input type="text" name="fullname" required="" ng-class="{error: submit.$submitted && submit.fullname.$invalid}" ng-model="page.userFullName"
								placeholder="{{::'public.submit.user.full-name.placeholder' | translate}}">
								<div class="submit-ticket-error" ng-show="submit.$submitted && submit.fullname.$invalid">{{::'public.submit.user.full-name.error' | translate}}</div>
							</div>

							<div class="input-user-input">
								<input type="email" name="email" required="" ng-class="{error: submit.$submitted && submit.email.$invalid}" ng-model="page.userEmail"
								placeholder="{{::'public.submit.user.email.placeholder' | translate}}">
								<div class="submit-ticket-error" ng-show="submit.$submitted && submit.email.$invalid">
									<span ng-show="submit.email.$error.required">{{::'public.submit.user.email.error.required' | translate}}</span>
									<span ng-show="submit.email.$error.email">{{::'public.submit.user.email.error.valid' | translate}}</span>
								</div>
							</div>
						</div>
					</div>

					<div ng-if="page.useCaptcha || page.guestContactMode" class="captcha-row">
						<div class="submit-label">{{::'public.submit.captcha.label' | translate}}</div>
						<div class="recaptcha-container" vc-recaptcha key="page.reCaptchaKey" ng-model="page.reCaptchaValue" ng-class="{'recaptcha-error': submit.$error.recaptcha}"></div>
						<div class="submit-ticket-error captcha-error" ng-show="submit.$submitted && submit.$error.recaptcha">{{::'public.submit.recaptcha-error' | translate}}</div>
					</div>
					<div class="button-row">
						<button type="button" class="btn-simple cancel" ng-click="page.notHelpful=false;" ng-class="{'with-captcha': page.useCaptcha}">{{::'common.cancel' | translate}}</button>
						<button id="submit-ticket" class="btn" ng-class="{'with-captcha': page.useCaptcha}">{{::'public.ticket.submit.btn' | translate}}</button>
					</div>
				</form>-->
				<div ng-if="!page.newContactForm" ng-show="page.isSubmited" class="ticket-submitted-old">
					<span class="ticket-submitted-msg">{{'public.submit.ticket.summary' | translate}}</span>
					<a inner-link="{{page.submitedTicket.uri}}">
					<svg-icon name="open" class="middle"></svg-icon>{{'public.submit.ticket.view-ticket' | translate}}</a>
				</div>
				<div ng-if="page.newContactForm" ng-show="page.isSubmited" class="ticket-submitted">
					<span class="ticket-submitted-title">{{'public.article.ticket-submitted-title' | translate}}</span>
					<div class="ticket-submitted-msg">{{::'public.article.ticket-submitted-msg' | translate}}</div>
					<a class="btn" href="" ng-click="page.onContactFormClose()">{{::'public.common.done' | translate}}</a>
				</div>
				<div ng-show="page.isSelfResolvedIssue" class="issue-self-resolved">
					<span class="issue-self-resolved-title">{{::'public.article.issue-resolved-title' | translate}}</span>
					<div class="issue-self-resolved-msg">{{::'public.article.issue-resolved-msg' | translate}}</div>
					<a class="btn" inner-link="/">{{::'public.common.done' | translate}}</a>

				</div>
				<div ng-if="!page.isLoggedIn && !page.guestContactFormEnabled && !page.calculatedContactUsLink" class="login-to-submit">
					<span class="submit-row-msg-title">{{'public.article.unhelpful-title' | translate}}</span>
					<span class="login-to-submit-msg">{{'public.article.sign-in' | translate}}</span>
					<a ng-click="page.signin()">{{'common.sign-in' | translate}}</a>
				</div>
				<div ng-if="page.calculatedContactUsLink" class="login-to-submit">
					<span class="submit-row-msg-title">{{'public.article.unhelpful-title' | translate}}</span>
					<span class="login-to-submit-msg">{{'public.article.contact-for-help' | translate}}</span>
					<a ng-hide="page.currentUser" href="{{page.calculatedContactUsLink}}">{{'public.footer.need.help.btn' | translate}}</a>
				</div>
			</div>
		</div>
	</section>

	<section class="related-sections">
		<section id="related-items" ng-if="page.relatedItems.length > 0">
			<header class="sub-title">
				<h3 class="article-items-title" ng-class="{'deselected': page.itemsDeselected}" ng-click="page.itemsDeselected=false" ng-click="page.itemsDeselected=false">{{'public.article.related-items' | translate}}</h3>
			</header>
			<ul class="article-list" ng-class="{'deselected': page.itemsDeselected}">
				<li class="related-item" sdk-event-trigger="articleResultClick" sdk-event-data="{article: relatedItem, order: $index}" ng-repeat="relatedItem in page.relatedItems | limitTo: 10"
				data-type="article">
					<a inner-link="{{relatedItem.uri}}">
						<svg-icon name="article" class="middle"></svg-icon>
						<h4 class="article-type">{{::'common.article-type.' + relatedItem.type | translate}}</h4>
						<div class="article-title">
							<div class="article-title-inner">{{::relatedItem.title | htmlToText}}</div>
						</div>
					</a>
				</li>
			</ul>
		</section>
	</section>

	<user-engagement-pinger article="::page.article"></user-engagement-pinger>
</main>

`; export = templateData;
