
/* tslint:disable */
const templateData = `
<section class="hero" ng-class="{'small': hero.isSmall, 'add-transition': hero.addTransition, 'with-user-toolbar': hero.$rootScope.showUserToolbar, 'toolbar-expanded': hero.$rootScope.isToolbarExpanded}">
	<div class="hero-background">
		<div ng-hide="!hero.isSmall && hero.brandingConfig.backgroundVideoUrl" class="hero-background-image desktop" ng-style="{'background-image': 'url(' + hero.backgroundImageUrl + ')'}" ng-show="hero.backgroundImageUrl">
			<div class="hero-background-image-inner"></div>
		</div>
		<div ng-hide="!hero.isSmall && hero.brandingConfig.backgroundVideoUrl" class="hero-background-image mobile" ng-style="{'background-image': 'url(' + hero.mobileHeroImageUrl + ')'}" ng-show="hero.mobileHeroImageUrl">
			<div class="hero-background-image-inner"></div>
		</div>
		<video ng-hide="hero.isSmall || !hero.brandingConfig.backgroundVideoUrl" autoplay preload="auto" loop="loop" class="hero-background-video">
		  <source ng-src="{{hero.videoUrl}}">
		</video>
	</div>
    <div class="hero-titles">
        <h1 ng-if="!hero.isSmall"><span preview-changeable-text="public.home.title">{{::'public.home.title' | translate}}<span></h1>
		<h2 ng-if="!hero.isSmall"><span preview-changeable-text="public.home.sub-title" ng-bind-html="'public.home.sub-title' | translate | htmlSafe"></span></h2>
		<a class="main-inner-title" ng-if="hero.isSmall" preview-changeable-text="public.home.title" inner-link="/">{{::'public.home.title' | translate}}<a>
		</div>
		<div class="search-box" data-or="{{::'common.or' | translate}}" sticky-inner-header>
			<div class="search-box-inner" ng-class="{'is-focused': hero.showSearchResult}">
				<input type="text" id="search"
					ng-model="hero.searchTerm"
					ng-model-options="{ debounce: hero.searchDebounce }"
					ng-change="hero.getSearchResult($event);"
					ng-focus="hero.showSearchResult=true;"
					ng-blur="hero.hideSearchResult();"
					ng-keydown="hero.onKeyDown($event);"
					maxlength="100"
					scroll-on-focus
					autocomplete="off"
					preview-changeable-text="public.search.title.placeholder"
					replace-attr="placeholder"
					placeholder="{{::'public.search.title.placeholder' | translate}}" />
      </div>
        <div class="search-button" ng-class="{'is-focused': hero.showSearchResult}" ng-click="hero.goToSearch();">
            <svg-icon name="search"></svg-icon>
        </div>

        <div class="search-results slide-up" ng-show="hero.showSearchResult && hero.searchTerm && hero.searchTerm.length > 1">
            <ul ng-show="hero.searchResults.length">
                <li ng-repeat="articleItem in hero.searchResults"
					  ng-class="{'hover': $index === hero.hoverSearchResultIndex}">
                    <a class="article-item"
                       data-hover="{{::'common.read' | translate}}"
                       inner-link="{{::articleItem.uri}}"
					   force-server-side-routing-link="{{hero.shouldForceServerSideRoutingLink()}}"
                       ng-click="hero.onResultClick(articleItem.id, $index)"
                       sdk-event-data="{searchTerm: hero.searchTerm, searchVersion: hero.searchVersion, searchMethod: hero.searchMethod, result: articleItem, order: $index, fromHero: true}"
                       sdk-event-trigger="articleSearchAnswerClicked">
					    <svg-icon name="article"></svg-icon>
						<div class="article-content">
	                        <div class="title">{{::articleItem.title}}</div>
	                        <div class="content">{{::articleItem.content | htmlToText}}</div>
						</div>
                    </a>
                </li>
                <li ng-show="hero.searchCount > hero.searchResults.length" class="show-more-container" ng-class="{'hover': hero.searchResults.length === hero.hoverSearchResultIndex}">
                    <a class="show-more"
                        ng-click="hero.goToSearch()"
                        sdk-event-data="{searchTerm: hero.searchTerm, searchVersion: hero.searchVersion, searchMethod: hero.searchMethod}"
                        sdk-event-trigger="articleSearchAllResultsClicked">
                        {{::'public.search.show-more' | translate}} <span class="show-more-term">‘{{hero.searchTerm}}’</span>
                    </a>
                </li>
            </ul>
            <div class="empty-search" ng-show="!hero.searchResults.length && !hero.waitForSearchResults">
                <div class="empty-search-icon">
					<svg-icon name="empty-search"></svg-icon>
                </div>
                <div class="empty-search-msg" ng-bind-html="'public.search.no.results' | translate | htmlSafe"></div>
                <div class="empty-search-msg-sub" ng-bind-html="'public.search.no.results-sub' | translate | htmlSafe"></div>
            </div>
        </div>
    </div>
</section>

`;
export = templateData;
