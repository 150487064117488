/// <reference path="../../references.ts" />

'use strict';

import { Article, CustomFieldNameToValueMap, ContactFormSubmitData } from '@wix/answers-api';
import SubmitTicketService from '../submit-a-ticket-service/submit-a-ticket.srv';

class LegacyContactFormDirectiveController {
	onSubmit: (data: {ticketData: ContactFormSubmitData}) => void;
	isGuestMode: boolean;
	disableArticleSearch: boolean;

	newTicketTitle: string;
	newTicketContent: string;
	userFullName: string;
	userEmail: string;
	reCaptchaValue: string;

	suggestAnswers: Article[];
	waitForSuggestAnswers: boolean;

	showFullName: boolean = false;
	useCaptcha: boolean = true;

	/*@ngInject*/
	constructor($window, $element, $scope, $timeout,
		private textEditorOptions,
		private submitTicketService: SubmitTicketService,
		private reCaptchaKey: string) {

		textEditorOptions.toolbarExternal = '.text-editor-toolbar';

		this.showFullName = this.isGuestMode;
	};

	getSuggestAnswers = (newVal, oldVal) => {
		if (!this.disableArticleSearch && this.newTicketTitle) {
			this.waitForSuggestAnswers = true;
			this.submitTicketService.getSuggestedAnswers(this.newTicketTitle)
			.then((articles) => {
				this.suggestAnswers = articles.items;
				this.waitForSuggestAnswers = false;

			});
		} else {
			this.suggestAnswers = [];
		}
	};

	handleSubmitClick = (isValid: boolean) => {
		if (isValid) {
			let firstName = null;
			let lastName = null;

			if (this.showFullName) {
				const splitFullName = this.userFullName.split(' ');
				firstName = splitFullName.length > 1 ? splitFullName.slice(0, -1).join(' ') : splitFullName[0];
				lastName = splitFullName.length > 1 ? splitFullName.slice(-1).join(' ') : null;
			}

			const newTicketIsPrivate = true;

			const ticketData: ContactFormSubmitData = {
				firstName: firstName,
				lastName: lastName,
				email: this.userEmail,
				title: this.newTicketTitle,
				description: this.newTicketContent,
				captchaToken: this.reCaptchaValue,
				customFieldsValuesMap: null,
				attachments: null,
			};

			this.onSubmit({ ticketData });
		}
	};
};

export default function legacyContactFormDirectiveFactory(): ng.IDirective {
	return <ng.IDirective> {
		restrict: 'E',
		scope: {
			onSubmit: '&',
			isGuestMode: '=?',
			disableArticleSearch: '=?'
		},
		template: require('./legacy-contact-form.tpl'),
		controller: LegacyContactFormDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
