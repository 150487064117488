const iconsMap = {
	'category-article': require('./category-article.svg'),
	'article': require('./category-article.svg'),
	'article-bold': require('./article-icon-bold.svg'),
	'article-followed': '',
	'category-home-icon': require('./home.svg'),
	'ticket': '',
	'ticket-followed': require('./followed-ticket.svg'),
	'search': require('./search.svg'),
	'home': require('./home.svg'),
	'follow': require('./follow.svg'),
	'v': require('./v-icon.svg'),
	'x': require('./x-icon.svg'),
	'feature-request': require('./vote.svg'),
	'back': require('./back-btn.svg'),
	'back-home': require('./home.svg'),
	'known-issue': require('./issue.svg'),
	'stats': require('./stats.svg'),
	'manage': require('./manage.svg'),
	'notifications': require('./notifications.svg'),
	'open': require('./open.svg'),
	'cancel': require('./cancel.svg'),
	'unfollow': require('./x-icon.svg'),
	'reply': '',
	'empty-search': require('./spotlight.svg'),
	'globus': require('./globus.svg'),
	'clock': require('./clock.svg'),
	'cat-dd-menu': require('./cat-dd-menu.svg'),
	'answers': require('./answers.svg'),
};

export default iconsMap;
