/// <reference path="references.ts" />

require('es6-promise').polyfill();
import mainAppRouting from './routing/main-routing';
import legacyRedirectsRoutingConfig from './routing/legacy-redirects.config';
import { initFedopsLogger } from './fedops';

import * as sentry from '@sentry/browser';
import { createSentryReporter } from '@wix/answers-lib';

const errorReporter = createSentryReporter(sentry, 'https://dc5babfa0e154125bde4fa4621a9aff2@sentry.wixpress.com/439');

errorReporter.setup('42');

require('./category-page/category-page.mdl');
require('./user/users.mdl');
require('./common/prerender/prerender.mdl');
require('./core/core.mdl');
require('./auth/auth.mdl');
require('./common/common.mdl');
require('./seo/seo.mdl');
require('./home/home.mdl');
require('./ugc/tickets.mdl');
require('./article/article.mdl');
require('./search/article-search.mdl');
require('./structure/structure.mdl');
require('./submit/submit-a-ticket.mdl');
require('./common/category/categories.mdl');

if (window.location.hash && window.location.hash === '#_=_') {
	history.replaceState ?
		history.replaceState('', '', window.location.href.split('#')[0]) :
		window.location.href = window.location.href.split('#_=_')[0];
}

angular.module('answersPublicStatics', ['ui.router', 'auth', 'core', 'seo', 'home', 'article', 'tickets', 'search', 'users', 'submit-ticket', 'structure', 'categoryPage', 'vcRecaptcha', 'ngScrollbars'])
.config(() => {
	initFedopsLogger();
})
.config(mainAppRouting)
.config(function ($translateProvider) {
	$translateProvider.useSanitizeValueStrategy('sanitizeParameters');
})
.run(function($rootScope, $state, previewMode) {
	$rootScope.goneToError = false;
	$rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {
		if (!$rootScope.goneToError) {
			$rootScope.goneToError = true;
			console.error('Error in route', event, toState, toParams);
			$state.go('app.error', { error: error });
		}
	});

	if (previewMode) {
		$(document.body).addClass('preview-mode');
	}
});
