/// <reference path="../../references.ts" />

'use strict';

import CategoryService from './../../common/category/category.srv';
import * as ans from '@wix/answers-api';
import SubmitTicketService from './../submit-a-ticket-service/submit-a-ticket.srv';
import Sdk from './../../common/sdk/sdk.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import Auth from './../../auth/auth.srv';
import SdkEventType from './../../common/sdk/sdk-events.enum';
import { isFeatureEnabled } from '@wix/answers-experiments';
import { PublicEventType } from '@wix/answers-bi';
import BiService from '../../common/bi/bi.srv';

class SubmitTicketPageDirectiveController {

	isSubmited: boolean = false;
	isSelfResolvedIssue: boolean = false;
	selectedCategory: ans.Category;

	newTicketIsPrivate: boolean = true;
	selectedCategoryId: string;
	showSuggestAnswer: boolean;
	newTicket: ans.Ticket;
	recommendedSolutions: ans.Article[];
	waitForSuggestAnswers: boolean;
	submitTicketInProcess: boolean = false;
	isLoggedIn: boolean = false;
	userOpenTickets: ans.Ticket[];
	forceFullName: boolean = false;

	isGuestContactForm: boolean = false;
	guestContactFormEnabled: boolean = false;

	contactFormLocale: string;
	showTicketLinkButton: boolean;

	newContactForm: boolean = true;

	/*@ngInject*/
	constructor(private categoryService: CategoryService,
		private auth: Auth,
		private textEditorOptions,
		private submitTicketService: SubmitTicketService,
		private reCaptchaKey: string,
		private $scope: ng.IScope,
		private sdk: Sdk,
		private biService: BiService,
		private appConfig: ans.AppConfig,
		private $timeout: ng.ITimeoutService) {
			this.isSubmited = false;
			this.isSelfResolvedIssue = false;
			this.isLoggedIn = auth.isLoggedIn();
			this.showSuggestAnswer = false;

			this.guestContactFormEnabled = this.submitTicketService.isGuestContactFormEnabled();
			var currentUser = auth.getCurrentUser();

			this.contactFormLocale = appConfig.lang;

			this.isGuestContactForm = !this.isLoggedIn && this.guestContactFormEnabled;

			textEditorOptions.toolbarExternal = '.text-editor-toolbar';
			this.textEditorOptions = textEditorOptions;

			if (this.selectedCategoryId) {
				this.selectedCategory = categoryService.categoryMap[this.selectedCategoryId];
			}

			this.sdk.setViewedPageData(PageType.submitTicket, null);
			$scope.$watch('$viewContentLoaded', () => {
				this.biService.logEvent(PublicEventType.CONTACT_PAGE_LOAD, {});
			});

			//ES - ONCE CATEGORY SELECTION SUPPORTS NG_MODEL THIS WILL NOT BE NEEDED
			$scope.$watch(() => this.selectedCategory, (newVal, oldVal) => {
				if (newVal && newVal !== oldVal) {
					const data = {
						category: newVal,
						parentCategory: this.categoryService.getCachedCategoryById(newVal.parentId)
					};
					this.sdk.triggerEvent(SdkEventType.submitTicketCategorySelect, data);
				}
			});
		}

		logEvent = (type: PublicEventType, data: any) => {
			this.biService.logEvent(type, {...data, contactFormLocation: 'contact'});
		}

		submitTicket = (ticketData: ans.ContactFormSubmitData): ng.IPromise<string> => {
			if (!this.submitTicketInProcess) {
				this.submitTicketInProcess = true;

				return this.submitTicketService.submitTicket(ticketData, this.isGuestContactForm, null)
				.then((newTicket) => {
					$('body,html').stop(true, true).animate({ scrollTop: 0 }, 300);
					this.newTicket = newTicket;
					this.getRecommendedSolutions();
					this.submitTicketInProcess = false;

					if (this.forceFullName) {
						this.auth.removeFromStorage();
					}

					this.showTicketLinkButton = this.isLoggedIn;
					this.isSubmited = true;
					return newTicket.id;
				});
			}
		};

		handleIssueResolved = () => {
			this.getRecommendedSolutions();
			this.isSelfResolvedIssue = true;
			this.$scope.$apply();
		};

		private getRecommendedSolutions = () => {
			this.submitTicketService.getRecommendedSolutions(null)
			.then((articles) => {
				this.recommendedSolutions = articles.items;
			});
		};

		signin = () => {
			return this.auth.accountRedirect('signin');
		};

		anotherIssue = () => {
			this.userOpenTickets = null;
		};
	}

	function submitTicketPageDirectiveFactory(): ng.IDirective {
		return <ng.IDirective>{
			template: require('./submit-a-ticket-page.tpl.ts'),
			restrict: 'E',
			scope: {
				newTicketTitle: '=?',
				newTicketContent: '=?',
				newTicketIsPrivate: '=?',
				userOpenTickets: '=?',
				selectedCategoryId: '='
			},
			controllerAs: 'page',
			bindToController: true,
			controller: SubmitTicketPageDirectiveController
		};
	}

	export default submitTicketPageDirectiveFactory;
