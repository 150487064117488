import {renderComponentsViewerAndReturnUnmount} from '@wix/answers-common-components/dist/components-viewer/components-viewer-renderer';
import { endInteraction } from '../../fedops';

function articleViewerDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			components: '=',
			locale: '='
		},
		link: function(scope: any, elem) {
			var unmount = renderComponentsViewerAndReturnUnmount(elem[0], scope.locale, scope.components);
			scope.$on('$destroy', () => unmount());
			endInteraction('article-view');
		}
	};
}

export default articleViewerDirectiveFactory;
