
/* tslint:disable */
const templateData = `
<section ng-if="page.isLoggedIn || page.guestContactFormEnabled" class="submit-ticket-section">
 	<div ng-hide="page.isSubmited || page.isSelfResolvedIssue || page.userOpenTickets.length" class="submit-ticket-container">
		<section class="sitemap">
			<a inner-link="/">
				<svg-icon name="home"></svg-icon>
			</a>
			<a class="sm-category">{{'public.contact.ticket.title' | translate}}</a>
		</section>
		<section class="main-panel">
			<h1>{{::'public.contact.ticket.title' | translate}}</h1>
			<legacy-contact-form ng-if="!page.newContactForm" on-submit="page.submitTicket(ticketData)" is-guest-mode="page.isGuestContactForm"></legacy-contact-form>
			<contact-form ng-if="page.newContactForm" locale="page.contactFormLocale" log-event="page.logEvent(type, data)" on-submit="page.submitTicket(ticketData)" is-guest-mode="page.isGuestContactForm"></contact-form>
		</section>
		<section class="side-panel">
			<div class="side-panel-inner">
				<div class="tip">
					<h4 class="tip-main-title"> {{::'public.submit.tip.title' | translate}}</h4>
					<div class=tip-row>
						<div class="tip-title">{{::'public.submit.tip.1.title' | translate}}</div>
						<div class="tip-content">{{::'public.submit.tip.1.content' | translate}}</div>
					</div>
					<div class=tip-row>
						<div class="tip-title">{{::'public.submit.tip.2.title' | translate}}</div>
						<div class="tip-content">{{::'public.submit.tip.2.content' | translate}}</div>
					</div>
				</div>
				<div class="phone" style="display:none;">
					<h4>{{::'public.submit.phone.title' | translate}}</h4>
					<div class=phone-row>
						{{::'public.submit.tip.1.title' | translate}}
					</div>
				</div>
			</div>
		</section>
	</div>
	<div ng-show="page.isSubmited || page.isSelfResolvedIssue" class="ticket-submitted-container">
		<section class="ticket-submitted-header">
			<div class="ticket-submitted-header-inner" ng-show="page.isSubmited">
			    <h3>{{::'public.submit.ticket.summary' | translate}}</h3>
				<div class="submit-summary-text">{{::'public.submit.ticket.summary.text' | translate}}</div>
				<a class="btn-simple" inner-link="/">{{::'public.submit.go.home' | translate}}</a>
			  	<a class="btn" ng-if="page.showTicketLinkButton" inner-link="{{::page.newTicket.uri}}">{{::'public.submit.ticket.view-ticket' | translate}}</a>
			</div>
			<div class="ticket-submitted-header-inner self-resolved" ng-show="page.isSelfResolvedIssue">
				<h3>{{::'public.submit.issue-resolved-title' | translate}}</h3>
				<div class="submit-summary-text">{{::'public.submit.issue-resolved-msg' | translate}}</div>
				<a class="btn-simple" inner-link="/">{{::'public.submit.go.home' | translate}}</a>
			</div>
		</section>
		<section class="ticket-submitted-body" ng-show="page.recommendedSolutions.length">
		    <div class="recommended-solutions">
		        <h4>{{::'public.submit.recommended-solutions' | translate}}</h4>
		        <ul class="search-results">
		            <li class="search-result" ng-repeat="searchResult in page.recommendedSolutions" data-type="article">
		                <a class="related-article-item" inner-link="{{::searchResult.uri}}">
							<svg-icon name="article-bold" class="middle"></svg-icon>
							<div class="article-type">{{::'common.article-type.' + searchResult.type | translate}}</div>
							<div class="article-title">{{::searchResult.title | htmlToText}}</div>
		                </a>
		            </li>
		        </ul>
		    </div>
		</section>
	</div>
	<div class="submit-has-open-ticket no-animation" ng-if="page.userOpenTickets.length">
		<div class="submit-has-open-ticket-inner">
			<div class="has-open-ticket-title">{{::'public.submit.has-open-ticket.title' | translate}}</div>
			<div class="has-open-ticket-msg">{{::'public.submit.has-open-ticket.text' | translate}}</div>

			<ul class="open-tickets">
				<li class="ticket" ng-repeat="ticket in page.userOpenTickets">
					<a inner-link="{{::ticket.uri}}">
						<svg-icon name="ticket"></svg-icon>
						<span class="title">{{::ticket.subject}}</span>
						<p class="info">
							<span class="creation-date">{{::ticket.creationDate | amDateFormat: 'LL'}}</span>
							<span class="replies">
								<span ng-show="ticket.repliesCount > 0">{{'public.user.tickets.replies' | translate: {replyCount: ticket.repliesCount} }} </span>
								<span ng-hide="ticket.repliesCount > 0" class="no-reply">{{'public.user.tickets.no.reply' | translate}}</span>
							</span>
						</p>
					</a>
				</li>
				<span class="none" ng-if="::page.userTickets.itemsCount === 0">
					{{'public.user.tickets.none' | translate}}
				</span>
			</ul>

			<div class="another-issue">
				<a href="#" ng-click="page.anotherIssue();"
				sdk-event-trigger="anotherTicket" class="another-btn">{{::'public.submit.has-open-ticket.another.btn' | translate}}</a>
			</div>

		</div>
	</div>
</section>
<section ng-if="!page.isLoggedIn && !page.guestContactFormEnabled" class="submit-not-login">
	<div class="submit-not-login-inner">
		<h1>{{::'public.contact.ticket.title' | translate}}</h1>
		<div class="not-login-msg">{{::'public.submit.sign-in' | translate}}</div>
		<a href="#" ng-click="page.signin()"
		sdk-event-trigger="signInFromTicket" class="login-btn">{{::'common.sign-in' | translate}}</a>
	</div>
</section>

`;
export = templateData;
