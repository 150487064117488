import * as ans from '@wix/answers-api';

export default class AnalyticsService {
	sourceType: ans.SourceType = ans.SourceType.HELP_CENTER;
	sourceId: string = null;

	/*@ngInject*/
	constructor(private appConfig: ans.AppConfig, private $http: ng.IHttpService) {}

	sessionStart() {
		ans.AnalyticsLogger.sessionStart(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId);
	}

	logPageView(articleId: string) {
		ans.AnalyticsLogger.logPageView(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId, articleId);
	}

	logSearchResultClickMore(text: string) {
		ans.AnalyticsLogger.logSearchResultClickMore(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId, text);
	}

	logSearchResultIgnored(text: string) {
		ans.AnalyticsLogger.logSearchResultIgnored(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId, text);
	}

	logSearchResultNone(text: string) {
		ans.AnalyticsLogger.logSearchResultNone(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId, text);
	}

	logSearchResultArticleClicked(text: string, articleId: string, position: number) {
		ans.AnalyticsLogger.logSearchResultArticleClicked(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId, text, articleId, position);
	}

	logContactSupportClick() {
		ans.AnalyticsLogger.logContactSupportClick(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId);
	}

	logVoteItem() {
		ans.AnalyticsLogger.logVoteItem(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId);
	}

	logUnvoteItem() {
		ans.AnalyticsLogger.logUnvoteItem(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId);
	}

	logHelpfulItem() {
		ans.AnalyticsLogger.logHelpfulItem(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId);
	}

	logUnhelpfulItem() {
		ans.AnalyticsLogger.logUnhelpfulItem(this.$http.post, this.appConfig.apiPath, this.appConfig.lang, this.sourceType, this.sourceId);
	}
}
