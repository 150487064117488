'use strict';

import * as ans from '@wix/answers-api';
import CategoryService from './../category.srv';

class MultilevelCategorySelectionDirectiveController {

	selectedCategory: ans.Category;
	categoryLevels: ans.Category[][];
	selectedCategories: ans.Category[];
	currentLevelSelectedCategoryId: string[];
	showError: boolean;

	cloneElement: JQuery;
	cloneScope;

	/*@ngInject*/
	constructor(private $compile: ng.ICompileService, private $scope, private $element, private $rootScope: ng.IRootScopeService, private categoryService: CategoryService, private $timeout) {
		this.setCategoryLevels(this.selectedCategory);
		this.showError = false;

		if (this.selectedCategory) {
			var category = this.selectedCategory;
			var selectedParent = [];
			while (category) {
				selectedParent.unshift(category);
				category = this.categoryService.getCachedCategoryById(category.parentId);
			}

			this.currentLevelSelectedCategoryId = new Array(selectedParent.length);

			for (let i = 0; i < selectedParent.length; i++) {
				this.currentLevelSelectedCategoryId[i] = selectedParent[i].id;
				this.categorySelected(i);
			}
		}
	}

	setCategoryLevels = (category: ans.Category) => {
		this.categoryLevels = [];
		while (category) {
			this.categoryLevels.unshift(category.children);
			category = this.categoryService.getCachedCategoryById(category.parentId);
		}
		this.categoryLevels.unshift(this.categoryService.categoryTree);
	};

	categorySelected = (index: number, category?: ans.Category) => {
		category = category || this.categoryService.getCachedCategoryById(this.currentLevelSelectedCategoryId[index]);
		if (category) {
			if (category.children && category.children.length) {
				this.selectedCategory = null;
				this.setCategoryLevels(category);
				if (Object.keys(this.currentLevelSelectedCategoryId || {}).length === 2) {
					delete this.currentLevelSelectedCategoryId[1];
				}
			} else {
				this.selectedCategory = category;
			}
		}
	};
}

function multilevelCategorySelectionDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		template: require('./category-selection.tpl.ts'),
		restrict: 'E',
		scope: {
			selectedCategory: '=',
			showErrors: '='
		},
		controllerAs: 'categorySelection',
		bindToController: true,
		controller: MultilevelCategorySelectionDirectiveController
	};
}

export default multilevelCategorySelectionDirectiveFactory;
