/// <reference path="../../references.ts" />

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export function htmlToText(html: any) {
	var htmlText = html && html.replace && html
	.replace(/([^<>]+)(?=<.+>[^<>]+)/g, '$& ')
	.replace(/<[^>]*?>/g, '')
	.replace(/&quot;/g, '"')
	.replace(/&apos;/g, '\'')
	.replace(/&#39;/g, '\'')
	.replace(/&lt;/g, '<')
	.replace(/&gt;/g, '>')
	.replace(/&amp;/g, '&');

	htmlText = entities.decode(htmlText);
	return htmlText;
}

export default function htmlToTextFilterFactory(): ng.IFilterService {
	return htmlToText;
}
