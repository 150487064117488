
/* tslint:disable */
const templateData = `
<div class="error-page">
    <div class="error-page-container">
        <div class="error-type">{{::'public.error.500.type' | translate}} | <span>{{::'public.error.500.type.text' | translate}}<span></div>
        <div class="error-title">{{::'public.error.500.title' | translate}}</div>
        <div class="error-redirect">{{::'public.error.redirected' | translate}} <go-home-counter delay="20"></go-home-counter>...</div>
        <a class="icon icon-home home-link" inner-link="/">{{::'public.error.home.btn' | translate}}</a>
    </div>
</div>

`;
export = templateData;
