import { CategoryContext } from '@wix/answers-common-components';
import UserToolbarService from '../../structure/user-toolbar/user-toolbar.srv';
import CategoryService from './../../common/category/category.srv';
import * as ans from '@wix/answers-api';
import ArticleService from './../../article/article-service/article.srv';
import Sdk from './../../common/sdk/sdk.srv';
import Auth from './../../auth/auth.srv';
import FollowService from './../../common/services/follow/follow.srv';
import TemplateConfigService from './../../common/template-config/template-config.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import { shouldForceServerSideRouting } from '../../common/experiments';

class CategoryPageDirectiveController {
	results: ans.SearchResult<ans.Article>;
	articles: ans.Article[];
	articlesByTypes: ans.SearchResult<ans.Article>;
	totalArticlesCount: number[] = [];
	page: number = 1;
	type: ans.ArticleType;
	category: ans.Category;
	availableTypes: ans.ArticleType[];
	showArticles: boolean = true;
	isUserAgent: boolean = false;
	isFollowedByUser: boolean = false;
	isFollowComplete: boolean = false;
	uri: string;
	desiredOrder: number[];
	showAllCategoryTypes: boolean;
	showCategoryBreadcrumbs: boolean;
	tree: ans.Category[];
	parentCategory: ans.Category;
	isMobile: boolean;
	showCategoryTypeAnchorMenu: boolean;
	combinedHeaderHeight: number;

	/*@ngInject*/
	constructor(private articleService: ArticleService,
		private $state: ng.ui.IStateService,
		private $location: ng.ILocationService,
		private followService: FollowService,
		private templateService: TemplateConfigService,
		private auth: Auth,
		private $rootScope,
		private $scope,
		private userToolbarService: UserToolbarService,
		private categoryService: CategoryService,
		private biService: BiService,
		sdk: Sdk,
		$timeout) {

			this.tree = categoryService.categoryTree;
			this.showAllCategoryTypes = this.templateService.showAllCategoryTypes();
			this.showCategoryBreadcrumbs = this.templateService.showCategoryBreadcrumbs();
			this.isMobile = this.templateService.isMobile();
			this.showCategoryTypeAnchorMenu = this.templateService.showCategoryTypeAnchorMenu();

			if (this.category) {
				let parentCategory = this.categoryService.getCachedCategoryById(this.category.parentId);
				if (parentCategory) {
					this.parentCategory = parentCategory;
				}
			}

			this.articlesByTypes = this.results;

			this.availableTypes.forEach( type => {
				this.totalArticlesCount[type] = this.articlesByTypes[type].itemsCount;
			});

			this.addTypeSearchParams();
			this.isUserAgent = auth.isAgent();
			let isLoggedIn = this.auth.isLoggedIn();
			if (isLoggedIn && this.isUserAgent) {
				this.followService.isCategoryFollowedByUser(this.category)
				.then((isFollowed) => {
					this.isFollowedByUser = isFollowed;
					this.userToolbarService.toggleCategoryFollow(isFollowed);
				});
			}

			this.desiredOrder = [ans.ArticleType.ARTICLE, ans.ArticleType.KNOWN_ISSUE, ans.ArticleType.FEATURE_REQUEST, ans.ArticleType.VIDEO];
			this.availableTypes.sort((a, b) => {
				return this.desiredOrder.indexOf(a) > this.desiredOrder.indexOf(b) ? 1 : -1;
			});

			sdk.setViewedPageData(PageType.category, this.category.id, {category: this.category, articles: this.results.items});
			$scope.$watch('$viewContentLoaded', () => {
				this.biService.logEvent(PublicEventType.CATEGORY_PAGE_LOAD, {
					category: this.parentCategory ? this.parentCategory : this.category,
					subcategory: this.parentCategory ? this.category : undefined
				});
			});

			userToolbarService.setCategoryContextView(this.getUserToolbarCategoryContext());

			$timeout(() => {
				const categoryHeaderHeight = 80;
				const hasUserToolbar = $rootScope.showUserToolbar;
				const headerHeight = $('#main-header').height() || $('#custom-header').height() || 0;
				const finalHeaderHeight = hasUserToolbar ? headerHeight + 10 : headerHeight;
				this.combinedHeaderHeight = finalHeaderHeight + categoryHeaderHeight;

				if (window.location.hash) {
					const anchorBlocks = $('ul.articles');
					const normalizedHash = window.location.hash.replace('#', '').replace(/-/g, ' ');
					const currentBlock = anchorBlocks.filter((_, block) => block.getAttribute('typeName').toLowerCase().trim() === normalizedHash);
					if (currentBlock.length) {
						this.scrollToAnchor(currentBlock);
					}
				}
			}, 500);
		}


		getUserToolbarCategoryContext(): CategoryContext {
			return {
				isFollowing: this.isFollowedByUser,
				onFollowToggle: (toggleFollow: boolean) => this.followCategory()
			};
		}

		viewMore() {
			const pageSize = this.templateService.categoriesPageSize();
			this.articleService.getByCategoryAndTypeAndSummarize(this.category.id, this.type, this.page + 1, pageSize)
			.then(results => {
				this.page++;
				this.articlesByTypes[this.type].items = this.articlesByTypes[this.type].items.concat(results.items);
				this.totalArticlesCount[this.type] = results.itemsCount;
			});
		}

		addTypeSearchParams() {
			if (!isNaN(this.type)) {
				var classificationStr = ans.ArticleType[this.type];
				if (classificationStr) {
					var classificationParamFormat = (this.type === ans.ArticleType.ARTICLE) ? null : classificationStr.toLowerCase().replace(/_/g, '-');
					this.$location.search('type', classificationParamFormat).replace();
				}
			}
		}

		shouldForceServerSideRoutingLink() {
			return shouldForceServerSideRouting(this.biService.logger);
		}

		followCategory() {
			this.isFollowComplete = false;
			let oldState = this.isFollowedByUser;
			let newState = !oldState;

			this.isFollowedByUser = newState;
			this.userToolbarService.toggleCategoryFollow(newState);
			this.followService.followCategoryToggle(this.category, newState)
			.catch(() => {
				this.isFollowedByUser = oldState;
				this.userToolbarService.toggleCategoryFollow(oldState);
			})
			.finally(() => this.isFollowComplete = true);
		}

		categoryNameClick(event) {
			if (this.isMobile) {
				this.$rootScope.$broadcast('category-name-clicked', { event});
			}
		}

		anchorClick(type: ans.ArticleType) {
			const $target = $(`#${type}`);
			const newHash = $target.attr('typeName').toLowerCase().trim().replace(/\s/g, '-');
			window.history.pushState({}, '', document.location.pathname + '#' + newHash);
			this.scrollToAnchor($target);
		}

		scrollToAnchor(anchor: JQuery) {
			const targetTop = anchor.offset().top;
			const windowHeight = $(window).height();
			const pageHeight = $('body').height();
			const targetOffset = targetTop - this.combinedHeaderHeight + 5;
			const finalOffset = windowHeight + targetOffset > pageHeight ? pageHeight - windowHeight : targetOffset;
			$('body,html').stop(true, true).animate({ scrollTop: finalOffset}, 500);
		}

		breadcrumbsCategoryClick(event) {
			if (this.isMobile) {
				this.$rootScope.$broadcast('category-name-clicked', { event});
			} else {
				this.$rootScope.$broadcast('category-name-clicked', { event, parent: this.parentCategory});
			}
		}
	}

	/*@ngInject*/
	export default function categoryPageDirectiveFactory($rootScope: ng.IRootScopeService, templateService: TemplateConfigService): ng.IDirective {
		return <ng.IDirective>{
			restrict: 'E',
			scope: {
				results: '=',
				category: '=',
				availableTypes: '=',
				type: '=',
				uri: '='
			},
			template: require('./category-page.tpl.ts'),
			controller: CategoryPageDirectiveController,
			controllerAs: 'ctrl',
			bindToController: true,
			link: function(scope: ng.IScope, element: JQuery, attrs: {}, controller: ng.IController) {
				if (templateService.showCategoryTypeAnchorMenu() && controller.availableTypes.length > 1) {

					let anchors = [];
					setTimeout(() => {
						anchors = controller.availableTypes.map(type => {
							const element = $(`#${type}`);
							return {element, type};
						});
					}, 100);
					$(window).scroll(function() {
						const currentAnchors = anchors.filter( curr => curr.element.offset().top - $(window).scrollTop() <= controller.combinedHeaderHeight);

						if (currentAnchors.length) {
							const currentAnchorType = currentAnchors[currentAnchors.length - 1].type;
							if (currentAnchorType && currentAnchorType !== controller.type) {
								controller.type = currentAnchorType;
								scope.$apply();
							}
						};
					});
				}
			}
		};
	}
