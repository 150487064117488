import {BrandingConfig} from '@wix/answers-api';

import teralabsIconMap from '../../layouts/teralabs/icons/icons-map';
import angelsEyeIconMap from '../../layouts/angels-eye/icons/icons-map';
import tinyIconMap from '../../layouts/tiny/icons/icons-map';

const svgIconDirectiveGenerator = (iconsMap: any) => {

	return {
		restrict: 'E',
		replace: true,
		link (_: any, elem: any, attrs: any) {
			const iconName = attrs.name;
			const svgContent = iconsMap[iconName];

			if (!svgContent && svgContent !== '') {
				throw new Error('Icon ' + iconName + ' was not found! make sure you added it to icons map');
			}

			elem.html(svgContent);
		}
	};
};


/* @ngInject */
export default function svgIconDirectiveFactory(brandingConfig: BrandingConfig): ng.IDirective {

	let iconsMap = {};

	const templateIconKeyMap = {
		'teralabs': teralabsIconMap,
		'angels-eye': angelsEyeIconMap,
		'tiny': tinyIconMap
	};

	let directive: any = svgIconDirectiveGenerator(templateIconKeyMap[brandingConfig.templateKey] || templateIconKeyMap.teralabs);
	return directive;
}
