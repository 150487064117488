import {renderUserEngagementPingerAndReturnUnmount, UserEngagementPingerProps, ActivityType} from '@wix/answers-common-components';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import * as ans from '@wix/answers-api';

const PING_INTERVAL = 15000;

class UserEngagementPingerDirectiveController {
	article: ans.Article;

	/* @ngInject */
	constructor($window, $element, $parse, $scope, $timeout, private biService: BiService) {

		const pingerProps: UserEngagementPingerProps = {
			onPing: (loggedActivities: ActivityType[]) =>
				loggedActivities.length &&
				this.biService.logEvent(PublicEventType.ARTICLE_ENGAGEMENT_PING, {activityType: loggedActivities, article: this.article}),
			pingInterval: PING_INTERVAL
		};

		let unmount = renderUserEngagementPingerAndReturnUnmount($element[0], pingerProps);
		$scope.$on('$destroy', () => unmount());
	}
}

/*@ngInject*/
export default function userEngagementPingerDirectiveFactory($window, $timeout): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			article: '=',
		},
		controller: UserEngagementPingerDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
