import BrandingConfig from './../branding/branding.i';
import AnalyticsService from './../../common/analytics/analytics.srv';
import VisibilityService from './../../common/services/visibility/visibility.srv';
import {HeaderFooterStyle, PreviewApiActions, ContactMethodType } from '@wix/answers-api';
import PublicContactSettings from '../../common/contact-settings/public-contact-settings.i';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';

class FooterDirectiveController {
	date: Date;
	trustedFooter: string;

	isContactButtonVisible: boolean;
	calculatedContactUsLink: string;

	isLightTheme: boolean;
	isDarkTheme: boolean;

	premiumFeaturesEnabled: boolean;
	marketingSiteUrl: string;
	showAnswersBanner: boolean = false;

	/*@ngInject*/
	constructor (private brandingConfig: BrandingConfig,
		customFooter,
		$sce: ng.ISCEService,
		private companyName: string,
		private companyId: string,
		contactUsLink: string,
		private $state: ng.ui.IStateService,
		private analyticsService: AnalyticsService,
		private visibilityService: VisibilityService,
		contactFormEnabled: boolean,
		$rootScope: ng.IRootScopeService,
		private $scope: ng.IScope,
		helpcenterContactSettings: PublicContactSettings,
		private biService: BiService,
		premiumKb: boolean
	) {
		this.date = new Date();
		this.trustedFooter = $sce.trustAsHtml(customFooter);

		if (helpcenterContactSettings) {
			const contactMethod = helpcenterContactSettings.contactMethod;

			if (contactMethod.type === ContactMethodType.EXTERNAL) {
				this.calculatedContactUsLink = contactMethod.externalUrl;
			} else if (contactMethod.type === ContactMethodType.INTERNAL) {
				this.calculatedContactUsLink = $state.href('app.submit');
			} else if (contactMethod.type === ContactMethodType.EMAIL) {
				this.calculatedContactUsLink = `mailto:${contactMethod.email}`;
			}
		}

		this.isContactButtonVisible = helpcenterContactSettings.contactButtonEnabled;
		this.calculatedContactUsLink = this.calculatedContactUsLink;

		this.isLightTheme = brandingConfig.headerFooterStyle === HeaderFooterStyle.LIGHT;
		this.isDarkTheme = brandingConfig.headerFooterStyle === HeaderFooterStyle.DARK;
		this.$scope.$on(PreviewApiActions.changeHeaderFooterStyle, (e, data) => {
			this.$scope.$apply(() => {
				this.isLightTheme = data === HeaderFooterStyle.LIGHT;
				this.isDarkTheme = data === HeaderFooterStyle.DARK;
			});
		});
		this.setContactVisibility($state.current.name);
		$rootScope.$on('$stateChangeStart', (event, data) => {
			this.setContactVisibility(data.name);
		});

		this.showAnswersBanner = false;

		this.marketingSiteUrl = `https://www.wixanswers.com/?referral=hcbanner-[${this.companyId}]`;
	}

	// TODO: Remove when contact form / settings / custom fields feature stabilized
	// OLD FLOW BASED ON FEATURE TOGGLE
	// ***
	calculateLegacyContactLink(contactUsLink: string, isContactFormEnabled: boolean): string {
		let calculatedLegacyLink: string;
		if (contactUsLink) {
			calculatedLegacyLink = contactUsLink;
		} else if (this.visibilityService.isTicketingVisible() && isContactFormEnabled) {
			calculatedLegacyLink = this.$state.href('app.submit');
		} else {
			calculatedLegacyLink = this.brandingConfig.logoTargetUrl;
		}

		return calculatedLegacyLink;
	}

	setContactVisibility(stateName: string) {
		if (stateName === 'app.submit') {
			$('body #need-more-help').hide();
		} else {
			$('body #need-more-help').show();
		}
	}

	onBeforeContactClick() {
		this.biService.logEvent(PublicEventType.CONTACT_US_CLICKED, {});
		this.analyticsService.logContactSupportClick();
	}
}

function footerDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		template: require('./footer.tpl.ts'),
		restrict: 'E',
		controllerAs: 'footer',
		controller: FooterDirectiveController
	};
};

export default footerDirectiveFactory;
