import * as ans from '@wix/answers-api';
import SdkEventType from './sdk-events.enum';
import Auth from './../../auth/auth.srv';
import BiService from '../bi/bi.srv';
import { triggerAppLoaded } from '../../fedops';
import { isFeatureEnabled } from '@wix/answers-experiments';
import { ContactFormInitialFieldData, User } from '@wix/answers-api';


export enum PageType {
	userProfile = 1,
	ticket = 2,
	article = 3,
	submitTicket = 4,
	category = 5,
	searchResults = 6,
	ticketsPage = 7,
	homePage = 8
}

var _window: any = window;

class ExposedSdk {

	onLoadListeners = [];
	internalSdk: any = null;
	events = SdkEventType;
	articleTypes = ans.ArticleType;
	pages = PageType;
	deferredHandlers = [];
	eventsPrefix: string = 'answersEvent';
	currentLang: string = null;

	contentFilters: Partial<ans.ArticleSearchDTO> = {};
	ticketFields: Partial<ContactFormInitialFieldData> = {};
	setTicketFieldsAgain: () => void = () => { return };

	userLoadedCb: Array<(user?: User) => void> = [];

	hasLoaded = false;

	setCurrentLang(lang: string) {
		this.currentLang = lang;
	}

	private getPreffixedEventName(eventType: SdkEventType) {
		let eventName = SdkEventType[eventType];
		return `${this.eventsPrefix}.${eventName}`;
	}

	onLoad(cb) {
		this.onLoadListeners.push(cb);
	}

	private ensurePrivateSdkReady() {
		if (!this.internalSdk) {
			throw new Error('SDK did not fully load yet. Make sure to use it only inside the onLoad listener');
		}
	}

	triggerLoad() {
		this.onLoadListeners.forEach(cb => {
			try {
				cb();
			} catch (e) {
				console.error('there is an error with answersSdk', e);
			}
		});

		this.hasLoaded = true;

		this.deferredHandlers.forEach(cb => cb());
	}

	private isValidEventType(type) {
		type = type && type.toString();
		return Object.keys(this.events).indexOf(type) !== -1;
	}

	addListener(type, cb) {
		if (this.isValidEventType(type)) {
			return $(document).on(this.getPreffixedEventName(type), (e, data) => {

				if (this.internalSdk) {
					if (cb && typeof cb === 'function') {
						cb(data);
					}
				} else {
					this.deferredHandlers.push(() => cb(data));
				}
			});
			// this.ensurePrivateSdkReady();
		} else {
			throw new Error ('Unknown event type: ' +  type);
		}

	}

	removeListener(type) {
		$(document).off(this.getPreffixedEventName(type));
	}

	getUnreadNotificationsCount(): number {
		this.ensurePrivateSdkReady();
		return this.internalSdk.getUnreadNotificationsCount();
	}


	isUserLoggedIn(): boolean {
		this.ensurePrivateSdkReady();
		return this.internalSdk.isUserLoggedIn();
	}

	getCurrentUser(): ans.User {
		this.ensurePrivateSdkReady();
		return this.internalSdk.getCurrentUser();
	}

	getCurrentUserId(): ans.User {
		this.ensurePrivateSdkReady();
		return this.internalSdk.getCurrentUserId();
	}

	getCurrentLanguage(): string {
		if (!this.currentLang) {
			throw new Error ('Current lang is not set yet. Please contact support');
		}
		return this.currentLang;
		// this.ensurePrivateSdkReady();
		// return this.internalSdk.getCurrentLanguage();
	}

	getVisiblePageType(): string {
		this.ensurePrivateSdkReady();
		return this.internalSdk.getVisiblePageType();
	}

	getVisiblePageData(): string {
		this.ensurePrivateSdkReady();
		return this.internalSdk.getVisiblePageData();
	}

	getVisiblePageItemId() {
		this.ensurePrivateSdkReady();
		return this.internalSdk.getVisiblePageItemId();
	}

	login() {
		this.ensurePrivateSdkReady();
		return this.internalSdk.login();
	}

	logout() {
		this.ensurePrivateSdkReady();
		return this.internalSdk.logout();
	}

	setLabelIdsToAddOnNewTicketSubmit(ids: string[]) {
		this.ensurePrivateSdkReady();
		return this.internalSdk.setLabelsToAddOnNextSubmit(ids);
	}

	setContentFilters(filters: ans.ArticleSearchDTO) {
		this.contentFilters = filters;
		if (this.hasLoaded) {
			console.warn('Content filters was changed after app has loaded. They will only affect subsequent navigation and search results.')
		}
	}

	setTicketFields = (fields: Partial<ContactFormInitialFieldData>) => {
		this.ticketFields = fields;
		this.setTicketFieldsAgain();
	}

	onUserLoaded = (cb: (user?: User) => void) => {
		this.userLoadedCb.push(cb);
	}

	// isUserLoggedIn: () => {
	// 	return this.auth.isLoggedIn();
	// },
	// login: () => {
	// 	return this.auth.goToSignin();
	// },
	// getCurrentUser: () => {
	// 	return this.auth.getCurrentUser();
	// },
	// getCurrentUserId: () => {
	// 	const currentUser = this.auth.getCurrentUser();
	// 	return currentUser ? currentUser.userId : null;
	// }


}

export const exposedSdk = new ExposedSdk();

_window.answersSdk = exposedSdk;

export default class Sdk {
	$document;

	currentLang: string;
	currentPage: PageType;
	currentPageItemId: string;
	currentPageExtraData: any;
	eventsPrefix: string = 'answersEvent';
	labelsToAddOnNextSubmit: string[];

	private getPreffixedEventName(eventType: SdkEventType) {
		let eventName = SdkEventType[eventType];
		return `${this.eventsPrefix}.${eventName}`;
	}

	triggerAppReady() {
		triggerAppLoaded();
		//safety timeout
		setTimeout(() => {
			this.exposeGlobalObject();
		});
	}

	getLabelsToAddOnNextSubmit() {
		return this.labelsToAddOnNextSubmit || [];
	}

	setLabelsToAddOnNextSubmit(labelIds: string[]) {
		this.labelsToAddOnNextSubmit = labelIds;
	}

	resetLabelsToAddOnNextSubmit() {
		this.labelsToAddOnNextSubmit = [];
	}

	/*@ngInject*/
	constructor($document, private $window, appConfig: ans.AppConfig, private auth: Auth, private biService: BiService) {
		this.$document = $($document);
		this.currentLang = appConfig.lang;
	}

	addExternalLogHandler(handler) {
		this.biService.addHandler(handler);
	}

	setViewedPageData(pageType: PageType, itemId: string = null, extraData?: any) {
		this.currentPage = pageType;
		this.currentPageItemId = itemId;
		this.currentPageExtraData = extraData;

		//allow directive to load properly...
		setTimeout(() => {
			this.triggerEvent(SdkEventType.pageLoad, {pageType: pageType, itemId: itemId, data: extraData});
		}, 100);
	}

	setUnreadNotificationsCount(count: number) {
		this.triggerEvent(SdkEventType.unreadNotificationsCount, count);
	}

	getContentFilters = () => {
		return exposedSdk.contentFilters || {};
	};

	getTicketFields = () : Partial<ContactFormInitialFieldData> => {
		return exposedSdk.ticketFields || {};
	}

	setContactFormPropsCB(cb: () => void) {
		exposedSdk.setTicketFieldsAgain = cb;
	}

	triggerTicketFieldsChanged = (fields: ContactFormInitialFieldData) => {
		this.triggerEvent(SdkEventType.contactFieldsChanged, fields);
	}

	exposeGlobalObject() {
		var internalSdk = {
			getVisiblePageType: () => {
				return this.currentPage;
			},
			getVisiblePageItemId: () => {
				return this.currentPageItemId;
			},
			getVisiblePageData: () => {
				return this.currentPageExtraData;
			},
			isUserLoggedIn: () => {
				return this.auth.isLoggedIn();
			},
			login: () => {
				return this.auth.goToSignin();
			},
			logout: () => {
				return this.auth.goToSignout();
			},
			getCurrentUser: () => {
				return this.auth.getCurrentUser();
			},
			getCurrentUserId: () => {
				const currentUser = this.auth.getCurrentUser();
				return currentUser ? currentUser.id : null;
			},
			getCurrentLanguage: () => {
				return this.currentLang;
			},
			setLabelsToAddOnNextSubmit: (ids) => {
				return this.setLabelsToAddOnNextSubmit(ids);
			},
			addExternalLogHandler: (handler) => {
				this.addExternalLogHandler(handler);
			}
		};

		exposedSdk.internalSdk = internalSdk;
		exposedSdk.triggerLoad();
	}

	triggerEvent(eventType: SdkEventType, data: any) {
		this.$document.trigger(this.getPreffixedEventName(eventType), [data]);
	}
}
