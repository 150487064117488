
/* tslint:disable */
const templateData = `
<div class="loader-container">
	<svg width="48px" height="62px" viewBox="0 0 48 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	    <title>loader fix</title>
	    <g id="SVGs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
	        <g id="B-CategoryPage-Copy" transform="translate(-541.000000, -241.000000)"></g>
	        <g id="loader-fix">
	            <path d="M0,3.99642766 C0,1.78926161 1.79405245,0 4.00989318,0 L43.9901068,0 C46.2047097,0 48,1.78867531 48,3.99642766 L48,58.0035723 C48,60.2107384 46.2059475,62 43.9901068,62 L4.00989318,62 C1.79529033,62 0,60.2113247 0,58.0035723 L0,3.99642766 L0,3.99642766 L0,3.99642766 Z M2,3.99642766 L2,58.0035723 C2,59.1049984 2.89810825,60 4.00989318,60 L43.9901068,60 C45.1023586,60 46,59.1051821 46,58.0035723 L46,3.99642766 C46,2.89500163 45.1018918,2 43.9901068,2 L4.00989318,2 C2.89764143,2 2,2.89481789 2,3.99642766 L2,3.99642766 Z M30.75,2 L46,17.25 L46,14.5 L33.5,2 L30.75,2 L30.75,2 Z" id="Combined-Shape" fill="#66737C"></path>
	            <rect id="line1" fill="#C8D6E0" x="12" y="21" width="24" height="2">
	                <animate
	                  id="frame1"
	                  attributeName="width"
	                  values="0;18;24;24;14;6;0;0"
	                  keyTimes="0;0.14;0.28;0.43;0.57;0.72;0.86;1"
	                  dur="1.05s"
	                  begin="0s"
	                  repeatCount="indefinite" />
	            </rect>
	            <rect id="line2" fill="#C8D6E0" x="12" y="27" width="24" height="2">
	                <animate
	                  id="frame2"
	                  attributeName="width"
	                  values="0;6;14;24;24;10;2;0"
	                  keyTimes="0;0.14;0.28;0.43;0.57;0.72;0.86;1"
	                  dur="1.05s"
	                  begin="0s"
	                  repeatCount="indefinite" />
	            </rect>
	            <rect id="line3" fill="#C8D6E0" x="12" y="33" width="24" height="2">
	                <animate
	                  id="frame3"
	                  attributeName="width"
	                  values="0;0;9;18;22;24;5;0"
	                  keyTimes="0;0.14;0.28;0.43;0.57;0.72;0.86;1"
	                  dur="1.05s"
	                  begin="0s"
	                  repeatCount="indefinite" />
	            </rect>
	            <rect id="line4" fill="#C8D6E0" x="12" y="39" width="24" height="2">
	                <animate
	                  id="frame4"
	                  attributeName="width"
	                  values="0;0;0;0;14;22;24;0"
	                  keyTimes="0;0.14;0.28;0.43;0.57;0.72;0.86;1"
	                  dur="1.05s"
	                  begin="0s"
	                  repeatCount="indefinite" />
	            </rect>
	        </g>
	    </g>
	</svg>
	<div class="loading-text">{{::'common.loading' | translate}}...</div>
</div>

`;
export = templateData;
