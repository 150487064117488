import { renderAnchorNavigationAndReturnUnmount } from '@wix/answers-common-components/dist/components-viewer/anchor-navigation/anchor-navigation-renderer';

class ArticlePageAnchoringDirectiveController {
	anchors: any;
	currentAnchorId: string;
	locale: string;
	hasAnchors: boolean;
	components: any;
	anchorChanging: boolean = false;

	/* @ngInject */
	constructor($window, $element, $parse, $scope, $timeout) {
		this.anchors = this.components
			.filter(comp => comp.type === 'heading' && comp.value && comp.value.anchorId)
			.map(comp => comp.value.anchorId)
			.map(id => $(`[id=${id}]`));

		this.hasAnchors = this.components.filter(c => c.type === 'heading' && !!c.value.anchorId).length > 0;
		const currentUrlWithoutAnchor = window.location.href.replace(/#.*$/, '');
		var onAnchorIdChange = (cb: ((anchorId: string) => void)) => {
			$scope.$on('updateAnchorId', () => cb(this.currentAnchorId));
		};

		var unmount = renderAnchorNavigationAndReturnUnmount($element[0], this.locale, this.components, onAnchorIdChange);
		$scope.$on('$destroy', () => unmount());

		$timeout(() => {
			if (this.hasAnchors) {
				var anchorHeight = $('.anchor-container').height();
				var anchorTop = parseInt($('anchor-navigation').css('top'), 10);

				// https://jira.wixpress.com/browse/WA-5356
				const maxHeight = $('hero').height() + $('.sitemap.breadcrumbs').height();
				$('.anchor-container anchor-navigation').css('max-height', 'calc(100vh - ' + maxHeight + 'px)');

				anchorHeight = (anchorTop) ? anchorHeight + anchorTop : anchorHeight;
				$('.anchor-container').css({ height: anchorHeight });

				const anchorLink = `a[href^="${currentUrlWithoutAnchor}"]`;

				$(`.anchor-container ${anchorLink}`).unbind('click').click(function (e) {
					if (!this.anchorChanging) {
						this.anchorChanging = true;
						e.preventDefault();
						e.stopPropagation();
						var $this = $(this);
						if ($this.attr('href').length > 0) {
							var newHash = $this.attr('href').replace(currentUrlWithoutAnchor, '');
							$('body,html').stop(true, true).animate({
								scrollTop: $(newHash).offset().top - 150
							}, 500, 'swing', () => {
								this.anchorChanging = false;

								if ($(newHash).length > 0) {
									$window.history.pushState({}, '', document.location.pathname + newHash);
								}
							});
						}

						return false;
					}
				});

				$($window).on('scroll', () => {
					let anchors = this.anchors.filter(curr => curr.offset().top - $(window).scrollTop() <= 180);
					if (anchors.length) {
						var currentAnchorId = anchors[anchors.length - 1].attr('id');
						if (currentAnchorId && currentAnchorId !== this.currentAnchorId) {
							this.currentAnchorId = currentAnchorId;
							$scope.$emit('updateAnchorId', this.currentAnchorId);
						}
						var isSticky = $('anchor-navigation').hasClass('sticky');

						const anchorHref = 'a[href="' + currentUrlWithoutAnchor + '#' + this.currentAnchorId + '"]';

						var anchorElement = $(anchorHref);

						if (isSticky && ((anchorElement.offset().top - $(window).scrollTop()) >= 300 || (anchorElement.offset().top - $(window).scrollTop()) <= 90)) {
							$window.document.body.querySelector(`.anchor-container ${anchorHref}`).scrollIntoView()
						}
						if (currentAnchorId === this.anchors[0].attr('id')) {
							$('anchor-navigation').animate({
								scrollTop: 0
							}, 5);
						};
					};
				});
				// // fix li item click bug
				$('li.anchor').unbind('click');

				if ($window.location.hash) {
					const anchorHrefInList = 'a[href="' + currentUrlWithoutAnchor + $window.location.hash + '"]';
					$(anchorHrefInList).trigger('click');
				}
			}
		}, 1000);
	}
}

/*@ngInject*/
export default function articlePageAnchoringDirectiveFactory($window, $timeout): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			components: '=',
			locale: '='
		},
		controller: ArticlePageAnchoringDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
