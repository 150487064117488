/*@ngInject */
export default function clickOutsideDirectiveFactory($document): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		link: function(scope, elem, attr: {clickOutside}) {
			elem.bind('click', function(e) {
				e.stopPropagation();
			});

			$document.bind('click', function() {
				scope.$apply(attr.clickOutside);
			});
		}
	};
}
