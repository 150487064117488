/**
* Created by Elad_Swissa on 7/8/15.
*/
/// <reference path="../../references.ts" />

import SubmitTicketService from './../../submit/submit-a-ticket-service/submit-a-ticket.srv';
import Tickets from './../../ugc/ticket-service/tickets.srv';
import * as ans from '@wix/answers-api';
import Sdk from '../../common/sdk/sdk.srv';

export interface CategoryTopArticles {
	categoryId: string;
	articles: ans.Article[];
}

export default class ArticleService {

	/*@ngInject*/
	constructor(private $http: ng.IHttpService, private $httpParamSerializer, private appConfig, private submitTicketService: SubmitTicketService, private sdk: Sdk) { }

	articlesApiPath = this.appConfig.apiPath + `/helpcenter/articles/`;

	public get(uri: string): ng.IPromise<ans.Article> {;
		return this.$http.get(this.articlesApiPath + 'uri/' + uri, {
			params: {
				// uri: uri,
				locale: this.appConfig.lang
			}
		})
		.then((response) => response.data as ans.Article);
	}

	public getById(articleId: string, locale: string, defaultLocaleFallback = false): ng.IPromise<ans.Article> {
		return this.$http.get(this.articlesApiPath + articleId, {
			params: {
				locale,
				defaultLocaleFallback: defaultLocaleFallback
			}
		})
		.then((response) => response.data as ans.Article);
	}

	public getItems(categoryId: string, lang: string): ng.IPromise<ans.SearchResult<ans.Article>> {
		return this.$http.post(this.articlesApiPath + 'search', {
			categoryIds: [categoryId],
			page: 1,
			pageSize: 5,
			sortType: ans.ArticleSortType.LAST_UPDATE_DATE_DESC,
			locale: lang
		})
		.then((response) => response.data as any);
	}

	public getByCategoryAndTypeAndSummarize(categoryId: string, type: ans.ArticleType, page: number = 1, pageSize: number = 20): ng.IPromise<ans.SearchResult<ans.Article>> {
		return this.$http.post(this.articlesApiPath + 'search', {
			locale: this.appConfig.lang,
			pageSize: pageSize,
			sortType: ans.ArticleSortType.POSITION,
			page: page,
			summarize: true,
			...this.sdk.getContentFilters(),
			categoryIds: [categoryId],
			types: [type],
		}).then(response => response.data as any);
	}

	public getCategoryTypesCounts(categoryId: string): ng.IPromise<any> {
		return this.$http.get(this.articlesApiPath + 'types/count', {
			params: {
				locale: this.appConfig.lang,
				categoryId: categoryId
			}
		}).then(response => response.data);
	}

	public getRelatedItems(itemId: string, lang: string): ng.IPromise<ans.Article[]> {
		var relatedParams = {
			params: {
				locale: lang
			}
		};

		return this.$http.get(this.articlesApiPath + itemId + '/relations', relatedParams)
		.then((response) => response.data as any);
	}

	public submitLinkedTicket(articleItem: ans.Article, ticketData: ans.ContactFormSubmitData, isGuest: boolean): ng.IPromise<ans.Ticket> {
		return this.submitTicketService.submitTicket(ticketData, isGuest, articleItem.id);
	}

	public getTopItems(): ng.IPromise<ans.Article[]> {
		const topArticleApiPath = this.articlesApiPath + 'featured/';
		return this.$http.get(topArticleApiPath + this.appConfig.lang)
		.then((response) => response.data as any);
	}

	public getArticleItemCounters(article: ans.Article): ng.IPromise<ans.Counters> {
		return this.$http.get<ans.CountersResult[]>(this.articlesApiPath + article.id + '/counters', {
			params: {
				lang: article.locale
			}
		})
		.then(res => {
			let counters: ans.Counters = ans.CountersHelper.convertCounterResultsToCounters(res.data);
			return counters;
		});
	}

	public getCategoriesTopArticles(categoryIds: string[], limit: number): ng.IPromise<CategoryTopArticles[]> {
		return this.$http.post(this.articlesApiPath + 'categories/top', {
			locale: this.appConfig.lang,
			categoryIds: categoryIds,
			limit: limit
		})
		.then((response) => response.data as any);
	}
}
