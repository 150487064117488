/// <reference path="../../references.ts" />
'use strict';

import UserToolbarService from './user-toolbar.srv';
import {User} from '@wix/answers-api';
import {renderUserToolbarAndReturnUnmount, UserToolbarProps, ToolbarContextSections} from '@wix/answers-common-components';
import Auth from '../../auth/auth.srv';

class UserToolbarDirectiveController {
	unmount: any;
	locale: string;
	user: User | null;
	scope: ng.IScope;

	/* @ngInject */
	constructor($window, $element, private $scope, private $rootScope, private auth: Auth, private userToolbarService: UserToolbarService, private translateFilter) {
		// Stopped passing user to userToolbar because we do not have the logged in agent object
		this.user = null;

		const toggleExpansion = () => {
			$rootScope.$apply(() => $rootScope.isToolbarExpanded = !$rootScope.isToolbarExpanded);
			userToolbarService.safelyRerenderToolbar();
		};

		const renderComp = () => {
			const props: UserToolbarProps = {
				isExpanded: $rootScope.isToolbarExpanded,
				user: this.user,
				toggleExpansion,
				translate: translateFilter,
				visibleContext: $rootScope.userToolbarVisibileContext || ToolbarContextSections.NONE,
				contextData: $rootScope.contextData || {}
			};

			this.unmount = renderUserToolbarAndReturnUnmount($element[0], props);
		};

		const init = (user: User) => {
			this.user = user;
			$scope.$on('$destroy', () => this.unmount());
			userToolbarService.init(renderComp);

			renderComp();
		}


		init(this.user);

	}
}

export default function userToolbarDirectiveFactory(): ng.IDirective {
	return <ng.IDirective> {
		restricnt: 'E',
		scope: {
			locale: '='
		},
		controller: UserToolbarDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
