import { isFeatureEnabled } from '@wix/answers-experiments';
/// <reference path="../references.ts" />
import UserToolbarService from '../structure/user-toolbar/user-toolbar.srv';
import Auth from './../auth/auth.srv';
import CategoryService from './../common/category/category.srv';
import BrandingConfig from './../structure/branding/branding.i';
import Sdk from './../common/sdk/sdk.srv';
import Prerender from './../common/prerender/prerender.srv';
import AnalyticsService from './../common/analytics/analytics.srv';

var url = location.href;
var expr = /(\/[a-zA-Z][a-zA-Z])\?/;
if (url.match(expr)) {
	url = url.replace(expr, '$1\/\?');
	location.href = url;
}

/*@ngInject*/
function mainAppRouting($stateProvider: ng.ui.IStateProvider,
	$urlRouterProvider: ng.ui.IUrlRouterProvider,
	$locationProvider: ng.ILocationProvider) {

		$locationProvider.html5Mode(true);
		$locationProvider.hashPrefix('!');
		$urlRouterProvider.otherwise('/');
		$urlRouterProvider.rule(function ($injector, $location) {
			var path = $location.path(), normalized = path.toLowerCase();
			if (path !== normalized) {
				$location.replace().path(normalized);
			}
		});

		$stateProvider
		.state('app', {
			template: require('../structure/main.tpl.ts'),
			resolve: {
				authInit: function(auth: Auth) {
					return auth.init();
				},
				categoryInit: function(categoryService: CategoryService) {
					return categoryService.init();
				},
				analyticsInit: function(analyticsService: AnalyticsService) {
					return analyticsService.sessionStart();
				}
			},
			controller: function(auth: Auth, brandingConfig: BrandingConfig, $rootScope, $state, sdk: Sdk, userToolbarService: UserToolbarService) {
				$rootScope.isAppReady = true;
				this.currentUser = auth.getCurrentUser();
				this.brandingConfig = brandingConfig;
				this.$rootScope = $rootScope;

				$rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
					$('body').removeClass('state-changing');
					userToolbarService.setNoneContextView();
					//	 console.log('toState', toState);
					//	 console.log('toParams', toParams);
				});

				$rootScope.$on('$stateChangeStart', (event, to, params) => {
					$('body').addClass('state-changing');
					if (to.redirectTo) {
						event.preventDefault();
						$state.go(to.redirectTo, params);
					}
				});
			},
			controllerAs: 'main',
			abstract: true
		});
	}

	export default mainAppRouting;
