import UserToolbarService from '../structure/user-toolbar/user-toolbar.srv';
import TemplateConfigService from './template-config/template-config.srv';
/*@ngInject*/
export default function stickyInnerHeaderDirectiveFactory($rootScope: ng.IRootScopeService, templateService: TemplateConfigService, userToolbarService: UserToolbarService): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		bindToController: true,
		link: function(scope: ng.IScope, element: JQuery, attr: {fixedHeight: boolean, stickyBottomSelector: string}) {
			var elemTop = element.offset().top;

			const getCustomHeaderHeight = () => {
				const customheaderElem = $('#custom-header');

				if (customheaderElem && customheaderElem.css('position') === 'fixed') {
					return customheaderElem.height();
				} else {
					return 0;
				}
			}

			$(window).scroll(function() {
				const hasUserToolbar = ($rootScope as any).showUserToolbar;

				let headerHeight = $('#main-header').height() || getCustomHeaderHeight() ||  0;
				let toolbarExpandedHeight = hasUserToolbar && !!($rootScope as any).isToolbarExpanded ? 39 : 10;
				let finalHeaderHeight = hasUserToolbar ? headerHeight + toolbarExpandedHeight : headerHeight;
				let windowHeight = $(window).height();
				let windowScrollTop = $(window).scrollTop();
				let footerTop = $('main-footer').offset().top;
				if (element.css('position') !== 'fixed' || elemTop < finalHeaderHeight) {
					elemTop = element.offset().top;
				}
				const getElemKey = element.attr('sticky-key');

				var bottom = ($(attr.stickyBottomSelector).offset() ? $(attr.stickyBottomSelector).offset().top : 0);

				if (windowScrollTop > elemTop - finalHeaderHeight && (!bottom || windowScrollTop + (elemTop - finalHeaderHeight) < bottom)) {
					if (!element.hasClass('sticky') && templateService.ignoreStickyElementsList(element.attr('class') || '')) {
						setTimeout(() => {
							element.addClass('sticky');

							const parent = element.parents();
							if (parent && getElemKey) {
								parent.addClass('sticky-parent-' + getElemKey);
							}
							if (finalHeaderHeight) {
								element.css({top: finalHeaderHeight});
							}
						}, 1);
					}
				} else {

					if (element.hasClass('sticky')) {
						element.removeClass('sticky');
						if (finalHeaderHeight) {
							element.css({top: ''});
						}
					}
					const parent = element.parents();
					if (parent && getElemKey && parent.hasClass('sticky-parent-' + getElemKey)) {
						parent.removeClass('sticky-parent-' + getElemKey);
					}
				}
			});
		}
	};
}
