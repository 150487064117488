/**
* Created by Elad_Swissa on 7/23/15.
*/
/// <reference path="../../references.ts" />

'use strict';


import SeoManager from '../../seo/seo-manager.srv';
import * as ans from '@wix/answers-api';
import {TestUtils, CategoryTreeBuilder} from './../../test-utils';
import { AppConfig } from '@wix/answers-api';
import Search from '../../common/article-search/article-search.srv';

/*@ngInject*/
function articleSearchRouting($stateProvider: ng.ui.IStateProvider) {
	$stateProvider.state('app.search', {
		url: '/search?term',
		template: '<article-search-page search-results="articleSearch.searchResults" search-term="articleSearch.searchTerm" search-method="articleSearch.searchMethod" search-version="articleSearch.searchVersion"></article-search-page>',
		resolve: {
			searchResults: function($stateParams, articleSearch: Search, seoManager: SeoManager, appConfig: AppConfig) {
				let term = $stateParams.term;
				try {
					term = decodeURI($stateParams.term);
				} catch (e) {console.log(e)};
				//decodeURI($stateParams.term)
				return articleSearch.search(term, 20)
				.then((searchResults) => {
					const placeholderKeys = ans.pagePlaceholderKeys.SEARCH;
					let seoPlaceholders = {};
					seoPlaceholders[placeholderKeys.SEARCH_TERM] = term;

					seoManager.fetchAndSetData(ans.PageType.SEARCH_RESULTS, appConfig.lang, seoPlaceholders);
					return searchResults.data;
				});
			}
		},
		controller: function(searchResults, $stateParams) {
			this.searchTerm = $stateParams.term;
			this.searchResults = searchResults.items;
			this.searchMethod = searchResults?.searchMethod;
			this.searchVersion = searchResults?.searchVersion;
		},
		controllerAs: 'articleSearch'
	});
}

export default articleSearchRouting;
