/// <reference path="../references.ts" />

import Auth from './auth.srv';


/*@ngInject*/
function authRoutingController($state, $window)	{
	$state.go('app.home');
	$window.location.reload();
}


/*@ngInject*/
function authRouting($stateProvider: ng.ui.IStateProvider) {

	$stateProvider
	.state('app.signout', {
		url: '/signout',
		resolve: {
			logout: function (auth: Auth, $state: ng.ui.IStateService, $window) {
				auth.logout();
				return true;
			}
		},
		controller: authRoutingController
	});
};

export default authRouting;
