/// <reference path="../../../references.ts" />

'use strict';

import { AppConfig, ResolvedCustomField } from '@wix/answers-api';

export default class CustomFieldsService {
	customFieldsUrl: string;

	/*@ngInject*/
	constructor(private $http: ng.IHttpService, private appConfig: AppConfig) {
		this.customFieldsUrl = `${this.appConfig.apiPath}/settings/helpcenter/formfields`;
	}

	public getResolvedCustomFields(locale: string): ng.IPromise<ResolvedCustomField[]> {
		return this.$http.get(`${this.customFieldsUrl}?locale=${locale}`).then(res => res.data as ResolvedCustomField[]);
	}
}
