import * as ans from '@wix/answers-api';

/*@ngInject*/
export default function scrollOnFocus($document): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		bindToController: true,
		link: function (scope, elem, attr) {
			if ($(window).width() < 768) {
				elem.on('focus', function() {
					var headerHeight = $('#main-header').height() || $('#custom-header').height() ||  0;
					$('body,html').stop(true, true).animate({
						scrollTop: $(this).offset().top - $(this).height() - headerHeight
					}, 500);
				});
			}
		}
	};
};
