/**
 * Created by Elad_Swissa on 7/23/15.
 */
/// <reference path="../references.ts" />

'use strict';

import articleSearchRouting from './article-search-routing/article-search-routing';
import articleSearchPageDirectiveFactory from './article-search-page/article-search-page.drv';
import Search from './../common/article-search/article-search.srv';

angular.module('search', ['core', 'ui.router', 'ngSanitize', 'common', 'categories', 'seo'])
		.config(articleSearchRouting)
		.service('articleSearch', Search)
		.directive('articleSearchPage', articleSearchPageDirectiveFactory);
