'use strict';

export default class Prerender {

	/*@ngInject*/
	constructor(private $document, private $window) {}
	private createMetaTag(): JQuery {
		return $('<meta name="prerender-status-code"></meta>');
	}

	private prerenderUserAgentPattern = /prerender/i;

	private createHeaderMetaTag(): JQuery {
		return $('<meta name="prerender-header"></meta>');
	}

	private getMetaTag() {
		return this.$document.find('head meta[name="prerender-status-code"]');
	}

	private getHeaderMetaTag() {
		return this.$document.find('head meta[name="prerender-header"]');
	}

	private setLocationHeader(url: string) {
		this.getHeaderMetaTag().remove();
		var $meta = this.createHeaderMetaTag().attr('content', 'Location: ' + url);
		this.$document.find('head').append($meta);
	}

	isPrerenderMode(): boolean {
		try {
			return this.prerenderUserAgentPattern.test(this.$window.navigator.userAgent);
		} catch (e) {
			return false;
		}
	}

	setPermanentRedirect(url: string) {
		this.setLocationHeader(url);
		this.setStatusCode(301);
	}

	setTemporaryRedirect(url: string) {
		this.setLocationHeader(url);
		this.setStatusCode(302);
	}

	getRedirectLocation(): string {
		var content = this.getHeaderMetaTag().attr('content');
		if (content) {
			content =	content.replace('Location: ', '');
		}
		return content;
	}

	setStatusCode(status: number) {
		this.clearStatus();
		var $meta = this.createMetaTag();
		$meta.attr('content', status);
		this.$document.find('head').append($meta);
	}

	getStatusCode(): number {
		return parseInt(this.getMetaTag().attr('content'), 10) || null;
	}

	set403() {
		this.setStatusCode(403);
	}

	set404() {
		this.setStatusCode(404);
	}

	clearStatus() {
		this.getMetaTag().remove();
	}

	setPrerenderReady() {
		const w: any = window;
		w.prerenderReady = true
	}
}
