/// <reference path="../../references.ts" />
'use strict';

import * as ans from '@wix/answers-api';
import Sdk from './../../common/sdk/sdk.srv';
import SdkEventType from './../../common/sdk/sdk-events.enum';
import AnalyticsService from './../../common/analytics/analytics.srv';

class SubmitTicketService {

	ticketsUrl: string;

	/*@ngInject*/
	constructor(private $http: ng.IHttpService,
		private $httpPostForm,
		private sdk: Sdk,
		private guestContactFormEnabled: boolean,
		private appConfig: ans.AppConfig,
		private analyticsService: AnalyticsService) {
			this.ticketsUrl = appConfig.apiPath + '/tickets';
		}

		articlesSearchApiPath = this.appConfig.apiPath + `/helpcenter/articles/search`;

		public isGuestContactFormEnabled() {
			return this.guestContactFormEnabled;
		}

		private attachmentsToHtml(attachments): string {
			const attachmentItems = attachments.reduce((res, item) => {
				return res + `<li><a href="${item.url}" target="_blank">${item.name}</a></li>`;
			}, '');

			return `<div><br/><div>Attachments:</div><ul>${attachmentItems}</ul></div>`;
		}

		private contentToHtml(content: string): string {
			return content.split('\n').map(sentence => `<p>${sentence}</p>`).join('\n');
		}

		public submitTicket(ticketData: ans.ContactFormSubmitData, isGuest: boolean, linkedKBItemId: string): ng.IPromise<ans.Ticket> {
			let submitUrl = `${this.ticketsUrl}`;

			const useInvisible = true;

			let data: any = {
				useInvisible,
				useCaptchaV3: ticketData.useCaptchaV3,
				private: true,
				locale: this.appConfig.lang,
				subject: ticketData.title,
				content: ticketData.description ? this.contentToHtml(ticketData.description) : '',
				customFields: ticketData.customFieldsValuesMap,
				labelIds: this.sdk.getLabelsToAddOnNextSubmit(),
				userFirstName: ticketData.firstName,
				userLastName: ticketData.lastName,
				sourceType: this.analyticsService.sourceType,
				sourceId: this.analyticsService.sourceId
			};

			data.attachments = ticketData.attachments;

			if (ticketData.captchaToken) {
				data.captcha = ticketData.captchaToken;
			}

			if (linkedKBItemId) {
				data.relatedArticleIds = [linkedKBItemId];
			}

			if (isGuest) {
				data.userEmail = ticketData.email;
				submitUrl = `${this.appConfig.apiPath}/internal/tickets/guest`;
			}

			return this.$http.post<ans.Ticket>(submitUrl, data)
			.then((response) => {
				this.sdk.resetLabelsToAddOnNextSubmit();
				this.sdk.triggerEvent(SdkEventType.ticketAdded, { ticket: response.data });
				return response.data;
			});
		}

		public getSuggestedAnswers(text): ng.IPromise<ans.SearchResult<ans.Article>> {
			const contentFilters = this.sdk.getContentFilters();
			return this.$http.post(this.articlesSearchApiPath, {
				text: text,
				page: 1,
				pageSize: 3,
				locale: this.appConfig.lang,
				searchable: true,
				...contentFilters
			})
			.then((response) => response.data)
			.then((data: ans.SearchResult<ans.Article>) => {
				var suggestions = data.items;
				this.sdk.triggerEvent(SdkEventType.articleSuggest, { query: text, suggestions: suggestions });
				return data;
			});
		}

		public getRecommendedSolutions(categoryId: string): ng.IPromise<ans.SearchResult<ans.Article>> {
			const contentFilters = this.sdk.getContentFilters();
			return this.$http.post<ans.SearchResult<ans.Article>>(this.articlesSearchApiPath, {
				page: 1,
				pageSize: 5,
				locale: this.appConfig.lang,
				searchable: true,
				sortType: ans.ArticleSortType.POSITION,
				listed: true,
				...contentFilters,
				categoryId: categoryId
			})
			.then((response) => response.data);
		}

		getUserOpenTickets(userId: string): ng.IPromise<ans.SearchResult<ans.Ticket>> {
			return this.$http.get<ans.SearchResult<ans.Ticket>>(`${this.appConfig.apiPath}/tickets/my/open`, {
				params: {
					locale: this.appConfig.lang,
					page: 1,
					pageSize: 100
				}
			})
			.then((response) => response.data);
		}
	}

	export default SubmitTicketService;
