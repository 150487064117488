/// <reference path="../../references.ts" />

import * as ans from '@wix/answers-api';
import Sdk from '../sdk/sdk.srv';
import { filterCategories } from '../content-filters/index';

interface CategoryMap {
	[id: string]: ans.Category;
}

export default class CategoryService {

	categoriesUrl: string;
	categoryTree: ans.Category[];
	categoryMap: CategoryMap = {};
	root: string = '00000000-0000-0000-0000-000000000000';
	treePromise: ng.IPromise<ans.Category[]>;

	/*@ngInject*/
	constructor(private $http: ng.IHttpService, private $q: ng.IQService, private appConfig: ans.AppConfig, private sdk: Sdk) {
		this.$http = $http;
		this.categoriesUrl = appConfig.apiPath + `/helpcenter/categories`;
	}

	public init() {
		if (!this.categoryTree) {
			if (!this.treePromise) {
				this.treePromise = this.getTree(this.appConfig.lang);
			}
			return this.treePromise;
		} else {
			var dfd = this.$q.defer();
			dfd.resolve();
			return dfd.promise;
		}
	}

	private filterUnlistedCategories(categories: ans.Category[]) {
		return categories
		.filter(cat => cat.listed !== false)
		.map(c => {
			c.children = this.filterUnlistedCategories(c.children || []);
			return c;
		});
	}


	public getTree(lang: string): ng.IPromise<ans.Category[]> {
		var config = {
			url: this.categoriesUrl,
			method: 'GET',
			cache: true,
			params: {
				locale: lang
			}
		};
		return this.treePromise = this.$http(config).then((res: any) => {
			if (res && res.data) {
				// filter not listed
				const categories = this.filterUnlistedCategories(res.data);
				const filtered = filterCategories(categories, this.sdk.getContentFilters());
				this.setCategoryTree(filtered);
				return this.categoryTree;
			}
		});
	}

	public getCachedCategoryById(id: string) {
		return this.categoryMap[id] || null;
	}

	public getByUri(uri: string): ng.IPromise<ans.Category> {
		uri = uri.replace(/\/$/, '');
		return this.$http.get(`${this.categoriesUrl}/uri${uri}`, {
			cache: true,
			params: {
				locale: this.appConfig.lang
			}
		}).then((res) => res.data as ans.Category);
	}

	public setCategoryTree(categoryTree: ans.Category[]) {
		this.categoryTree = categoryTree;
		this.setLevelCategoryMap(categoryTree);
	}

	calculateCategoryIds(id: string): string[] {
		let category = this.categoryMap[id];
		if (category) {
			category.children = category.children || [];
			return category.children.map(category => category.id).concat(category.id);
		}
	}

	private setLevelCategoryMap(categoryTree: ans.Category[]) {
		angular.forEach(categoryTree, (category) => {
			this.categoryMap[category.id] = category;
			if (category.children) {
				this.setLevelCategoryMap(category.children);
			}
		});
	}
}
