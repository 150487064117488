/// <reference path="../references.ts" />
import Tickets from './ticket-service/tickets.srv';
import ticketPageDirectiveFactory from './ticket-page/ticket-page.drv';
import authorAndDateDirectiveFactory from './author-and-date/author-and-date.drv';
import FollowService from '../common/services/follow/follow.srv';
import ticketsRouting from './routing/tickets-routing';
import ticketsPageContainerDirectiveFactory from './tickets-page/tickets-page-container.drv';
import ticketResultsDirectiveFactory from './tickets-page/ticket-results.drv';
import stickyInnerHeaderDirectiveFactory from './../common/sticky-inner-header.drv';

angular.module('tickets', ['ui.router', 'ngSanitize', 'core', 'common', 'angularMoment', 'auth', 'article', 'seo', 'angular-redactor', 'categories'])
	.config(ticketsRouting)
	.directive('ticketPage', ticketPageDirectiveFactory)
	.directive('ticketResults', ticketResultsDirectiveFactory)
	.directive('ticketsPageContainer', ticketsPageContainerDirectiveFactory)
	.directive('authorAndDate', authorAndDateDirectiveFactory)
	.directive('stickyInnerHeader', stickyInnerHeaderDirectiveFactory)
	.service('followService', FollowService)
	.service('tickets', Tickets);
