import { ResolvedCustomField, CustomFieldNameToValueMap, CustomFieldType, ContactFormInitialFieldData } from '@wix/answers-api';

export const tryParseBool = (raw: string) => {
	return raw === 'true' ? true : raw === 'false' ? false : null;
};

export const tryParseNumber = (raw: string) => {
	const maybeInt = parseInt(raw, 10);
	return !isNaN(maybeInt) ? maybeInt : null;
};

export const parseCustomFieldValue = (rawValue: string, fieldType: CustomFieldType, fieldName: string, customFields: CustomFieldNameToValueMap): CustomFieldNameToValueMap => {
	let parsedValue = null;

	switch (fieldType) {
		case CustomFieldType.BOOLEAN:
			const maybeBool = tryParseBool(rawValue);
			return maybeBool !== null ? {...customFields, [fieldName]: maybeBool} : customFields;
		case CustomFieldType.NUMBER:
			const maybeNumber = tryParseNumber(rawValue);
			return maybeNumber !== null ? {...customFields, [fieldName]: maybeNumber} : customFields;
		case CustomFieldType.MULTILINE_TEXT:
		case CustomFieldType.TEXT:
		case CustomFieldType.SINGLE_SELECT:
			return {...customFields, [fieldName]: rawValue};
		default:
			return customFields;
	}
};

export const getParameterByName = (name: any) => {
	var url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
	results = regex.exec(url);
	if (!results) { return null; }
	if (!results[2]) { return ''; }
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getUrlContactFormParams = (customFields: ResolvedCustomField[]): ContactFormInitialFieldData => {
	const customFieldsNameToValueMap: CustomFieldNameToValueMap = customFields.reduce((res, currentField) => {
		const rawValue = getParameterByName(currentField.name);

		return rawValue ? parseCustomFieldValue(rawValue, currentField.type, currentField.name, res) : res;
	}, {});

	const fullname = getParameterByName('fullname') || '';
	const email = getParameterByName('email') || '';
	const title = getParameterByName('title') || '';
	const description = getParameterByName('description') || '';

	return { fullname, email, title, description, customFields: customFieldsNameToValueMap };
};
