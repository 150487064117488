/// <reference path="../../references.ts" />

'use strict';

import * as ans from '@wix/answers-api';
import ArticleService from '../../article/article-service/article.srv';
import CategoryService from './../../common/category/category.srv';
import UgcSortType from './../../ugc-sort-type.enum';
import Sdk from './../../common/sdk/sdk.srv';
import SdkEventType from './../../common/sdk/sdk-events.enum';
import { isFeatureEnabled } from '@wix/answers-experiments';

export default class Tickets {

	ticketsUrl: string;

	/*@ngInject*/
	constructor(private $http: ng.IHttpService,
		private $httpPostForm,
		private appConfig: ans.AppConfig,
		private sdk: Sdk,
		private $q: ng.IQService,
		private categoryService: CategoryService) {
			this.ticketsUrl = appConfig.apiPath + '/tickets';
		}

		getTicket(itemId: string): ng.IPromise<ans.Ticket> {
			return this.$http.get(`${this.ticketsUrl}/${itemId}`)
			.then((response: any) => {
				return response.data;
			});
		}

		searchByUser(userId: string, page: number = 1, pageSize: number = 10): ng.IPromise<ans.SearchResult<ans.Ticket>> {
			return this.$http.post(`${this.ticketsUrl}/my`, {
				locale: this.appConfig.lang,
				page: page,
				pageSize: pageSize,
				channelFilters: [
					{ids: [], channel: ans.Channel.API},
					{ids: [], channel: ans.Channel.EMAIL},
					{ids: [], channel: ans.Channel.OUTBOUND},
					{ids: [], channel: ans.Channel.PHONE_CALLBACK},
					{ids: [], channel: ans.Channel.PHONE_INBOUND},
					{ids: [], channel: ans.Channel.PHONE_OUTBOUND},
					{ids: [], channel: ans.Channel.WEB},
					{ids: [], channel: ans.Channel.WIDGET}
				]
			})
			.then((response) => response.data as any);
		}

		getTicketReplies(parentItemId: string, page: number, pageSize: number): ng.IPromise<ans.SearchResult<ans.Reply>> {
			const getRepliesUrl = `${this.ticketsUrl}/${parentItemId}/${isFeatureEnabled('publicChatReplies') ? 'userTimeline' : 'replies'}`;
			return this.$http.get(getRepliesUrl, {params: {sortType: 10, pageSize: 100}})
			.then((response) => response.data as any);
		}

		addReply(parentItemId: string, contentText: string, makeTicketPrivate: boolean = false): ng.IPromise<ans.Reply> {
			this.sdk.triggerEvent(SdkEventType.ticketReplied, {parentTicketId: parentItemId, private: makeTicketPrivate});
			let data = {
				ticketId: parentItemId,
				content: contentText,
				makeTicketPrivate: makeTicketPrivate
			};

			return this.$http.post(`${this.ticketsUrl}/${parentItemId}/replies`, data)
			.then((response: any) => response.data);
		}

		getRelatedItems(ticket: ans.Ticket): ng.IPromise<ans.Article[]> {
			return this.$http.get(`${this.ticketsUrl}/${ticket.id}/relations`, {params: {itemsCount: 5}})
			.then((response: any) => {
				return response.data;
			});
		}

		getTickets(type: string, page: number = 1, searchTerm?: string, pageSize?: number): ng.IPromise<ans.SearchResult<ans.Ticket>> {
			const defaultPageSize = 12;
			switch (type) {
				case 'featured':
				return this.$http.get(`${this.ticketsUrl}/top`, {
					params: {
						locale: this.appConfig.lang,
						page: page,
						pageSize: pageSize || defaultPageSize
					}
				})
				.then((response) => response.data as any);
				case 'recent':
				const data = {
					locale: this.appConfig.lang,
					page: page,
					pageSize: pageSize || defaultPageSize,
					sortType: ans.TicketSortType.CREATION_DATE_DESC,
					spellcheck: true,
					hasAgentReply: true,
					text: searchTerm
				};
				return this.$http.post(`${this.ticketsUrl}/search`, data)
				.then((response) => response.data as any);
				default:
				throw 'bad type passed';
			}
		}
	}
