import * as ans from '@wix/answers-api';
import Tickets from './../ticket-service/tickets.srv';

class TicketResultsController {

	private visiblePagesCount = 5;
	private pageSize = 12;
	page: string; //temp string
	pageNum: number;
	results: ans.SearchResult<ans.Ticket>;
	visiblePages: number[] = [];
	totalPages: number;
	searchTerm: string;

	/*@ngInject*/
	constructor(tickets: Tickets) {
		this.pageNum = parseInt(this.page, 10);
		this.setPaginationData(this.results);
	}

	setPaginationData(results) {
		this.results = results;
		let pagesNum = this.results.numPages;
		let additionalPagesToShow = Math.floor(this.visiblePagesCount - 1) / 2;
		let lowerBound = this.pageNum - additionalPagesToShow; //1 - 2 = -1
		let upperBound = this.pageNum + additionalPagesToShow; // 1 + 2 = 3
		let rightPadding = lowerBound < 1 ? Math.abs(lowerBound) + 1 : 0; // 2
		let leftPadding = upperBound > pagesNum ? upperBound - pagesNum : 0; //0
		for (let i = lowerBound - leftPadding; i <= upperBound + rightPadding; i++) {
			if (i > 0 && i <= pagesNum) {
				this.visiblePages.push(i);
			}
		}
		this.totalPages = pagesNum;
	}
}

/*@ngInject*/
function ticketResultsDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		template: require('./ticket-results.tpl.ts'),
		scope: {
			page: '@',
			searchTerm: '@',
			results: '='
		},
		restrict: 'E',
		controllerAs: 'ctrl',
		bindToController: true,
		controller: TicketResultsController
	};
}

export default ticketResultsDirectiveFactory;
