
/* tslint:disable */
const templateData = `
<nav click-outside="ctrl.showCategoryDropdownMenu = false" ng-class="{'open-cat-dropdown-menu': ctrl.showCategoryDropdownMenu}">
	<section class="categories-nav-container" sticky-inner-header >
		<section id="categories" ng-show="ctrl.showCollapsibleCategoryMenu || ctrl.showParent">

			<section class="category-dropdown-menu-container" ng-if="ctrl.showCategoryDropdownMenuHelper">
				<a class="category-dropdown-menu-inner" ng-click="ctrl.openCatDropdownMenu()">
					<svg-icon name="cat-dd-menu"></svg-icon>
					{{::'public.category.nav.all-topics' | translate}}
				</a>
			</section>

			<div class="current-category-title" ng-if="!ctrl.showCollapsibleCategoryMenu">
				<div class="current-category-title-container">
					<a id="back-home" class="back" ng-click="ctrl.showParent = true" inner-link="/">
						<svg-icon name="back-home"></svg-icon>
						<span class="back-home">{{::'public.header.home.link' | translate}}</span>
					</a>
					<span id="choose-topic" class="nav-title">
						<span>{{::'public.category.nav.all-topics' | translate}} <span class="category-count">({{ctrl.tree.length}})</span></span>
					</span>
				</div>
			</div>

			<div class="close-cat-dropdown-menu" ng-if="ctrl.showCategoryDropdownMenuHelper" ng-click="ctrl.closeCatDropdownMenu()">
				<svg-icon name="x"></svg-icon>
			</div>


			<div class="categories-nav">

				<div class="normal-nav-container" ng-if="!ctrl.showCollapsibleCategoryMenu" ng-scrollbars ng-scrollbars-config="config">
					<a ng-repeat="category in ctrl.tree"
					ng-click="ctrl.navigate(category)"
					sdk-event-trigger="categoryNavigated"
					sdk-event-data="{category: category, parentCategory: null}"
					class="category"
					ng-class="{active: category.id === ctrl.category.id || category.id === ctrl.category.parentId, 'active-child': category.id === ctrl.category.parentId}">
						<span>{{::category.name}}</span>
					</a>
				</div>
				<div ng-repeat="category in ctrl.tree"
				class="category"
				ng-if="ctrl.showCollapsibleCategoryMenu"
				ng-class="{active: (category.id === ctrl.category.id || category.id === ctrl.category.parentId || category.id === ctrl.parentCategory.id), 'active-child': category.id === ctrl.category.parentId, 'no-sub': !category.children.length}">
				<div class="collapsible-nav-container">
					<div class="main-category"
					ng-click="ctrl.navigate(category)"
					sdk-event-trigger="categoryNavigated"
					sdk-event-data="{category: category, parentCategory: null}">
						<span class="category-icon" ng-hide="category.data.iconSrc && !category.data.iconKey">
							<i class="material-icons" ng-show="category.data.iconKey">{{::category.data.iconKey}}</i>
							<i class="material-icons" ng-hide="category.data">style</i>
						</span>
						<span class="category-icon-img" ng-show="category.data.iconSrc && !category.data.iconKey">
							<span class="category-icon-img-helper"></span>
							<img ng-src="{{::category.data.iconSrc}}">
						</span>
						<span class="category-name">{{::category.name}}</span>
					</div>

					<div class="subcategories-collapsible" ng-show="category.id === ctrl.parentCategory.id">
						<a ng-repeat="subcategory in category.children"
							ng-click="ctrl.navigate(subcategory)"
							sdk-event-trigger="categoryNavigated"
							sdk-event-data="{category: subcategory, parentCategory: ctrl.parentCategory}"
							class="subcategory"
							ng-class="{active: subcategory.id === ctrl.category.id}">
							<span>{{::subcategory.name}}</span>
						</a>
					</div>
				</div>
			</div>

		</div>
	</section>

	<section id="sub-categories" ng-show="!ctrl.showParent && ctrl.showBounceCategoryMenu">
		<div class="current-category-title">
			<div class="main-category-icon">
				<div class="category-icon" ng-hide="ctrl.parentCategory.data.iconSrc && !ctrl.parentCategory.data.iconKey">
					<i class="material-icons" ng-show="ctrl.parentCategory.data.iconKey">{{::ctrl.parentCategory.data.iconKey}}</i>
					<i class="material-icons" ng-hide="ctrl.parentCategory.data">style</i>
				</div>
				<div ng-show="ctrl.parentCategory.data.iconSrc && !ctrl.parentCategory.data.iconKey" class="category-icon-img">
					<span class="category-icon-img-helper"></span>
					<img ng-src="{{ctrl.parentCategory.data.iconSrc}}"/>
				</div>
			</div>
			<div class="sub-category-title-container">
				<a id="all-topics" class="all-topics back" ng-click="ctrl.allTopics()">
					<svg-icon name="back"></svg-icon>
					<span class="back-all-topics">{{::'public.category.nav.all-topics' | translate}}</span>
				</a>
				<span id="parent-name" class="nav-title">
					<span>{{ctrl.parentCategory.name}}</span>
				</span>
			</div>
		</div>
		<div class="categories-nav" ng-scrollbars ng-scrollbars-config="config">
			<a ng-repeat="subcategory in ctrl.parentCategory.children"
				sdk-event-trigger="categoryNavigated"
				sdk-event-data="{category: subcategory, parentCategory: ctrl.parentCategory}"
				ng-click="ctrl.navigate(subcategory)"
				class="category subcategory"
				ng-class="{active: subcategory.id === ctrl.category.id}">
				<span>{{::subcategory.name}}</span>
			</a>
		</div>
		</section>
	</section>
</nav>

`;
export = templateData;
