/**
 * Created by Elad_Swissa on 7/8/15.
 */
/// <reference path="../references.ts" />
import ArticleService from './article-service/article.srv';
import articleRoutingConfig from './article-routing/article-routing';
import articlePageDirectiveFactory from './article-page/article-page.drv';
import itemRatingDirectiveFactory from './item-rating/item-rating.drv';
import FollowService from '../common/services/follow/follow.srv';
import ItemRatingService from './item-rating/item-rating.srv';
import stickyInnerHeaderDirectiveFactory from './../common/sticky-inner-header.drv';
import articlePageAnchoringDirectiveFactory from './article-page/article-page-anchoring.drv';
import userEngagementPingerDirectiveFactory from './article-page/user-engagement-pinger.drv';
import articleInfoDirectiveFactory from './article-info/article-info.drv';
import articleViewerDirectiveFactory from './article-page/article-viewer.drv';
import legacyContactFormDirectiveFactory from '../submit/legacy-contact-form/legacy-contact-form.drv';
import contactFormDirectiveFactory from '../submit/contact-form/contact-form.drv';

angular.module('article', ['ui.router', 'ngSanitize', 'categories', 'core', 'common', 'auth', 'angular-redactor', 'seo', 'submit-ticket'])
	.config(articleRoutingConfig)
		.directive('articlePage', articlePageDirectiveFactory)
		.directive('articleViewer', articleViewerDirectiveFactory)
		.directive('itemRating', itemRatingDirectiveFactory)
		.directive('stickyInnerHeader', stickyInnerHeaderDirectiveFactory)
		.directive('anchorNavigation', articlePageAnchoringDirectiveFactory)
		.directive('userEngagementPinger', userEngagementPingerDirectiveFactory)
		.directive('articleInfo', articleInfoDirectiveFactory)
		.service('followService', FollowService)
		.service('itemRatingService', ItemRatingService)
		.service('articleService', ArticleService);
