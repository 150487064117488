/**
 * Created by Elad_Swissa on 7/12/15.
 */

'use strict';

export default function htmlTextDirectiveFactory(): ng.IDirective {
		return <ng.IDirective>{
				restrict: 'A',
				bindToController: true,
				link: function (scope, elem, attr: any) {
						var htmlText = attr.htmlText.replace(/<[^>]*?>/g, '');
						elem.html(htmlText);
				}
		};
}
