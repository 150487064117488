/// <reference path="../references.ts" />

'use strict';
import Prerender from './../common/prerender/prerender.srv';

/*@ngInject*/
function legacyRedirectsRoutingConfig($stateProvider: ng.ui.IStateProvider) {

		$stateProvider
		//Redirect contact us to home page
		.state('app.submit-redirect', {
			url: '/submit-a-ticket?title&content&categoryId&private',
			onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
				if (prerender.isPrerenderMode()) {
					var correctUrl = appConfig.helpCenterUrls[appConfig.lang];
					prerender.setPermanentRedirect(correctUrl + '/contact');
				} else {
					$state.go('app.submit', $stateParams);
				}
			}
		})
		.state('app.forum-redirect', {
			url: '/forum',
			onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
				if (prerender.isPrerenderMode()) {
					var correctUrl = appConfig.helpCenterUrls[appConfig.lang];
					prerender.setPermanentRedirect(correctUrl + '/tickets');
				} else {
					$state.go('app.tickets.results');
				}
			}
		})
		.state('app.forum-redirect-2', {
			url: '/forum/:cat',
			onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
				if (prerender.isPrerenderMode()) {
					var correctUrl = appConfig.helpCenterUrls[appConfig.lang];
					prerender.setPermanentRedirect(correctUrl + '/tickets');
				} else {
					$state.go('app.tickets.results');
				}
			}
		})
		.state('app.top-questions-redirect', {
			url: '/top-questions',
			onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
				if (prerender.isPrerenderMode()) {
					var correctUrl = appConfig.helpCenterUrls[appConfig.lang];
					prerender.setPermanentRedirect(correctUrl);
				} else {
					$state.go('app.home');
				}
			}
		});
	}

	export default legacyRedirectsRoutingConfig;
