import * as ans from '@wix/answers-api';

class AuthorAndDateDirectiveController {
	item: any;
	user: ans.User;
	agoFormat: boolean;

	/*@ngInject*/
	constructor() {
		this.user = (this.item.createdByAgent ? this.item.createdByAgent : this.item.user);
	}
}

export default function authorAndDateDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		transclude: true,
		scope: {
			item: '=',
			prefixKey: '@',
			agoFormat: '='
		},
		template: require('./author-and-date.drv.tpl.ts'),
		controller: AuthorAndDateDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
