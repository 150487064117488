import CategoryPageService from './category-page.srv';
import categoryPageRouting from './routing/category-page-routing';
import categoryPageDirectiveFactory from './components/category-page.drv';
import categoryPageNavDirectiveFactory from './components/category-page-nav.drv';

angular.module('categoryPage', ['common', 'core', 'categories', 'ui.router', 'article'])
	.config(categoryPageRouting)
	.directive('categoryPage', categoryPageDirectiveFactory)
	.directive('categoryPageNav', categoryPageNavDirectiveFactory)
	.service('categoryPageService', CategoryPageService);
