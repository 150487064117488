
/* tslint:disable */
const templateData = `
<main ng-class="{'no-related-articles': page.relatedItems.length === 0}">
	<a class="back-home mobile-only" ng-click="ctrl.showParent = true" inner-link="/">
		<svg-icon name="back"></svg-icon>
		{{::'public.header.home.link' | translate}}
	</a>
	<section class="sitemap" sticky-inner-header>
		<a inner-link="/">
			<svg-icon name="home"></svg-icon>
		</a>
		<a class="sm-category tickets-sitemap-link" ui-sref="app.tickets.results">{{'public.discussions' | translate}}</a>
	</section>

	<section class="ticket">
		<section class="old-ticket-notice" ng-if="page.isTicketOverYearOld">
			<svg-icon class="notice-icon" name='clock'></svg-icon>
			<p class="notice-content" ng-bind-html="'public.ticket.ticket-year-old-notice' | translate: {helpCenterUrl: page.helpCenterUrl}"></p>
		</section>
		<section class="ticket-inner">
			<section class="author-and-date">
				<author-and-date item="page.ticket"></author-and-date>
				<span class="ticket-number" ng-if="page.isTicketAuthor">{{::'public.ticket.id' | translate}} {{page.ticket.ticketNumber}}</span>
			</section>
			<header class="ticket-header">
				<h1>{{::page.ticket.subject}}</h1>
			</header>
			<div class="content" ng-bind-html="::page.ticket.content"></div>
			<aside id="ticket-details" style="display:none;">
				<h4>{{'public.ticket.details.header' | translate}}</h4>
				<p ng-if="::page.ticket.category">{{::'public.ticket.details.category' | translate}} <span class="category">{{::page.ticket.category.name}}</span></p>
				<p>{{::'public.ticket.details.ticket-number' | translate}} <span class="ticket-number">{{::page.ticket.ticketNumber}}</span></p>
				<p ng-if="::page.replies.length">{{::'public.ticket.details.last-reply' | translate}} <span class="last-reply">{{::page.replies[page.replies.length - 1].creationDate | amTimeAgo}}</span></p>
				<p>{{::'public.ticket.details.status' | translate}} <span class="ticket-status">{{::'common.ticketStatus.' + page.ticket.ticketStatus.value | translate}}</span></p>
				<!-- <p class="is-private" ng-if="page.ticket.private">{{::'public.ticket.details.private' | translate}}</p> -->
			</aside>
		</section>
	</section>

	<section class="user-replies">
		<header class="replies-title">
			<h4 ng-bind-html="'public.ticket.replies.header' | translate: {repliesCount: page.ticket.repliesCount}"></h4>
		</header>
		<div class="replies-container">
			<ul class="replies" ng-show="page.replies.length">
				<li class="reply" ng-show="reply.content" ng-repeat="reply in page.replies" ng-class="{'agent-replay': page.isAgent(reply)}">
					<header class="author-and-date">
						<author-and-date item="reply" pprefix-key="public.reply.author-prefix">
							<div class="agent-response" ng-if="page.isAgent(reply)"><svg-icon name="v" class="middle"></svg-icon>{{::'public.ticket.replies.agent.response' | translate}}</div>
						</author-and-date>
					</header>
					<div class="reply-content" ng-bind-html="::reply.content"></div>
				</li>
			</ul>

			<!-- <div ng-hide="page.replies.length" class="no-replies">
				<span><b>{{::page.brandingConfig.name}}</b> {{::'public.ticket.no-replies' | translate}}</span>
			</div> -->

			<form id="reply-form" ng-if="page.isTicketAuthor" ng-submit="page.addReply(page.newReplyContent, page.makeTicketPrivate)" ng-class="{'no-replay': !page.replies.length}">
				<h5>{{'public.ticket.reply.header' | translate}}</h5>
				<user-avatar user="::page.currentUser"></user-avatar>
				<span class="author-display-name">{{page.currentUser | userDisplayName}}</span>
				<div class="text-editor" ng-class="{'open-text-editor': page.focusReplayTextarea}">
					<textarea redactor="page.textEditorOptions" ng-model="page.newReplyContent" placeholder="{{'public.ticket.reply.placeholder' | translate}}"></textarea>
					<div class="text-editor-toolbar"></div>

					<button id="add-reply" class="btn">{{'public.ticket.submit.btn' | translate}}</button>
				</div>
			</form>

			<div class="cannot-reply" ng-class="{'no-replay': !page.replies.length}" ng-if="!page.currentUser" is-ticketing-enabled>
				<div class="cannot-reply-inner">
					<span class="cannot-reply-msg">{{'public.ticket.author.only' | translate}}</span>
					<a ng-if="!page.currentUser" ng-click="page.signin()">{{'public.ticket.login' | translate}}</a>
				</div>
			</div>
		</div>
	</section>

	<section class="ticket-actions">
		</div>

		<!--<div class="submit-row">
			<span class="submit-lable">{{'public.article.help' | translate}}</span>
			<a ui-sref="app.submit" sdk-event-trigger="submitTicketClicked" class="submit-btn">{{'public.ticket.submit.new' | translate}}</a>
		</div>-->
	</section>

	<aside id="related-items" ng-if="page.relatedItems.length">
		<h4 class="article-items-title">{{'public.article.related-items' | translate}}</h4>
		<ul class="article-list">
			<li class="related-item"
			sdk-event-trigger="articleResultClick"
			sdk-event-data="{article: searchResult, order: $index}"
			 ng-repeat="relatedArticleItem in page.relatedItems">
				<a inner-link="{{relatedArticleItem.uri}}">
					<svg-icon name="article" class="middle"></svg-icon>
					<h4 class="article-type">{{::'common.article-type.' + relatedArticleItem.type | translate}}</h4>
					<div class="article-title"><div class="article-title-inner">{{::relatedArticleItem.title | htmlToText}}</div></div>
				</a>
			</li>
		</ul>
	</aside>
</main>

`;
export = templateData;
