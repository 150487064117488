
/* tslint:disable */
const templateData = `
<user-toolbar ng-if="header.showUserToolbar" locale="header.currentLang"></user-toolbar>
<iframe id="agent-check-iframe" ng-src="{{::header.trustedAgentCheckUrl}}" ng-style="{width: '0px', height: '0px', position: 'absolute', border: '0'}"/>

<header id="main-header" ng-if="!header.customHeader" ng-init="header.triggerAppReady()" ng-class="{'hf-light': header.isLightTheme, 'hf-dark': header.isDarkTheme, 'toolbar-expanded': header.$rootScope.isToolbarExpanded, 'with-user-toolbar': header.showUserToolbar}">

	<div id="languages-selection" ng-if="header.supportedLangs.length > 1"
		ng-click="header.showAllLang = !header.showAllLang;"
		ng-mouseleave="header.showAllLang = false;">

		<div class="lang-dropdown">
			<span id="current-lang" data-lang="{{header.currentLang}}">{{::'common.lang.original.' + header.currentLang | translate}}</span>
		</div>

		<div class="lang-globus" ng-class="{'globus-active': header.showAllLang}">
			<a class="globus-icon">
				<svg-icon name="globus"></svg-icon>
			</a>
		</div>

		<div class="language-select-container" ng-show="header.showAllLang">
			<ul>
				<li class="language-select"
				ng-class="{selected: lang === header.currentLang}"
				ng-repeat="lang in header.supportedLangs"
				data-lang="{{lang}}">
					<a ng-href="{{header.localeUrlMap[lang] || header.helpCenterUrls[lang]}}" data-lang="{{lang}}">{{::'common.lang.original.' + lang | translate}}</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="logo-container">
		<div class="logo-container-inner">
			<a href="{{header.logoTargetUrl ? header.logoTargetUrl : '/'}}">
				<img id="logo" ng-src="{{header.tenantLogo}}">
			<a>
		</div>
	</div>
	<div id="header-user" ng-if="header.enableHeaderLogin && header.isUserLoggedIn"
		ng-click="header.showUserMenu = !header.showUserMenu;"
		ng-mouseleave="header.showUserMenu = false;">
		<a id="user-name">{{::header.user | userDisplayName}}</a>
		<div id="user-menu" ng-show="header.showUserMenu">
			<ul>
				<li>
					<a ui-sref="app.user({id: header.user.id})">{{::'common.my.profile' | translate}} <span class="arrow-right"></span> </a>
				</li>
				<li><a id="sign-out" ng-click="header.logout()">{{::'common.sign-out' | translate}}</a></li>
			</ul>
		</div>
	</div>
	<div ng-if="header.enableHeaderLogin && !header.isUserLoggedIn">
		<a ng-if="!header.isIe" href="#" id="sign-up" ng-click="header.signup()">{{::'common.sign-up' | translate}}</a>
		<a href="#" id="sign-in" ng-click="header.signin()">{{::'common.sign-in' | translate}}</a>
	</div>
	<a inner-link="/" id="home-link"><span preview-changeable-text="public.header.home.link">{{::'public.header.home.link' | translate}}<span></a>
</header>

<header id="custom-header" ng-if="header.customHeader" ng-bind-html="header.trustedCustomHeader" ng-init="header.triggerAppReady()" ng-class="{'toolbar-expanded': main.$rootScope.isToolbarExpanded}"></header>

`;
export = templateData;
