/* tslint:disable */
const templateData = `
	<form id="submit-form" name="submit" ng-submit="ctrl.handleSubmitClick(submit.$valid);" novalidate ng-class="{'invalid-content': !submit.content.$valid}">
		<!--<h2>{{::'public.contact.ticket.title' | translate}}</h2>-->
		<div class="input-title-row">
			<div class="submit-label">{{::'public.submit.title.label' | translate}}</div>
			<input type="text" name="title" required="" html-min-length="10" novalidate
				class="ticket-title"
				ng-class="{error: submit.$submitted && submit.title.$invalid}"
				ng-model="ctrl.newTicketTitle"
				ng-model-options="{ debounce: 700 }"
				ng-change="ctrl.getSuggestAnswers(ctrl.newTicketTitle, '{{ctrl.newTicketTitle}}');"
				placeholder="{{::'public.ticket.submit.title.placeholder' | translate}}">
			<div class="submit-ticket-error" ng-show="submit.$submitted && submit.title.$invalid">{{::'public.submit.title.error' | translate}}</div>
		</div>

		<div id="suggested-items" ng-show="ctrl.newTicketTitle && ctrl.suggestAnswers.length">
			<div class="suggested-answers" ng-show="!ctrl.waitForSuggestAnswers">
				<div class="submit-label">{{'public.submit.suggested' | translate}}</div>
				<ul>
					<li class="related-item" ng-repeat="suggestAnswer in ctrl.suggestAnswers">
						<a class="related-article-item"
							inner-link="{{::suggestAnswer.uri}}"
							sdk-event-data="{query: ctrl.newTicketTitle, suggestion: suggestAnswer}"
							sdk-event-trigger="articleSuggestClicked"><svg-icon name="article" class="middle"></svg-icon>{{suggestAnswer.title}}</a>
					</li>
				</ul>
			</div>

			<div ng-show="ctrl.waitForSuggestAnswers">
				<div class="sk-folding-cube">
					<div class="sk-cube1 sk-cube"></div>
					<div class="sk-cube2 sk-cube"></div>
					<div class="sk-cube4 sk-cube"></div>
					<div class="sk-cube3 sk-cube"></div>
				</div>
			</div>
		</div>

		<div class="input-content-row">
			<div ng-class="{error: submit.$submitted && submit.content.$invalid}">
				<textarea redactor="ctrl.textEditorOptions"
					name="content" required=""
					html-min-length="10"
					ng-model="ctrl.newTicketContent"
					placeholder="{{::'public.ticket.submit.content.placeholder' | translate}}">
				</textarea>
				<div class="text-editor-toolbar"></div>
			</div>
			<div class="submit-ticket-error" ng-show="submit.$submitted && submit.content.$invalid">{{::'public.submit.content.error' | translate}}</span></div>
		</div>

		<div class="input-user-row" ng-if="ctrl.showFullName">
			<div class="submit-label">{{::'public.submit.user.label' | translate}}</div>
			<div class="input-user-input-container">
				<div class="input-user-input">
					<input type="text" name="fullname" required=""
						ng-class="{error: submit.$submitted && submit.fullname.$invalid}"
						ng-model="ctrl.userFullName"
						placeholder="{{::'public.submit.user.full-name.placeholder' | translate}}">
					<div class="submit-ticket-error" ng-show="submit.$submitted && submit.fullname.$invalid">{{::'public.submit.user.full-name.error' | translate}}</div>
				</div>

				<div class="input-user-input" ng-if="ctrl.isGuestMode">
					<input type="email" name="email" required=""
						ng-class="{error: submit.$submitted && submit.email.$invalid}"
						ng-model="ctrl.userEmail"
						placeholder="{{::'public.submit.user.email.placeholder' | translate}}">
					<div class="submit-ticket-error" ng-show="submit.$submitted && submit.email.$invalid">
						<span ng-show="submit.email.$error.required">{{::'public.submit.user.email.error.required' | translate}}</span>
						<span ng-show="submit.email.$error.email">{{::'public.submit.user.email.error.valid' | translate}}</span>
					</div>
				</div>
			</div>
		</div>

		<div ng-if="ctrl.useCaptcha || ctrl.isGuestMode" class="captcha-row">
			<div class="submit-label">{{::'public.submit.captcha.label' | translate}}</div>
			<div class="recaptcha-container" vc-recaptcha key="ctrl.reCaptchaKey" ng-model="ctrl.reCaptchaValue" ng-class="{'recaptcha-error': submit.$error.recaptcha}"></div>
			<div class="submit-ticket-error" ng-show="submit.$submitted && submit.$error.recaptcha">{{::'public.submit.recaptcha-error' | translate}}</div>
		</div>

		<button id="submit-ticket"
		sdk-event-trigger="ticketSubmitClicked" class="btn">{{::'public.ticket.submit.btn' | translate}}</button>

	</form>
`;

export = templateData;
