
/* tslint:disable */
const templateData = `
<main>
	<section class="user-profile">
		<header class="user-header">
			<div class="user-header-top">
				<a class="back-home" inner-link="/">
					<svg-icon name="back-home"></svg-icon>
					<span class="back-home-text">{{::'public.header.home.link' | translate}}</span>
				</a>
				<h1>{{'public.user.title' | translate}}</h1>
			</div>
			<user-avatar user="page.user"></user-avatar>
			<aside class="user-info">
				<div class="user-name">{{::page.user | userDisplayName}}</div>
				<p class="joined-date-row">{{'public.user.joined' | translate}} <span class="joined-date">{{::page.user.creationDate | amDateFormat: 'LL'}}</span></p>
			</aside>
		</header>

		<!-- <a id="settings-btn" ui-sref=".settings" ng-if="::page.isSameUser">{{'public.user.settings' | translate}}</a> -->
		<!-- for child state - settings modal -->
		<!-- <ui-view></ui-view> -->

		<section class="user-activities">
			<h2>{{'public.user.activities.title' | translate}}</h2>

			<section id="tickets-container" class="user-activity"  ng-init="isTicketsCollapsed = false;" ng-class="{'collapsed': isTicketsCollapsed}" is-ticketing-enabled>
				<h4 ng-click="isTicketsCollapsed = !isTicketsCollapsed;">{{'public.user.tickets.header' | translate}} <span id="tickets-count">({{::page.userTickets.itemsCount || 0}})</span></h4>
				<div class="activities-list">
					<ul>
						<li class="ticket" ng-repeat="ticket in page.userTickets.items">
							<a inner-link="{{::ticket.uri}}">
								<svg-icon name="ticket"></svg-icon>
								<span class="title">{{::ticket.subject}}</span>
								<p class="info">
									<span class="creation-date">{{::ticket.creationDate | amDateFormat: 'LL'}}</span>
									<span class="replies">
										<span ng-show="ticket.repliesCount > 0">{{'public.user.tickets.replies' | translate: {replyCount: ticket.repliesCount} }} </span>
										<span ng-hide="ticket.repliesCount > 0" class="no-reply">{{'public.user.tickets.no.reply' | translate}}</span>
									</span>
								</p>
							</a>
						</li>
						<span class="none" ng-if="::page.userTickets.itemsCount === 0">
							{{'public.user.tickets.none' | translate}}
						</span>
					</ul>
					<a href="" ng-if="page.userTickets.items.length < page.userTickets.itemsCount" ng-click="page.viewMoreTickets()" class="view-more">{{'common.view-more' | translate}}</a>
				</div>
			</section>

			<section id="followed-content-container" class="user-activity"  ng-init="isContentCollapsed = true;" ng-class="{'collapsed': isContentCollapsed}">
				<h4 ng-click="isContentCollapsed = !isContentCollapsed;">{{'public.user.followed-content.header' | translate}} <span id="followed-content-count">({{page.followedContent.itemsCount || 0}})</span></h4>
				<div class="activities-list">
					<ul>
						<li class="followed-content" ng-repeat="item in page.followedContent.items">
							<a inner-link="{{::item.uri}}">
								<svg-icon name="article-followed"></svg-icon>
								<span class="title">{{::item.title}}</span>
								<p class="info">
									<span class="creation-date">{{::item.lastUpdateDate | amDateFormat: 'LL'}}</span>
								</p>
								<div class="action-row">
									<a class="open" inner-link="{{::item.uri}}"><svg-icon name="open" class="middle"></svg-icon>{{'common.view' | translate}}</a>
									<span class="unfollow" ng-click="page.unfollowItem(item)">{{'common.unfollow' | translate}}<svg-icon name="unfollow" class="middle"></svg-icon></span>
								<div>
							</a>
						</li>
						<span class="none" ng-if="::page.followedContent.itemsCount === 0">
							{{'public.user.followed-content.none' | translate}}
						</span>
					</ul>
					<a href="" ng-if="page.followedContent.items.length < page.followedContent.itemsCount" ng-click="page.viewMoreFollowedContent()" class="view-more">{{'common.view-more' | translate}}</a>
				</div>
			</section>
		</section>
	</section>
</main>

`;
export = templateData;
