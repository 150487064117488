import CategoryService from './../common/category/category.srv';
import ArticleService from './../article/article-service/article.srv';
import * as ans from '@wix/answers-api';
import TemplateConfigService from './../common/template-config/template-config.srv';

export type ArticlesByType = {
	[type: number]: ans.SearchResult<ans.Article>
};

export default class CategoryPageService {
	/*@ngInject*/
	constructor(private $q: ng.IQService, private categoryService: CategoryService, private articleService: ArticleService, private appConfig: ans.AppConfig, private $location, private templateService: TemplateConfigService) {}

	searchsByCategoryUri(categoryPermalink: string, type: ans.ArticleType = null): ng.IPromise<{results: ArticlesByType, category: ans.Category}> {
		let dfd = this.$q.defer();
		var resMap = {}, promises = [];
		var categoryService = this.categoryService;
		this.categoryService.getByUri(categoryPermalink)
		.then(category => {
			this.articleService.getCategoryTypesCounts(category.id).then(counts => {
				var availableTypes = counts.items.reduce((availableTypes, item: {count: number, value: string}): ans.ArticleType[] => {
					if (item.count) {
						var enumStr = ans.ArticleType[item.value];
						availableTypes.push(ans.ArticleType[enumStr]);
					}
					return availableTypes;
				}, []);
				if (availableTypes.length) {
					const pageSize = this.templateService.categoriesPageSize();
					availableTypes.forEach( type => promises.push(this.articleService.getByCategoryAndTypeAndSummarize(category.id, type, 1, pageSize)));

					this.$q.all(promises).then((allData) => {
						availableTypes.forEach( (type, index) => {
							resMap[type] = allData[index];
						});
						dfd.resolve({category: category, results: resMap, availableTypes: availableTypes});
					});
				} else {
					dfd.resolve({category: category, results: {}, availableTypes: availableTypes});
				}
			});
		})
		.catch((e) => dfd.reject(e));

		return dfd.promise as any;
	}
}
