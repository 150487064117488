import * as ans from '@wix/answers-api';

/* @ngInject */
export default function MainLoaderDirectiveFactory(): ng.IDirective {

	return <ng.IDirective> {
		restrict: 'E',
		template: require('./loader.tpl.ts')
	};
};
