import * as ans from '@wix/answers-api';
import * as bi from '@wix/answers-bi';
import { AppConfig } from '@wix/answers-api';
import SdkEventType from '../sdk/sdk-events.enum';
import Auth from '../../auth/auth.srv';

declare var Promise: any;

export default class BiService {
	logger: bi.Logger;
	handlers: bi.LoggerHandler[];
	baseData: bi.PublicBaseData;

	/*@ngInject*/
	constructor(private companyId: string, private appConfig: AppConfig, private auth: Auth) {
		const capturedReferral = /referral=([^&]+)/.exec(window.location.href);
		const referralName = capturedReferral && capturedReferral[1] ? capturedReferral[1] : null;
		const locale = appConfig.lang;
		const referralUrl = document.referrer;
		const user = this.auth.getCurrentUser();
		this.baseData = {
			tenantId: companyId,
			userId: user ? user.id : null,
			locale,
			referralName,
			referralUrl,
			sourceType: 'help-center'
		}

		this.handlers = [bi.createWixPublicHandler(this.baseData)];
		this.logger = bi.createLogger(this.handlers, this.errorHandler);
	}

	maybeSetUserId = () => {
		if (!this.baseData.userId) {
			const user = this.auth.getCurrentUser();
			if (user) {
				this.baseData.userId = user.id;
				this.handlers = [bi.createWixPublicHandler(this.baseData)];
				this.logger = bi.createLogger(this.handlers, this.errorHandler);
			}
		}
	}

	errorHandler = (e: Error) => console.error('error with bi handler', e);

	logEvent = (eventType: bi.EventType, data: any) => {
		this.maybeSetUserId();
		this.logger.logEvent({type: (eventType as any), data});
	}

	addHandler = (handler: bi.LoggerHandler) => {
		this.handlers.push(handler);
		this.logger = bi.createLogger(this.handlers, this.errorHandler);
	}
}
