import SeoManager from './seo-manager.srv';

/*@ngInject*/
function alternateLangsRoutingBinder($rootScope, seoManager: SeoManager) {
	$rootScope.$on('$stateChangeSuccess', function(event, toState) {
		var setAlternateTagsByUrl = toState.data && toState.data.setAlternateTagsByUrl;
		if (setAlternateTagsByUrl) {
			seoManager.setAlternateLanguageMetaTagsByCurrentUrl();
		}
	});
};

export default	alternateLangsRoutingBinder;
