
/* tslint:disable */
const templateData = `
<main>
	<header class="showing">
		<a class="back-home" inner-link="/">
			<svg-icon name="back-home"></svg-icon>
			<span class="back-home-text">{{::'public.header.home.link' | translate}}</span>
		</a>
		<span class="showing-text" ng-bind-html="'public.search.showing.result' | translate: {numOfResults: page.searchResults.length, searchTerm: page.searchTerm}"></span>
	</header>
	<section ng-show="page.searchResults.length">
	    <section class="search-results-section">
	        <ul class="search-results">
	            <li class="search-result"
					sdk-event-trigger="articleSearchAnswerClicked"
					sdk-event-data="{searchTerm: page.searchTerm, searchVersion: page.searchVersion, searchMethod: page.searchMethod, result: searchResult, order: $index}"
					 ng-repeat="searchResult in page.searchResults">
	                <div class="search-result-inner">
						<svg-icon name="article"></svg-icon>
	                    <a class="article-item"
							data-hover="{{::'common.read' | translate}}"
							ng-click="page.onResultClick(searchResult.id, $index)"
							inner-link="{{::searchResult.uri}}"
							force-server-side-routing-link="{{page.shouldForceServerSideRoutingLink()}}"
						>
	                        <h3 class="title">{{::searchResult.title}}</h3>
	                        <div class="content">{{::searchResult.content | htmlToText}}</div>
	                    </a>
						<div class="article-path">
	                        <a class="category" ng-repeat="category in page.getCategoryParents(searchResult.categoryId)" inner-link="{{category.uri}}">{{::category.name}}</a>
	                        <span class="classification">{{::'common.article-type.' + searchResult.type | translate}}</span>
	                    </div>
	                </div>
	            </li>
	        </ul>
	    </section>
    </section>
	<div class="empty-search" ng-show="!page.searchResults.length">
		<div class="empty-search-icon">
			<svg-icon name="empty-search"></svg-icon>
		</div>
		<div class="empty-search-msg" ng-bind-html="'public.search.no.results' | translate | htmlSafe"></div>
		<div class="empty-search-msg-sub" ng-bind-html="'public.search.no.results-sub' | translate | htmlSafe"></div>
	</div>
</main>

`;
export = templateData;
