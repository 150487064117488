'use strict';
import Prerender from './../../common/prerender/prerender.srv';


class GoHomeCounter {
	redirectCounter: number;
	delay: string;

	/*@ngInject*/
	constructor($state: ng.ui.IStateService, $interval: ng.IIntervalService, prerender: Prerender, $scope: ng.IScope) {
		const delayFromAttr = parseInt(this.delay, 10);
		this.redirectCounter = delayFromAttr || 9;

		if (!prerender.isPrerenderMode()) {
			var redirect = $interval(() => {
				if (this.redirectCounter > 1) {
					this.redirectCounter = this.redirectCounter - 1;
				} else {
					setTimeout(() => {
						$interval.cancel(redirect);
						$state.go('app.home');
					}, 1000);
				}
			}, 1000);

			$scope.$on('$stateChangeStart', () => {
				$interval.cancel(redirect);
			});
		}
	}
}

export default function goHomeCounter(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			delay: '@'
		},
		bindToController: true,
		template: '{{counter.redirectCounter}}',
		controllerAs: 'counter',
		controller: GoHomeCounter
	};
}
