import UserToolbarService from '../../structure/user-toolbar/user-toolbar.srv';
import {TicketContext} from '@wix/answers-common-components';
import Tickets from '../ticket-service/tickets.srv';
import FollowService from '../../common/services/follow/follow.srv';
import * as ans from '@wix/answers-api';
import Auth from '../../auth/auth.srv';
import BrandingConfig from './../../structure/branding/branding.i';
import CategoryService from './../../common/category/category.srv';
import Sdk from './../../common/sdk/sdk.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import { PublicEventType } from '@wix/answers-bi';
import BiService from '../../common/bi/bi.srv';

class TicketPageDirectiveController {

	newReplyContent: string = '';
	makeTicketPrivate: boolean = false;

	replies: ans.Reply[];
	ticket: ans.Ticket;
	relatedItems: ans.Article[];
	isTicketFollowedByUser: boolean;
	isTicketFollowComplete: boolean = true;
	isTicketAuthor: boolean = false;
	isUserAgent: boolean = false;
	categoryParents: ans.Category[];
	currentUser: ans.User;
	appUrl: string;
	helpCenterUrl: string;
	addReplyInProcess: boolean = false;
	focusReplayTextarea: boolean = false;
	isTicketOverYearOld: boolean = false;

	textEditorOptions;

	/*@ngInject*/
	constructor (private tickets: Tickets,
		private auth: Auth,
		private followService: FollowService,
		private userToolbarService: UserToolbarService,
		textEditorOptions,
		private $scope,
		private contactFormEnabled,
		private brandingConfig: BrandingConfig,
		private categoryService: CategoryService,
		private $state: ng.ui.IStateService,
		private moment,
		sdk: Sdk,
		private biService: BiService,
		appConfig: ans.AppConfig) {
			if (auth.isLoggedIn()) {
				this.followService.isTicketFollowedByUser(this.ticket)
				.then((isFollowed) => {
					this.isTicketFollowedByUser = isFollowed;
					this.updateFollowContext(isFollowed);
				});
			}

			var currentUser = this.auth.getCurrentUser();
			this.currentUser = currentUser;
			if (currentUser) {
				this.isTicketAuthor = currentUser.id === this.ticket.user.id;
				this.isUserAgent = auth.isAgent();
			}

			const that = this;
			const ticketTextEditorOptions = angular.copy(textEditorOptions);
			const origenFocusCallback = angular.copy(textEditorOptions.focusCallback);
			ticketTextEditorOptions.toolbarExternal = '.text-editor-toolbar';
			ticketTextEditorOptions.focusCallback = ((e) => {
				that.$scope.$apply(() => {
					that.focusReplayTextarea = true;
				});
				origenFocusCallback(e);
			});
			this.textEditorOptions = ticketTextEditorOptions;
			// old category to new
			// let ticketCategory = (this.ticket.category) ? this.categoryService.getCachedCategoryById(this.ticket.category.id) : null;
			// kombina end
			// this.setTicketCategoryParents(ticketCategory);
			this.appUrl = `${appConfig.appUrl}/${ans.appUrlsMapping.helpDeskBase}/ticket/${this.ticket.id}`;
			this.helpCenterUrl = appConfig.helpCenterUrls[appConfig.lang];

			const dateNow = moment(Date());
			this.isTicketOverYearOld = dateNow.diff(this.ticket.creationDate, 'years') > 0;

			sdk.setViewedPageData(PageType.ticket, this.ticket.id, this.ticket);
			$scope.$watch('$viewContentLoaded', () => {
				this.biService.logEvent(PublicEventType.TICKET_PAGE_LOAD, {ticket: this.ticket});
			});

			this.userToolbarService.setTicketContextView(this.getUserToolbarTicketContext());
		}

		setTicketCategoryParents = (category: ans.Category) => {
			this.categoryParents = [];
			while (category) {
				this.categoryParents.unshift(category);
				category = this.categoryService.getCachedCategoryById(category.parentId);
			}
		};

		addReply (content) {
			if (!this.addReplyInProcess) {
				this.addReplyInProcess = true;
				this.tickets.addReply(this.ticket.id, content)
				.then((reply) => {
					this.newReplyContent = '';
					this.ticket.repliesCount++;
					this.addReplyInProcess = false;

					return this.replies.push(reply);
				});
			}
		}

		getUserToolbarTicketContext(): TicketContext {
			return {
				ticket: this.ticket,
				isFollowing: this.isTicketFollowedByUser,
				onFollowToggle: (toggleFollow: boolean) => this.followTicket()
			};
		}

		updateFollowContext(isFollowed: boolean) {
			const updatedContext: TicketContext = {...this.getUserToolbarTicketContext(), isFollowing: isFollowed};
			this.userToolbarService.toggleTicketFollow(updatedContext);
		}

		followTicket() {
			this.isTicketFollowComplete = false;
			const oldState = this.isTicketFollowedByUser;
			const newState = !oldState;

			this.updateFollowContext(newState);
			this.isTicketFollowedByUser = newState;
			this.followService.followTicket(this.ticket, newState)
			.catch(() => {
				this.isTicketFollowedByUser = oldState;
				this.updateFollowContext(oldState);
			})
			.finally(() => this.isTicketFollowComplete = true);
		}

		isAgent(reply) {
			const isAgent: boolean = reply.type === ans.ReplyType.AGENT;
			return isAgent;
		}

		signin() {
			return this.auth.accountRedirect('signin');
		}
	}

	export default function ticketPageDirectiveFactory(): ng.IDirective {
		return <ng.IDirective>{
			template: require('./ticket-page.tpl.ts'),
			scope: {
				ticket: '=',
				replies: '=',
				relatedItems: '='
			},
			restrict: 'E',
			controllerAs: 'page',
			bindToController: true,
			controller: TicketPageDirectiveController
		};
	}
