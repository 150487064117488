/// <reference path="../../references.ts" />

'use strict';

import * as ans from '@wix/answers-api';

class UserAvatarDirectiveController {
	user: ans.User;

	/*@ngInject*/
	constructor() {
		// console.log('this.categoryTree', this.categoryTree);
	}

	getDefaultAvatarClass = () => {
		var subUserIdStr = this.user.id.substring(0, 3);
		var subUserId = parseInt(subUserIdStr, 16);
		var avatarClassIndex = subUserId % 5;
		return ++avatarClassIndex;
	};
}

export default function userAvatarDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			user: '='
		},
		bindToController: true,
		template: require('./user-avatar.tpl.ts'),
		controllerAs: 'avatar',
		controller: UserAvatarDirectiveController
	};
}
