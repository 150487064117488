
/* tslint:disable */
const templateData = `
<div class="sitemap breadcrumbs" ng-if="ctrl.showCategoryBreadcrumbs" itemscope itemtype="http://data-vocabulary.org/Breadcrumb" sticky-inner-header>
	<a inner-link="/" itemprop="url">
		<svg-icon name="home"></svg-icon>
		<span class="home-link-title">{{'public.header.home.link' | translate}}</span>
	</a>
	<a class="sm-category"
		ng-repeat="category in ctrl.tree"
		ng-if="category.id === ctrl.parentCategory.id"
		itemprop="url"
		inner-link="{{ctrl.parentCategory.uri}}"
		ng-click="ctrl.breadcrumbsCategoryClick($event)"
		sdk-event-trigger="categoryNavigated"
		sdk-event-data="{category: category, parentCategory: ctrl.category}">
		<span itemprop="title">{{category.name}}</span>
	</a>
	<span class="sm-subcategory">{{ctrl.category.name}}</span>
</div>

<section class="article-list">
	<div class="category-header" sticky-inner-header>
		<div class="category-name" ng-click="ctrl.categoryNameClick($event)">
			<h1>{{ctrl.category.name}}</h1>
		</div>
	    <nav class="category-type" ng-show="ctrl.availableTypes.length > 1" ng-if="!ctrl.showAllCategoryTypes">
			<a ng-repeat="type in ctrl.availableTypes"
	            ng-class="{active: type === ctrl.type}"
					sdk-event-trigger="categoryPageTypeSelect"
					sdk-event-data="{type: type, category: ctrl.category}"
	            ui-sreaf="app.category.content({categoryPermalink: ctrl.uri, type: type})"
							ng-click="ctrl.type = type">{{::'common.article-type.plural.' + type | translate}}</a>
		</nav>
		<nav class="category-type-menu" ng-show="ctrl.availableTypes.length > 1" ng-if="ctrl.showCategoryTypeAnchorMenu">
			<a ng-repeat="type in ctrl.availableTypes"
				class="type"
				ng-class="{active: type === ctrl.type}"
				ng-click="ctrl.anchorClick(type)" >{{::'common.article-type.plural.' + type | translate}} <span class="type-count">({{ctrl.articlesByTypes[type].itemsCount}})</span></a>
		</nav>
		<div class="category-type sticky-type" ng-repeat="type in ctrl.availableTypes" ng-show="ctrl.type == type" ng-if="ctrl.showCategoryTypeAnchorMenu">
			{{::'common.article-type.plural.' + type | translate}}
		</div>
	</div>

	<div class="articles-by-type" ng-class="{'no-types': ctrl.availableTypes.length === 1}">
    <ul ng-attr-typeName="{{::'common.article-type.plural.' + type | translate}}" ng-attr-id="{{type}}" class="articles" ng-class="{activeArticles: type === ctrl.type}" ng-show="ctrl.showArticles && (type === ctrl.type || ctrl.showAllCategoryTypes) && ctrl.articlesByTypes[ctrl.availableTypes[$index]].items.length > 0" ng-repeat="type in ctrl.availableTypes">

				<div class="category-type" ng-if="ctrl.showAllCategoryTypes">
					{{::'common.article-type.plural.' + type | translate}}
				</div>

				<li class="article" ng-repeat="article in ctrl.articlesByTypes[ctrl.availableTypes[$index]].items">
            <a
				inner-link="{{::article.uri}}"
				force-server-side-routing-link="{{ctrl.shouldForceServerSideRoutingLink()}}"
				data-hover="{{::'common.read' | translate}}"
				sdk-event-trigger="articleResultClick"
			  	sdk-event-data="{article: article, order: $index, fromSearch: false}">
					<svg-icon class="article-icon" name="category-article" ng-if="ctrl.showAllCategoryTypes"></svg-icon>
					<span>{{article.title}}</span>
			</a>
        </li>
				<a class="view-more" ng-click="ctrl.viewMore()" ng-show="type === ctrl.type && ctrl.articlesByTypes[type].items.length < ctrl.articlesByTypes[type].itemsCount">{{'common.view-more' | translate}}</a>
    </ul>
	</div>
</section>

`;
export = templateData;
