import * as ans from '@wix/answers-api';
import { isFeatureEnabled } from '@wix/answers-experiments';
import CategoryService from './../../common/category/category.srv';
import Sdk from './../../common/sdk/sdk.srv';
import {PageType} from './../../common/sdk/sdk.srv';

class TicketsPageContainerController {

	categoryTree: ans.Category[];
	type: string;
	isRecent: boolean;
	searchDebounce: number;
	isFeatured: boolean;
	searchMode: boolean;
	searchTerm: string;
	isFromSearch: boolean;
	results: ans.SearchResult<ans.Ticket>;
	/*@ngInject*/
	constructor(categoryService: CategoryService, private $state: ng.ui.IStateService, sdk: Sdk) {
		this.isRecent = $state.is('app.tickets.recent');
		this.searchDebounce = isFeatureEnabled('EnableVespaInHelpCenter') && sdk.currentLang === 'en' ? 2000 : 700;
		this.categoryTree = categoryService.categoryTree;
		sdk.setViewedPageData(PageType.ticketsPage, null, {results: this.results});
	}

	getSearchResult = () => {
		this.$state.go('.', {type: this.type, searchTerm: this.searchTerm, page: 1});
	};

	exitSearchMode = () => {
		this.searchMode = false;
		this.searchTerm = '';
		this.getSearchResult();
	};

	enterSearchMode = () => {
		this.searchMode = true;
		angular.element('.ticket-search').trigger('focus');
	};
}

export default function ticketsPageContainerDirectiveFactory(): ng.IDirective {
	return <ng.IDirective> {
		restrict: 'E',
		scope: {
			page: '@',
			type: '@',
			categoryTree: '=?',
			searchTerm: '@',
			isFromSearch: '=',
			results: '=?'
		},
		template: require('./tickets-page-container.tpl.ts'),
		controller: TicketsPageContainerController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
