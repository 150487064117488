import UserToolbarService from '../../structure/user-toolbar/user-toolbar.srv';
import * as ans from '@wix/answers-api';
import Search from './../article-search/article-search.srv';
import BrandingConfig from './../../structure/branding/branding.i';
import Sdk from './../sdk/sdk.srv';
import SdkEventType from './../sdk/sdk-events.enum';
import AnalyticsService from './../analytics/analytics.srv';
import {AppConfig} from '@wix/answers-api';
import { startInteraction, endInteraction } from '../../fedops';
import BiService from '../bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import { shouldForceServerSideRouting } from '../../common/experiments';
import { isFeatureEnabled } from '@wix/answers-experiments';

class HeroController {
	searchTerm: string = '';
	searchResults: ans.Article[];
	searchVersion: string;
	searchMethod: string;
	searchCount: number;
	searchDebounce: number;
	showSearchResult: boolean = false;
	waitForSearchResults: boolean = false;
	currentSearchWasIgnored: boolean = false;
	isSmall: boolean = false;
	withUserToolbar: boolean = false;
	addTransition: boolean = false;
	prevState: string = '';
	hoverSearchResultId: string = '';
	hoverSearchResultIndex: number = null;
	title: string;
	videoUrl: string;
	ignoreSearchEventPromise: ng.IPromise<void>;
	lastEventSearchTerm: string = '';
	backgroundImageUrl: string = '';
	mobileHeroImageUrl : string = '';

	/*@ngInject*/
	constructor(private articleSearch: Search,
		private brandingConfig: BrandingConfig,
		private $timeout: ng.ITimeoutService,
		private $state: ng.ui.IStateService,
		private userToolbarService: UserToolbarService,
		private analyticsService: AnalyticsService,
		private $rootScope: ng.IRootScopeService,
		$element,
		private sdk: Sdk,
		private biService: BiService,
		private appConfig: AppConfig,
		private $location: ng.ILocationService,
		$window: Window,
		private $scope: ng.IScope, $document: Document, $filter, $sce
	) {
		this.searchDebounce = isFeatureEnabled('EnableVespaInHelpCenter') && sdk.currentLang === 'en' ? 2000 : 700;

		this.setSmallIfNeeded($state.current.name);
		$rootScope.$on('$stateChangeStart', (event, data) => {
			this.addTransition = true;
			this.setSmallIfNeeded(data.name, true);
			this.searchTerm = '';
		});
		$rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
			$('body,html').stop(true, true).animate({ scrollTop: 0 }, 1);
		});

		this.withUserToolbar = (this.$rootScope as any).showUserToolbar;
		this.backgroundImageUrl = this.brandingConfig.backgroundImageUrl;
		this.mobileHeroImageUrl = this.brandingConfig.mobileHeroImageUrl || this.backgroundImageUrl;
		this.videoUrl = $sce.trustAsResourceUrl(this.brandingConfig.backgroundVideoUrl);
		this.$scope.$on(ans.PreviewApiActions.changeHeroImageUrl, (e, data) => {
			this.$scope.$apply(() => this.backgroundImageUrl = data);
		});
		this.$scope.$on(ans.PreviewApiActions.changeHeroVideoUrl, (e, data) => {
			this.$scope.$apply(() => this.brandingConfig.backgroundVideoUrl = data);
		});
	}

	setSmallIfNeeded(stateName: string, scroll?: boolean) {
		var $header = $('body').find('#main-header');
		$header = $header.length ? $header : $('body').find('#custom-header');
		var $searchBox = $('body').find('.search-box');

		if (stateName === 'app.error' || stateName === 'app.404'  || stateName === 'app.403' || stateName === 'app.ticket-restricted') {
			$('body hero').hide();
		} else {
			$('body hero').show();
		}

		try {
			// console.log('this.prevState', stateName);
			if (stateName !== 'app.home') {
				var isScrolledIntoView = this.isSearchInView($searchBox, $header);
				//if (isScrolledIntoView) {
				scroll = scroll && (!this.prevState || this.prevState === 'app.home');
				if (scroll) {
					$('body,html').stop(true, true).animate({ scrollTop: 0 }, 300, () => {
						this.$scope.$apply(() => {
							this.isSmall = true;
						});
					});
				} else {
					this.isSmall = true;
				}
			} else {
				$('body,html').stop(true, true).animate({ scrollTop: 0 }, 300, () => {
					this.$scope.$apply(() => {
						this.isSmall = false;
					});
				});
			}
		} catch (e) {
			this.isSmall = stateName !== 'app.home';
		}

		this.prevState = stateName;
	}

	getSearchResult = () => {
		if (this.searchTerm && this.searchTerm.length > 1) {
			this.waitForSearchResults = true;

			startInteraction('search');
			this.articleSearch.search(this.searchTerm, 5)
			.then((searchResults) => {
				this.searchResults = searchResults.data.items;
				this.searchVersion = searchResults.data.searchVersion;
				this.searchMethod = searchResults.data.searchMethod;
				this.searchCount = searchResults.data.itemsCount;
				this.waitForSearchResults = false;
				this.currentSearchWasIgnored = false;
				this.hoverSearchResultIndex = null;

				endInteraction('search');

				this.sdk.triggerEvent(SdkEventType.articleSearch, {
					results: this.searchResults,
					searchTerm: this.searchTerm,
					searchVersion: this.searchVersion,
					searchMethod: this.searchMethod,
					requestId: searchResults.headers('X-Wix-Request-Id') || 'N/A',
					conductionValue: isFeatureEnabled('EnableVespaInHelpCenter'),
				});
				this.biService.logEvent(PublicEventType.SEARCH, {term: this.searchTerm, searchVersion: this.searchVersion, searchMethod: this.searchMethod, results: {articles: this.searchResults, count: this.searchCount}});
			});
		}
	};

	hideSearchResult = () => {
		this.$timeout(() => {
			this.showSearchResult = false;
		}, 300);
		this.ignoreSearchEventPromise = this.$timeout(() => {
			if (!this.currentSearchWasIgnored) {
				this.currentSearchWasIgnored = true;

				this.sdk.triggerEvent(SdkEventType.articleSearchIgnored, {
					searchTerm: this.searchTerm,
					results: this.searchResults,
					searchVersion: this.searchVersion,
					searchMethod: this.searchMethod,
				});

				if (this.searchTerm) {
					if (this.searchResults && this.searchResults.length === 0) {
						//Analytics - SEARCH_RESULTS_NONE
						this.analyticsService.logSearchResultNone(this.searchTerm);
					} else {
						//Analytics - SEARCH_RESULTS_IGNORED
						this.analyticsService.logSearchResultIgnored(this.searchTerm);
					}
				}
			}
		}, 300);
	};

	onResultClick(articleId: string, position: number) {
		//Analytics - SEARCH_RESULTS_ARTICLE_CLICKED_INSTANT
		this.analyticsService.logSearchResultArticleClicked(this.searchTerm, articleId, position);
		this.biService.logEvent(PublicEventType.SEARCH_RESULT_SELECTED, {term: this.searchTerm, searchVersion: this.searchVersion, searchMethod: this.searchMethod, article: this.searchResults[position], index: position});

		this.searchTerm = '';
		this.searchResults = [];
		this.waitForSearchResults = true;
		this.$timeout.cancel(this.ignoreSearchEventPromise);
	}

	goToSearch = () => {
		if (this.searchCount > this.searchResults.length && this.searchTerm.length > 1) {
			//Analytics - SEARCH_RESULTS_CLICKED_MORE
			this.analyticsService.logSearchResultClickMore(this.searchTerm);
			this.biService.logEvent(PublicEventType.SEE_ALL_RESULTS, {term: this.searchTerm, searchVersion: this.searchVersion, searchMethod: this.searchMethod, results: {articles: this.searchResults, count: this.searchCount}})
			this.$timeout.cancel(this.ignoreSearchEventPromise);
			this.$state.go('app.search', {term: this.searchTerm});
		}
	};

	onKeyDown = (e) => {
		this.showSearchResult = true;

		if (e.which === 13) {
			if (this.hoverSearchResultIndex !== null) {
				if (this.hoverSearchResultIndex < this.searchResults.length) {
					var hoverItem = this.searchResults[this.hoverSearchResultIndex];
					this.biService.logEvent(PublicEventType.SEARCH_RESULT_SELECTED, {term: this.searchTerm, searchVersion: this.searchVersion, searchMethod: this.searchMethod, article: hoverItem, index: this.hoverSearchResultIndex});
					this.$location.path(hoverItem.uri);
				} else {
					this.goToSearch();
				}
				this.showSearchResult = false;
			}
		}

		if (this.searchResults) {
			if (this.hoverSearchResultIndex === null && this.searchResults.length) {
				// down
				if (e.which === 40) {
					this.hoverSearchResultIndex = 0;
				}
			} else {
				// up
				if (e.which === 38) {
					if (this.hoverSearchResultIndex > 0) {
						this.hoverSearchResultIndex--;
					}
				}
				// down
				if (e.which === 40) {
					if (this.hoverSearchResultIndex < this.searchResults.length) {
						this.hoverSearchResultIndex++;
					}
				}
			}
		}
	};

	isSearchInView = ($elem, $elemRel) => {
		var elemTop = $elem.offset().top;
		var elemBottom = elemTop + $elem.height();

		var elemRelTop = $elemRel.offset().top;
		var elemRelBottom = elemRelTop + $elemRel.height();

		return elemBottom >= elemRelBottom;
	};

	shouldForceServerSideRoutingLink() {
		return shouldForceServerSideRouting(this.biService.logger);
	}
}

function HeroDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		template: require('./hero.tpl.ts'),
		controller: HeroController,
		controllerAs: 'hero',
		bindToController: true
	};
}

export default HeroDirectiveFactory;
