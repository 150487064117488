enum SdkEventType {
	appLoad = 1,
	unreadNotificationsCount = 2,
	itemRated = 100, //done
	itemVoted = 105, //done
	itemFollow = 110, //done
	linkedTicketClick = 120, //done
	articleSuggest = 200, //done
	articleSuggestClicked = 210, //done
	ticketAdded = 300, //done
	ticketReplied = 310,
	ticketFormOpened = 320,
	ticketSubmitClicked = 321,
	ticketLinkClick = 322,
	anotherTicket = 323,
	signInFromTicket = 324,
	articleSearch = 400,
	articleSearchAnswerClicked = 410, //done
	articleSearchAllResultsClicked = 420,
	articleSearchIgnored = 430,
	articleSearchNoAnswer = 440,
	articleResultClick = 441,
	articleTicketFormOpened = 450,
	contactSelectCategory = 500, // done
	contactSelectIssue = 510, //no need for it anymore
	contactDescribeIssue = 520, //done
	contactClickOnAnswer = 530, //done
	contactClickStillNeedHelp = 540,
	contactClickMoreHelp = 550,
	contactFieldsChanged = 560,
	ticketSearch = 600,
	ticketSearchAnswerClicked = 610,
	ticketSearchAllResultsClicked = 620,
	ticketSearchIgnored = 630,
	ticketSearchNoAnswer = 640,
	ticketsPaginationClick = 650,
	phoneCallCancelled = 700,
	categoryPageTypeSelect = 1001,
	submitTicketCategorySelect = 1002,
	submitTicketClicked = 1003,
	categoryNavigated = 1100,
	pageLoad = 800
}

export default SdkEventType;
