/// <reference path="../references.ts" />

// import homeRouting from './home-routing/home-routing';
import homePageDirectiveFactory from './home-page/home-page.drv';
import CategoryService from './../common/category/category.srv';
import homeRouting from './home-routing/home-routing';

angular.module('home', ['core', 'ui.router', 'common', 'categories', 'seo', 'article', 'tickets'])
		.config(homeRouting)
		.directive('homePage', homePageDirectiveFactory);
