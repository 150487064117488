
/* tslint:disable */
const templateData = `
<section class="categories-section" ng-class="{'show-all-categories': page.showAllCategory}">
	<ul class="home-categories">
		<li ng-repeat="category in page.categoryTree"
		ng-class="{'no-sub': !category.children.length && !page.mainCategoriesTopArticlesMap[category.id].length, 'expanded-category': page.expandedCategoryId === category.id, 'no-sub-cats': !category.children.length}" data-type="category"
		ng-mouseenter="page.hoverCategory[$index] = true;"  ng-mouseleave="page.hoverCategory[$index] = false;">
			<a inner-link="{{category.uri}}" force-disable-inner-link="{{page.disableParentCategoryLink(category)}}" ng-click="page.handleCategoryClick(category)" sdk-event-trigger="categoryNavigated" sdk-event-data="{category: category}">
				<div class="category-icon" ng-hide="category.data.iconSrc && !category.data.iconKey">
					<i class="material-icons" ng-show="category.data.iconKey">{{::category.data.iconKey}}</i>
					<i class="material-icons" ng-hide="category.data">style</i>
				</div>
				<div ng-show="category.data.iconSrc && !category.data.iconKey" class="category-icon-img">
					<span class="category-icon-img-helper"></span>
					<img ng-src="{{category.data.iconSrc}}" ng-error="category.data.iconSrc = ''"/>
				</div>
				<div class="category-name">{{category.name}}</div>
				<div class="category-overlay empty-category" ng-if="!category.children.length && !page.mainCategoriesTopArticlesMap[category.id].length">{{::'public.home.view-articles' | translate}}</div>
			</a>
			<ul class="sub-categories category-overlay" ng-show="category.children.length || page.mainCategoriesTopArticlesMap[category.id].length">
				<li data-type="sub-category" ng-if="::category.children.length"
					ng-repeat="subCategory in category.children"
				 	sdk-event-trigger="categoryNavigated"
					sdk-event-data="{category: subCategory, parentCategory: parentCategory}">
					<a inner-link="{{subCategory.uri}}" ng-click="page.handleSubcategoryClick(subCategory)">
						<div class="sub-category-title">{{subCategory.name}}</div>
					</a>

					<ul class="sub-category-articles" ng-show="page.subCategoryArticlesMap[subCategory.id].length">
						<li data-type="sub-category-article" class="category-article" ng-repeat="article in page.subCategoryArticlesMap[subCategory.id]">
							<a
								inner-link="{{article.uri}}"
								force-server-side-routing-link="{{page.shouldForceServerSideRoutingLink()}}"
							>
								<svg-icon name="category-article"></svg-icon>
								<div class="sub-category-article-title">{{article.title}}</div>
							</a>
						</li>

						<li class="sub-categories-link" data-type="view-sub-category">
							<a inner-link="{{subCategory.uri}}">
								<div>{{::'common.view.all' | translate}}</div>
							</a>
						</li>
					</ul>

				</li>

				<li data-type="sub-category" class="category-article" ng-if="::page.mainCategoriesTopArticlesMap[category.id].length"
					ng-repeat="article in page.mainCategoriesTopArticlesMap[category.id]">
					<a
						inner-link="{{article.uri}}"
						force-server-side-routing-link="{{page.shouldForceServerSideRoutingLink()}}"
					>
						<svg-icon name="category-article"></svg-icon>
						<div class="sub-category-title">{{article.title}}</div>
					</a>
				</li>

				<li class="sub-categories-link" data-type="view-all-categories">
					<a inner-link="{{category.uri}}">
						<div>{{::'common.view.all' | translate}}</div>
					</a>
				</li>
			</ul>
		</li>
		<li class="view-all-category-btn">
			<a ng-click="page.showAllCategory = true;">{{::'common.view-more' | translate}}</a>
		</li>
	</ul>
</section>

<section class="top-articles-section" ng-show="page.popularArticles.length"  ng-class="{'show-all-articles': page.showAllArticles}">
	<div class="title-container">
		<h2 preview-changeable-text="public.home.top-articles" data-mobile="{{::'public.mobile.home.top-articles' | translate}}">{{::'public.home.top-articles' | translate}}</h2>
	</div>
	<ul class="top-articles article-list">
		<li ng-repeat="article in page.popularArticles | limitTo: 10"
		sdk-event-trigger="articleResultClick"
		sdk-event-data="{article: article, order: $index}" data-type="article">
			<a
				inner-link="{{::article.uri}}"
				force-server-side-routing-link="{{page.shouldForceServerSideRoutingLink()}}"
			>
				<svg-icon name="article-bold"></svg-icon>
				<div class="article-type">{{::'common.article-type.' + article.type | translate}}</div>
				<h4 class="article-title">{{::article.title | htmlToText}}</h4>
			</a>
		</li>
		<span class="view-all-articles-btn">
			<a ng-click="page.showAllArticles = true;">{{::'common.view.all' | translate}}</a>
		</span>
	</ul>
</section>
`;
export = templateData;
