/// <reference path="../../references.ts" />

'use strict';

import CategoryService from './../../common/category/category.srv';
import * as ans from '@wix/answers-api';
import BrandingConfig from './../../structure/branding/branding.i';
import Sdk from './../../common/sdk/sdk.srv';
import TemplateConfigService from './../../common/template-config/template-config.srv';
import {CategoryTopArticles} from './../../article/article-service/article.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import { filterCategories } from '../../common/content-filters';
import { shouldForceServerSideRouting } from '../../common/experiments';

class HomePageDirectiveController {
	categoryTree: ans.Category[] = [];
	popularArticles: ans.Article[];
	subCategoryArticlesMap: ans.Article[];
	mainCategoriesTopArticles: CategoryTopArticles[];
	mainCategoriesTopArticlesMap: any;
	expandedCategoryId: string;
	disableParentCategoryLinks: boolean;
	searchTerm: string;

	/*@ngInject*/
	constructor(private categoryService: CategoryService, $timeout, sdk: Sdk, private templateService: TemplateConfigService, private $scope, private biService: BiService, private previewMode) {
		this.disableParentCategoryLinks = templateService.enableConditionalLinksInHome();
		this.categoryTree = filterCategories(categoryService.categoryTree, sdk.getContentFilters());
		this.mainCategoriesTopArticlesMap = this.mainCategoriesTopArticles.reduce((categoryTopArticlesMap, item: CategoryTopArticles) => {
			categoryTopArticlesMap[item.categoryId] = item.articles;
			return categoryTopArticlesMap;
		}, {});
		sdk.setViewedPageData(PageType.homePage);
		$scope.$watch('$viewContentLoaded', () => {
			this.biService.logEvent(PublicEventType.HOME_PAGE_LOAD, {});
			if (previewMode) {
				window.parent.postMessage('home-page-loaded', '*');
			}
		});
	}

	disableParentCategoryLink(category: ans.Category) {
		return this.disableParentCategoryLinks && category.children.length > 0;
	}

	handleCategoryClick(category: ans.Category) {
		this.biService.logEvent(PublicEventType.HOME_CATEGORY_CLICKED, {category});
		this.expandedCategoryId = (this.expandedCategoryId === category.id || category.children.length === 0) ? null : category.id;
	}

	handleSubcategoryClick(category: ans.Category) {
		this.biService.logEvent(PublicEventType.HOME_SUBCATEGORY_CLICKED, {category});
	}

	shouldForceServerSideRoutingLink() {
		return shouldForceServerSideRouting(this.biService.logger);
	}
}

function homePageDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		template: require('./home-page.tpl.ts'),
		restrict: 'E',
		scope: {
			popularArticles: '=',
			mainCategoriesTopArticles: '=',
			subCategoryArticlesMap: '='
		},
		controllerAs: 'page',
		bindToController: true,
		controller: HomePageDirectiveController
	};
}

export default homePageDirectiveFactory;
