const iconsMap = {
	'category-article': '',
	'article': '',
	'article-bold': '',
	'article-followed': '',
	'category-home-icon': '',
	'ticket': '',
	'ticket-followed': '',
	'search': require('./search.svg'),
	'home': require('./home-icon.svg'),
	'follow': require('./follow.svg'),
	'v': require('./check-mark.svg'),
	'x': require('./clear.svg'),
	'feature-request': require('./star.svg'),
	'back': require('./back-arrow.svg'),
	'back-home': require('./home-icon.svg'),
	'known-issue': require('./issue.svg'),
	'stats': require('./stats.svg'),
	'manage': require('./manage.svg'),
	'notifications': '',
	'open': '',
	'cancel': require('./clear.svg'),
	'unfollow': require('./clear.svg'),
	'reply': require('./reply.svg'),
	'empty-search': require('./spotlight.svg'),
	'globus': '',
	'clock': '',
	'cat-dd-menu': require('./cat-dd-menu.svg'),
	'answers': require('./answers.svg'),
};

export default iconsMap;
