/// <reference path="../../references.ts" />

'use strict';

export default function scrollToDirectiveFactory () {
	return {
		restrict: 'A',
		link: function(scope, $elm, attrs) {
			var selectorToScroll = attrs.scrollTo;
			var speed = attrs.speed;
			var $target = $(selectorToScroll);
			$elm.on('click', function() {
				$('body,html').stop(true, true).animate({ scrollTop: $target.offset().top }, speed);
			});
		}
	};
};
