/// <reference path="../references.ts" />

'use strict';

import Auth from './auth.srv';
import authRouting from './auth.routing';

angular.module('auth', ['ngCookies', 'core', 'ui.router'])
	.service('auth', Auth)
	.config(authRouting);
