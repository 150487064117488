/// <reference path="../../references.ts" />

import CategoryService from './../../common/category/category.srv';
import SeoManager from './../../seo/seo-manager.srv';
import * as ans from '@wix/answers-api';
import ArticleService from './../../article/article-service/article.srv';
import Tickets from './../../ugc/ticket-service/tickets.srv';
import Prerender from './../../common/prerender/prerender.srv';
import TemplateConfigService from './../../common/template-config/template-config.srv';
import {CategoryTopArticles} from './../../article/article-service/article.srv';
import { filterArticles } from '../../common/content-filters';
import Sdk from '../../common/sdk/sdk.srv';

/*@ngInject*/
function homeRouting($stateProvider: ng.ui.IStateProvider) {
	$stateProvider.state('app.home', {
		url: '/',
		template: '<home-page popular-articles="home.popularArticles" main-categories-top-articles="home.mainCategoriesTopArticles" sub-category-articles-map="home.subCategoryArticlesMap"></home-page>',
		data: {
			setAlternateTagsByUrl: true
		},
		onEnter: function (seoManager: SeoManager, appConfig: ans.AppConfig) {
			seoManager.fetchAndSetData(ans.PageType.HOME_PAGE, appConfig.lang);
		},
		resolve: {
			mainCategoriesTopArticles: function(articleService: ArticleService, categoryService: CategoryService, sdk: Sdk) {
				return categoryService.init()
				.then(() => {
					let categoriesWithNoChildren = categoryService.categoryTree.filter((category) => {
						return !category.children || category.children.length === 0;
					});
					let categoryIds: string[] = categoriesWithNoChildren.map((category) => {
						return category.id;
					});

					return articleService.getCategoriesTopArticles(categoryIds, 10)
					.then((categoryTopArticles: CategoryTopArticles[]) => {
						return categoryTopArticles.map((item) => ({...item, articles: filterArticles(item && item.articles ? item.articles : [], sdk.getContentFilters())}));
					});
				});
			},
			popularArticles: function(articleService: ArticleService, sdk: Sdk) {
				return articleService.getTopItems()
				.then((articles) => {
					return filterArticles(articles || [], sdk.getContentFilters());
				});
			},
			subCategoryArticlesMap: function(
				articleService: ArticleService,
				categoryService: CategoryService,
				templateService: TemplateConfigService,
				sdk: Sdk
			) {
				return categoryService.init()
				.then(() => {
					var test = [];
					const subCatIds = categoryService.categoryTree.reduce((subCatMap, item) => {
						if (item.children && item.children.length) {
							item.children.forEach(subCat => {
								subCatMap.push(subCat.id);
							});
						}
						return subCatMap;
					}, []);

					// Special call for Tiny Template
					if (templateService.showSubCategoryArticlesInHome() && subCatIds.length) {
						return articleService.getCategoriesTopArticles(subCatIds, 3)
						.then((subCategoryTopArticles: CategoryTopArticles[]) => {
							return subCategoryTopArticles.reduce((articlesMap, item) => {
								articlesMap[item.categoryId] = item.articles;
								return articlesMap;
							}, []);
						});
					}
				});
			},
		},
		controller: function(popularArticles, mainCategoriesTopArticles, subCategoryArticlesMap) {
			this.popularArticles = popularArticles;
			this.mainCategoriesTopArticles = mainCategoriesTopArticles || [];
			this.subCategoryArticlesMap = subCategoryArticlesMap || [];
		},
		controllerAs: 'home'
	})
	.state('app.zendesk-home-redirect', { //links from zendesk come like that..
		url: '/hc/:langPart/',
		onEnter: function($stateParams, $window, prerender: Prerender, appConfig) {
			const lang = $stateParams.langPart.split('-')[0];
			const correctUrl = appConfig.helpCenterUrls[lang];
			if (prerender.isPrerenderMode()) {
				prerender.setPermanentRedirect(correctUrl);
			} else {
				$window.location.href = correctUrl;
			}
		}
	});
}

export default homeRouting;
