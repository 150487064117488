/// <reference path="../../references.ts" />

import * as ans from '@wix/answers-api';
import Prerender from '../../common/prerender/prerender.srv';
import SeoManager from '../../seo/seo-manager.srv';
import CategoryService from './../../common/category/category.srv';
import Tickets from './../ticket-service/tickets.srv';
import Auth from './../../auth/auth.srv';
import {htmlToText} from './../../common/html-text/html-text.fit';
import { AppConfig, TicketTimeline } from '@wix/answers-api';
import { PublicEventType } from '@wix/answers-bi';
import BiService from '../../common/bi/bi.srv';
import { isFeatureEnabled } from '@wix/answers-experiments';

/*@ngInject*/
export default function ticketsRoutingConfig($stateProvider: ng.ui.IStateProvider, $urlRouterProvider: ng.ui.IUrlRouterProvider) {

	$stateProvider
	.state('app.ticket-old', {
		url: '/ugc/:userId/{itemId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
		resolve: {
			onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
				const correctUrl = appConfig.helpCenterUrls[appConfig.lang] + '/ticket/' + $stateParams.itemId;
				if (prerender.isPrerenderMode()) {
					prerender.setPermanentRedirect(correctUrl);
				} else {
					$state.go('app.ticket', {'itemId': $stateParams.itemId}, {location: 'replace'});
				}
			}
		}
	})
	.state('app.ticket', {
		url: '/ticket/{itemId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
		template: '<ticket-page ticket="ticketPage.ticket" replies="ticketPage.replies" related-items="ticketPage.relatedItems"></ticket-page>',
		resolve: {
			ticketAndRelatedItems: function(tickets: Tickets, $stateParams, $state: ng.ui.IStateService, prerender: Prerender, $location, $rootScope, appConfig, $window: Window, seoManager: SeoManager, auth: Auth, $q: ng.IQService, biService: BiService) {
				return tickets.getTicket($stateParams.itemId)
				.then((ticket) => {
					if (ticket.locale !== appConfig.lang) {
						var correctUrl = ticket.url;
						if (prerender.isPrerenderMode()) {
							prerender.setPermanentRedirect(correctUrl);
						} else {
							$window.location.replace(correctUrl);
						}
					}

					return tickets.getRelatedItems(ticket)
					.then((relatedItems) => {
						return { ticket: ticket, relatedItems: relatedItems };
					});
				})
				.catch((res) => {
					switch (res.status) {
						case ans.HttpStatus.NOT_FOUND:
							$state.go('app.ticket-not-found');
							biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'ticket', reason: '404', url: $location.absUrl()}});
							break;
						case ans.HttpStatus.FORBIDDEN:
							if (prerender.isPrerenderMode()) {
								$state.go('app.ticket-restricted');
								biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'ticket', reason: '403', url: $location.absUrl()}});
							} else {
								auth.init()
								.then(_ => {
									if (auth.isLoggedIn()) {
										biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'ticket', reason: '403', url: $location.absUrl()}});
										return $state.go('app.ticket-restricted');
									} else {
										return auth.accountRedirect('signin');
									}
								});
							}
							break;
						default:
							$state.go('app.error', {error: res});
							biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'ticket', reason: `${res.status}`, url: $location.absUrl()}});
							break;
					}
				});
			},
			replies: function(tickets: Tickets, $stateParams, $state) {
				return tickets.getTicketReplies($stateParams.itemId, 1, 1000)
				.then((result: ans.SearchResult<ans.Reply> | TicketTimeline) =>
					isFeatureEnabled('publicChatReplies') ? (result as TicketTimeline).timelineItems : (result as ans.SearchResult<ans.Reply>).items
				);
			}
		},
		controller: function(ticketAndRelatedItems, replies) {
			this.ticket = ticketAndRelatedItems.ticket;
			this.relatedItems = ticketAndRelatedItems.relatedItems;
			this.replies = replies;
		},
		controllerAs: 'ticketPage'
	})
	.state('app.ticket-restricted', {
		onEnter: function ($state, prerender: Prerender) {
			prerender.set403();
			$state.go('app.403');
		}
	})
	.state('app.ticket-not-found', {
		onEnter: function ($state, prerender: Prerender) {
			prerender.set404();
			$state.go('app.404');
		}
	})
	.state('app.tickets', {
		abstract: true,
		template: '<tickets-page-container type="{{ctrl.type}}" search-term="{{ctrl.searchTerm}}" is-from-search="ctrl.isFromSearch"></tickets-page-container>',
		controllerAs: 'ctrl',
		data: {
			setAlternateTagsByUrl: true,
			ignoreLoader: true
		},
		controller: function ($scope: ng.IScope) {
			$scope.$on('setType', (ev, type) => {
				this.type = type;
			});

			$scope.$on('setSearchTerm', (ev, searchTerm) => {
				this.searchTerm = searchTerm;
				this.isFromSearch = !!searchTerm;
			});
		}
	})
	.state('app.tickets.results', {
		url: '/tickets?type&page&searchTerm',
		template: '<ticket-results results="ctrl.results" page="{{ctrl.page}}" search-term="{{ctrl.searchTerm}}"></ticket-results>',
		params: {
			type: 'featured',
			page: '1'
		},
		resolve: {
			results: function ($q: ng.IQService) {
				return new $q((_, rej) => {
					rej('404');
				});
			}
		},
		controllerAs: 'ctrl',
		controller: function (results, $stateParams, $scope: ng.IScope) {
			this.results = results;
			this.page = $stateParams.page;
			this.searchTerm = $stateParams.searchTerm;
			$scope.$emit('setType', $stateParams.type);
			$scope.$emit('setSearchTerm', $stateParams.searchTerm);
		}
	});
};
