
/* tslint:disable */
const templateData = `
<aside class="article-info" ng-click="ctrl.getArticleCounters();ctrl.showArticleInfo = !ctrl.showArticleInfo" ng-mouseover="ctrl.getArticleCounters()" click-outside="ctrl.showArticleInfo = false" ng-class="{'open': ctrl.showArticleInfo}">
	<div class="open-stats-btn">
		<svg-icon name="stats"></svg-icon>
	</div>
	<div class="stats-panel" ng-show="ctrl.showArticleInfo">
		<div class="agent-times">
			<div class="agent-times-row">
				<span class="time-lable">{{::'common.updated' | translate}}</span> <span class="last-modified-date">{{::ctrl.article.lastUpdateDate | amDateFormat: 'LL'}}</span>
			</div>
			<div class="agent-times-row">
				<span class="time-lable">{{::'common.created' | translate}}</span> <span class="creation-date">{{::ctrl.article.creationDate | amDateFormat: 'LL'}}</span>
			</div>
		</div>
		<div class="agent-counts" ng-click="$event.stopPropagation();" ng-show="ctrl.articleCounters">
			<!-- <span ng-if="ctrl.articleCounters.views >= 0" class="count-element views">
				<span class="count-value">{{::ctrl.articleCounters.views | shortNumber}}</span>
				<span class="count-lable">{{::'common.views' | translate}}</span>
			</span> -->
			<span ng-if="ctrl.articleCounters.followers >= 0" class="count-element followers">
				<span class="count-value">{{::ctrl.articleCounters.followers | shortNumber}}</span>
				<span class="count-lable">{{::'common.followers' | translate}}</span>
			</span>
			<span class="count-element linked-tickets" is-ticketing-enabled>
				<a href="{{::ctrl.linkedTicketUrl}}" target="_blank">
					<span class="count-value">{{::ctrl.linkedTicketsCount | shortNumber}}</span>
					<span class="count-lable">{{::'common.linked-tickets' | translate}}</span>
				</a>
			</span>
			<span ng-if="ctrl.articleCounters.helpful >= 0 && (ctrl.article.type === ctrl.ArticleType.ARTICLE)" class="count-element helpful">
				<span class="count-value">{{::ctrl.articleCounters.helpful | shortNumber}}</span>
				<span class="count-lable">{{::'common.helpful' | translate}}</span>
			</span>
			<span ng-if="ctrl.articleCounters.unhelpful >= 0 && (ctrl.article.type === ctrl.ArticleType.ARTICLE)" class="count-element unhelpful">
				<span class="count-value">{{::ctrl.articleCounters.unhelpful | shortNumber}}</span>
				<span class="count-lable">{{::'common.unhelpful' | translate}}</span>
			</span>
			<span ng-if="ctrl.articleCounters.votes >= 0 && (ctrl.article.type === ctrl.ArticleType.FEATURE_REQUEST || ctrl.article.type === ctrl.ArticleType.KNOWN_ISSUE)" class="count-element votes">
				<span class="count-value">{{::ctrl.articleCounters.votes | shortNumber}}</span>
				<span class="count-lable">{{::'common.votes' | translate}}</span>
			</span>
		</div>
	</div>
</aside>

`;
export = templateData;
