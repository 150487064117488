import * as ans from '@wix/answers-api';
import { isFeatureEnabled } from '@wix/answers-experiments';

export const filterCategories = (categories: ans.Category[], filters: Partial<ans.ArticleSearchDTO>) => {

	// yack but somewhere in tests it fell and couldn't find why
	if (!filters) {
		filters = {}
	};

	const excludedCats = filters.excludedCategoryIds || [];
	const onlyCats = filters.categoryIds || [];

	const filterFn = (cat: ans.Category) => {
		if (excludedCats.indexOf(cat.id) !== -1) {
			return false;
		}
		if (onlyCats.length && onlyCats.indexOf(cat.id) === -1) {
			return false;
		}
		return true;
	};

	return categories
		.filter(filterFn)
		.map((cat) => {
			return {
				...cat,
				children: (cat.children || []).filter(filterFn)
			}
		});
};

export const isArticlePermitted = (article: ans.Article, filters: Partial<ans.ArticleSearchDTO>) => {

	// yack but somewhere in tests it fell and couldn't find why
	if (!filters) {
		filters = {}
	};

	const excludeLabels = filters.notHasAnyOfLabelIds || [];
	const mustLabels = filters.hasAllOfLabelIds || [];
	const anyLabels = filters.hasAnyOfLabelIds || [];
	const excludedCategoryIds = filters.excludedCategoryIds || [];

	const labelIds = (article.labels || []).map((l) => l.id);

	const hasExcludedLabel = labelIds.some((id) => excludeLabels.indexOf(id) !== -1);

	const hasAnyLabels = labelIds.some((id) => anyLabels.indexOf(id) !== -1);

	const hasAllLabels = mustLabels.every((id) => labelIds.indexOf(id) !== -1);

	if (hasExcludedLabel) {
		return false;
	}

	if (anyLabels.length && !hasAnyLabels) {
		return false;
	}

	if (mustLabels.length && !hasAllLabels) {
		return false;
	}

	if (excludedCategoryIds.length && excludedCategoryIds.indexOf(article.categoryId) !== -1) {
		return false;
	}

	return true;
};

export const filterArticles = (articles: ans.Article[], filters: Partial<ans.ArticleSearchDTO>) => {
	return articles.filter((article) => isArticlePermitted(article, filters));
};
