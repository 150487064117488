/// <reference path="../../references.ts" />

/**
* Created by Elad_Swissa on 7/15/15.
*/

export default function reverseFilterFactory(): ng.IFilterService {
	return function(items: any) {
		return items.reverse();
	};
}
