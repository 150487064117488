/**
 * Created by Elad_Swissa on 7/23/15.
 */

'use strict';

import * as ans from '@wix/answers-api';
import { isFeatureEnabled } from '@wix/answers-experiments';
import { IHttpResponse } from 'angular';
import Sdk from '../sdk/sdk.srv';

type SearchWithVespa = { searchMethod: string, searchVersion: string } & ans.SearchResult<ans.Article>;
type SearchWithVespaResponse = IHttpResponse<SearchWithVespa>;

class Search {

		/*@ngInject*/
		constructor(private $http: ng.IHttpService, private appConfig, private sdk: Sdk, private $location: ng.ILocationProvider) {}

		articlesSearchApiPath = this.appConfig.apiPath + `/helpcenter/articles/search`;

		vespaEnabledArticlesSearchApiPath = `https://support.wix.com/answers-search-proxy/api/v2/articles/search`;

		public search(text: string, pageSize?: number): ng.IPromise<SearchWithVespaResponse> {
			const isWix = window.location.href.includes('support.wix.com');
			const isVespaEnabled = isFeatureEnabled('EnableVespaInHelpCenter') && isWix && this.sdk.currentLang === 'en';
			const apiUrl = isVespaEnabled ? this.vespaEnabledArticlesSearchApiPath : this.articlesSearchApiPath;
			const contentFilters = this.sdk.getContentFilters();

			const dto = {
				text: text,
				page: 1,
				pageSize: pageSize || 5,
				locale: this.appConfig.lang,
				searchable: true,
				spellcheck: true,
				...contentFilters
			};

			return this.$http.post(apiUrl, isVespaEnabled ? {dto} : dto);
		}
}

export default Search;
