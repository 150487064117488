import { Logger } from '@wix/answers-bi';

// let isLogging = false;

export const shouldForceServerSideRouting = (logger?: Logger): boolean => {
	// @ts-expect-error
	const rawValue = window.__ansExperiments['specs.CcKbArticlePage'];
	const isB = rawValue === 'B' ? true : false;

	// if (!isLogging) {
	// 	isLogging = true;
	// 	setTimeout(() => {
	// 		logger.logEvent({
	// 			type: PublicEventType.AB_TESTING, data: {
	// 				isB: isB,
	// 				petriConducitonValue: rawValue,
	// 			}
	// 		});
	// 		isLogging = false;
	// 	}, 700);
	// }

	return isB;
}
