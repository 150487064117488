/*@ngInject*/
function ticketRateReplyRoutingConfig($stateProvider: ng.ui.IStateProvider) {
	$stateProvider.state('app.ticket-rate-reply', {
		url: '/ticket-rate-reply?token&ticketId&replyId&helpful',
		template: `<div class="ticket-rate-reply">
					<div class="ticket-rate-reply-inner">
						<div class="ticket-rate-reply-content">
							<svg width="93px" height="93px" viewBox="0 0 93 93" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<g id="SVGs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="Voted">
										<g id="feedback" transform="translate(12.000000, 11.000000)" fill="#445868">
											<path d="M50.4791667,18.225 C50.4791667,16.95 49.9652778,15.42 48.9375,14.655 C47.9097222,13.635 46.625,13.125 45.3402778,13.125 L33.5208333,13.125 C36.8611111,3.18 32.1076389,0.4140625 30.0048828,0.4140625 C27.9021267,0.4140625 29.2969097,-0.113894737 26.9101563,5.35351563 C23.5175781,13.125 20.6347656,13.125 17.8472222,14.145 C15.0596788,15.165 14.1943359,14.145 14.1943359,14.145 L14.1943359,44.745 L45.5972222,44.745 L45.8541667,44.745 C48.4236111,44.49 50.4791667,42.705 50.4791667,40.155 C50.4791667,38.88 49.9652778,37.605 48.9375,36.585 C49.9652778,35.565 50.7361111,34.29 50.7361111,32.76 C50.7361111,31.23 50.2222222,29.955 49.1944444,29.19 C50.2222222,28.17 50.7361111,26.895 50.7361111,25.62 C50.7361111,24.09 49.9652778,22.815 48.9375,21.795 C49.9652778,20.775 50.4791667,19.5 50.4791667,18.225 Z" id="Shape"></path>
											<path d="M0.347222222,44.745 C0.347222222,44.745 11.1111111,44.745 11.1111111,44.745 L11.1111111,14 L0.347222222,14 C0.347222222,14 0.347222222,44.745 0.347222222,44.745 Z M3.40277778,19.7169624 C3.40277778,18.1797124 4.6875,17.1548791 5.97222222,17.1548791 C7.51388889,17.1548791 8.54166667,18.4359208 8.54166667,19.7169624 C8.54166667,21.2542124 7.25694444,22.2790458 5.97222222,22.2790458 C4.6875,22.2790458 3.40277778,20.9980041 3.40277778,19.7169624 L3.40277778,19.7169624 Z" id="Shape"></path>
										</g>
										<g id="noun_4489" transform="translate(51.000000, 55.000000)" fill="#3BD466">
											<polygon id="Shape" points="5.11764706 10.4 10.2352941 15.6 23.8823529 0 29 5.2 10.2352941 26 10.2352941 26 10.2352941 26 0 15.6"></polygon>
										</g>
									</g>
								</g>
							</svg>
							<h3 ng-if="ctrl.isHelpful">{{::'public.ticket.rate-reply.positive.title' | translate}}</h3>
							<div ng-if="ctrl.isHelpful">{{::'public.ticket.rate-reply.positive.text' | translate}}</div>
							<h3 ng-if="!ctrl.isHelpful">{{::'public.ticket.rate-reply.negative.title' | translate}}</h3>
							<div ng-if="!ctrl.isHelpful">{{::'public.ticket.rate-reply.negative.text' | translate}}</div>
						</div>
					</div>
				</div>`,
		resolve: {
			rateReply: function($stateParams, appConfig, $http: ng.IHttpService) {
				return $http.post(appConfig.apiPath + `/tickets/${$stateParams.ticketId}/replies/${$stateParams.replyId}/rate`, {
					token: $stateParams.token
				})
				.then((response) => response.data);
			}
		},
		controller: function($stateParams) {
			this.ticketId = $stateParams.ticketId;
			this.isHelpful = $stateParams.helpful === 'true';
		},
		controllerAs: 'ctrl'
	});
}

export default ticketRateReplyRoutingConfig;
