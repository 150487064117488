/// <reference path="../references.ts" />
import Auth from '../auth/auth.srv';

import * as ans from '@wix/answers-api';
import Sdk from './sdk/sdk.srv';
import Search from './article-search/article-search.srv';
import goHomeCounter from './../structure/error-pages/go-home-counter.drv';
import svgIconDirectiveFactory from './svg-icon/svg-icon.drv';
import legacyRedirectsRoutingConfig from './../routing/legacy-redirects.config';
import commonRoutingConfig from './common-routing';
import htmlMinlengthDirectiveFactory from './../submit/submit-a-ticket-page/html-minlength/html-minlength.drv';
import isTicketingEnabledDirectiveFactory from './is-ticketing-enabled/is-ticketing-enabled';
import AnalyticsService from './analytics/analytics.srv';
import TemplateConfigService from './template-config/template-config.srv';
import ticketRateReplyRoutingConfig from './../routing/ticket-rate-reply.config';
import VisibilityService from './services/visibility/visibility.srv';
import CustomFieldsService from './services/custom-fields/custom-fields.srv';
import UploadFileService from './services/upload-file/upload-file.srv';
import BiService from './bi/bi.srv';

require('./sdk/sdk.mdl.ts');
require('ng-error/ng-error');

export const userDisplayName = (user: ans.User) => user.firstName;

angular.module('common', ['core', 'prerender', 'sdk', 'ui.select2', 'ngAnimate', 'angularMoment', 'angular-inview', 'ngError'])
.run(require('./preview-events/preview-events.run'))
.directive('timestamp', require('./timestamp/timestamp.drv').default)
.directive('previewChangeableText', require('./preview-events/preview-changeable-text.drv').default)
.directive('scrollOnFocus', require('./mobile/scroll-on-focus.drv').default)
.directive('userLink', require('./user-link/user-link.drv').default)
.directive('innerLink', require('./inner-link/inner-link.drv').default)
.directive('userAvatar', require('./user-avatar/user-avatar.drv').default)
.directive('htmlText', require('./html-text/html-text.drv').default)
.directive('scrollTo', require('./scroll-to/scroll-to.drv').default)
.directive('loader', require('./loader/loader.drv').default)
.directive('mainLoader', require('./loader/main-loader.drv').default)
.directive('clickOutside', require('./click-outside/click-outside.drv').default)
.directive('hero', require('./hero/hero.drv').default)
.directive('goHomeCounter', goHomeCounter)
.directive('svgIcon', svgIconDirectiveFactory)
.directive('isTicketingEnabled', isTicketingEnabledDirectiveFactory)
.directive('htmlMinLength', htmlMinlengthDirectiveFactory)
.filter('htmlSafe', require('./filters/html-safe.flt').default)
.filter('shortNumber', require('./filters/short-number.flt').default)
.filter('reverse', require('./filters/reverse.flt').default)
.filter('htmlToText', require('./html-text/html-text.fit').default)
.filter('userDisplayName', () => {
	return userDisplayName;
})
.service('articleSearch', Search)
.service('analyticsService', AnalyticsService)
.service('templateService', TemplateConfigService)
.service('visibilityService', VisibilityService)
.service('customFieldsService', CustomFieldsService)
.service('uploadFileService', UploadFileService)
.service('biService', BiService)
.config(ticketRateReplyRoutingConfig)
.config(commonRoutingConfig)
.config(legacyRedirectsRoutingConfig)

.factory('textEditorOptions', function(appConfig: ans.AppConfig, translateFilter, auth: Auth) {
	const isLoggedIn = auth.isLoggedIn();
	const buttons = isLoggedIn ? ['image', 'file'] : [];

	const redactorLangs = ['en', 'it', 'es', 'de', 'fr', 'ja', 'ko', 'pl', 'pt', 'ru', 'he'];

	const lang = redactorLangs.indexOf(appConfig.lang) !== -1 ? appConfig.lang : 'en';
	return {
		lang,
		imageUpload: appConfig.apiPath + '/files',
		fileUpload: appConfig.apiPath + '/files',
		direction: lang === 'he' ? 'rtl' : 'ltr',
		// imageUpload: appConfig.apiUri + '/file/put',
		// fileUpload: appConfig.apiUri + '/file/put',
		fileUploadCallback: function (link, json) {
			if (json.errorCode === ans.Errors.VALIDATION) {
				alert(translateFilter('public.upload.error') + '\n' + translateFilter('public.upload.error.file.type.text'));
				return false;
			}
		},
		imageUploadCallback: function (link, json) {
			if (json.errorCode === ans.Errors.VALIDATION) {
				alert(translateFilter('public.upload.error') + '\n' + translateFilter('public.upload.error.image.type.text'));
				return false;
			} else if (json.extension === 'tiff') {
				link.after($(`<a href="${json.filelink}">${json.filename}</a>`));
				link.remove();
			}
		},
		fileUploadErrorCallback: function (data) {
			alert(translateFilter('public.upload.error') + '\n' + translateFilter('public.upload.error.file.text'));
		},
		imageUploadErrorCallback: function (data) {
			alert(translateFilter('public.upload.error') + '\n' + translateFilter('public.upload.error.image.text'));
		},
		buttons,
		focusCallback: function(e) {
			$(e.target).parent().addClass('focus-editor');
		},
		blurCallback: function(e) {
			$(e.target).parent().removeClass('focus-editor');
		}
	};
})
.factory('$httpPostForm', function($http: ng.IHttpService, $httpParamSerializer) {
	return function (url, data, options) {
		var formData = $httpParamSerializer(data);
		var combinedOptions = angular.extend(options || {}, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
		return $http.post(url, formData, combinedOptions);
	};
});
