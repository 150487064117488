/// <reference path="../../references.ts" />
/// <reference path="../../references.ts" />

import Prerender from './prerender.srv';

/*@ngInject*/
function prerenderRoutingBinder($rootScope, prerender: Prerender) {
	$rootScope.$on('$stateChangeSuccess', function(event, toState) {
		var statusCode = toState.data && toState.data.statusCode;

		if (statusCode) {
			prerender.setStatusCode(statusCode);
		} else if (statusCode === null) {
			prerender.clearStatus();
		}
	});
};

export = prerenderRoutingBinder;
