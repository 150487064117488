/// <reference path="../../../references.ts" />

'use strict';

import * as ans from '@wix/answers-api';
import Sdk from './../../sdk/sdk.srv';
import SdkEventType from './../../sdk/sdk-events.enum';

export default class FollowService {

	/*@ngInject*/
	constructor(private $http: ng.IHttpService,
		private $httpPostForm,
		private appConfig: ans.AppConfig,
		private sdk: Sdk) { }

		public isTicketFollowedByUser(ticket: ans.Ticket): ng.IPromise<boolean> {
			return this.$http.get(`${this.appConfig.apiPath}/tickets/${ticket.id}/follow`).then(res => res.data as boolean);
		}

		public isFollowedByUser(article: ans.Article): ng.IPromise<boolean> {
			return this.$http.get(this.appConfig.apiPath + '/articles/' + article.id + '/follow', {
				params: {
					locale: this.appConfig.lang
				}
			})
			.then((response) => response.data as boolean);
		}

		public followTicket(ticket: ans.Ticket, newStatus: boolean): ng.IPromise<any> {

			this.sdk.triggerEvent(SdkEventType.itemFollow, { item: ticket, newStatus: newStatus });

			var $httpMethod: any = newStatus ? this.$http.post : this.$http.delete;
			return $httpMethod(this.appConfig.apiPath + '/tickets/' + ticket.id + '/follow', {
				locale: this.appConfig.lang
			})
			.then((response) => response.data);
		}

		public followToggle(article: ans.Article, newState: boolean): ng.IPromise<any> {
			this.sdk.triggerEvent(SdkEventType.itemFollow, { item: article, newStatus: newState });
			return (newState ? this.follow(article) : this.unfollow(article));
		}

		public follow(article: ans.Article): ng.IPromise<any> {
			return this.$http.post (this.appConfig.apiPath + '/articles/' + article.id + '/follow', {
				locale: article.locale
			})
			.then((response) => response.data);
		}

		public unfollow(article: ans.Article): ng.IPromise<any> {
			return this.$http.delete(this.appConfig.apiPath + '/articles/' + article.id + '/follow', {
				params: {
					locale: article.locale
				}
			})
			.then((response) => response.data);
		}

		// Category
		public isCategoryFollowedByUser(category: ans.Category): ng.IPromise<boolean> {
			return this.$http.get(this.appConfig.apiPath + '/categories/' + category.id + '/follow', {
				params: {
					locale: this.appConfig.lang
				}
			})
			.then((response) => response.data as boolean);
		}

		public followCategoryToggle(category: ans.Category, newState: boolean): ng.IPromise<any> {
			return (newState ? this.followCategory(category) : this.unfollowCategory(category));
		}

		private followCategory(category: ans.Category): ng.IPromise<any> {
			return this.$http.post (this.appConfig.apiPath + '/categories/' + category.id + '/follow', {
				locale: category.locale
			})
			.then((response) => response.data);
		}

		private unfollowCategory(category: ans.Category): ng.IPromise<any> {
			return this.$http.delete(this.appConfig.apiPath + '/categories/' + category.id + '/follow', {
				params: {
					locale: category.locale
				}
			})
			.then((response) => response.data);
		}
	}
