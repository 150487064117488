/// <reference path="../references.ts" />

'use strict';

import submitTicketRouting from './submit-a-ticket-routing/submit-a-ticket-routing';
import submitTicketPageDirectiveFactory from './submit-a-ticket-page/submit-a-ticket-page.drv';
import SubmitTicketService from './submit-a-ticket-service/submit-a-ticket.srv';
import htmlMinlengthDirectiveFactory from './submit-a-ticket-page/html-minlength/html-minlength.drv';
import legacyContactFormDirectiveFacory from './legacy-contact-form/legacy-contact-form.drv';
import contactFormDirectiveFactory from './contact-form/contact-form.drv';

angular.module('submit-ticket', ['core', 'ui.router', 'common', 'categories', 'seo', 'vcRecaptcha', 'ngFileUpload'])
		.config(submitTicketRouting)
		.service('submitTicketService', SubmitTicketService)
		.directive('legacyContactForm', legacyContactFormDirectiveFacory)
		.directive('contactForm', contactFormDirectiveFactory)
		.directive('submitTicketPage', submitTicketPageDirectiveFactory);
