import * as ans from '@wix/answers-api';
import Prerender from '../common/prerender/prerender.srv';

export default class SeoManager {
	private emptySeoContent: ans.PageSeoData = new ans.PageSeoDataBuilder().build();
	private defaultPlaceholders: {[key: string]: string} = {};

	/*@ngInject*/
	constructor(private $http: ng.IHttpService,
		private $location: ng.ILocationService,
		private $document,
		private prerender: Prerender,
		private appConfig: ans.AppConfig,
		companyName, brandingConfig) {
			this.defaultPlaceholders = {};
			this.defaultPlaceholders[ans.defaultPlaceholderKeys.COMPANY_NAME] = companyName;
			this.defaultPlaceholders[ans.defaultPlaceholderKeys.COMPANY_LOGO] = brandingConfig.logoUrl;
			this.defaultPlaceholders[ans.defaultPlaceholderKeys.CANONICAL_URL] = $location.absUrl();
		}

		public fetchData(pageType: ans.PageType, lang: string): ng.IPromise<ans.PageSeo> {
			const seoApiPath = this.appConfig.apiPath + `/helpcenter/seo/`
			return this.$http.get(seoApiPath + pageType + '/' + lang, {
				params: {
					locale: lang || this.appConfig.lang
				}
			})
			.then((response) => angular.extend({}, this.emptySeoContent, response.data));
		}

		private static renderContentWithPlaceholders(seoContent: ans.PageSeoData, placeholders: {
			[key: string]: string;
		}): ans.PageSeoData {
			var title = seoContent.title;
			var description = seoContent.description;
			var keys = Object.keys(placeholders || {});
			keys.forEach((key) => {
				var val = placeholders[key];
				title = SeoManager.replacePlaceHolder(title, key, val);
				description = SeoManager.replacePlaceHolder(description, key, val);
			});

			return new ans.PageSeoDataBuilder()
			.withTitle(title)
			.withDescription(description)
			.withNoIndex(seoContent.robots.noIndex)
			.withNoArchive(seoContent.robots.noArchive)
			.withNoFollow(seoContent.robots.noFollow)
			.build();
		}

		private static replacePlaceHolder(str, key, val) {
			return str.replace(new RegExp('\\$' + key, 'g'), val);
		}

		private setOrCreateCanonical() {
			var url = this.$location.absUrl();
			var $newTag = $(`<link rel="canonical" href="${url}"></link>`);
			var $head = this.$document.find('head');
			$head.find(`link[rel="canonical"]`).remove();
			$head.append($newTag);
		}

		private setOrCreateMetaTag(name, content) {
			var $newTag = $(`<meta name="${name}"></meta>`);
			$newTag.attr('content', content);
			var $head = this.$document.find('head');
			$head.find(`meta[name="${name}"]`).remove();

			if (content) {
				$head.append($newTag);
			}
		}

		static robotsToContent = (robots: ans.SeoRobotsData): string => {
			let combined = [];

			if (robots.noFollow) {
				combined.push('nofollow');
			}

			if (robots.noArchive) {
				combined.push('noarchive');
			}

			if (robots.noIndex) {
				combined.push('noindex');
			}
			return combined.join(',');
		}

		public setData(seoContent: ans.PageSeoData, placeholders: {[key: string]: string} = {}, forceNoIndex = false) {
			var calculatedPlaceholders = angular.extend({}, placeholders, this.defaultPlaceholders);

			if (forceNoIndex) {
				seoContent.robots.noIndex = true;
			}

			var renderedSeoContent = SeoManager.renderContentWithPlaceholders(seoContent, calculatedPlaceholders);
			this.$document.find('title').text(renderedSeoContent.title);

			this.setOrCreateMetaTag('description', renderedSeoContent.description);
			this.setOrCreateMetaTag('robots', SeoManager.robotsToContent(seoContent.robots));
			this.setOrCreateMetaTag('og:title', renderedSeoContent.title);
			this.setOrCreateMetaTag('og:description', renderedSeoContent.description);
			this.setOrCreateMetaTag('twitter:title', renderedSeoContent.title);
			this.setOrCreateMetaTag('twitter:description', renderedSeoContent.description);
			this.setOrCreateCanonical();

			this.prerender.setPrerenderReady();
		}

		public fetchAndSetData(pageType: ans.PageType, lang: string, placeholders?, forceNoIndex = false): ng.IPromise<ans.PageSeoData> {
			return this.fetchData(pageType, lang)
			.then((seoContent: ans.PageSeoData) => {
				this.setData(seoContent, placeholders, forceNoIndex);
				return seoContent;
			});
		}

		private clearAlternateLanguageMetaTags() {
			var $head = this.$document.find('head');
			$head.find('link[rel=alternate]').remove();
		}

		private createAlternateLangTag(lang, url) {
			return $(`<link rel="alternate" hreflang="${lang}" href="${url}"></link>`);
		}

		public setAlternateLanguageMetaTags(alternateItems: ans.Translation[] = []) {
			this.clearAlternateLanguageMetaTags();
			let $head = $('head');
			let helpCenterUrls = this.appConfig.helpCenterUrls;
			var $tags = [];
			alternateItems.forEach((item ) => {
				if (item.locale === this.appConfig.primaryLang) {
					$tags.push(this.createAlternateLangTag('x-default', item.url));
				}
				$tags.push(this.createAlternateLangTag(item.locale, item.url));
			});
			$tags.forEach($tag => $head.append($tag));
		}

		public setAlternateLanguageMetaTagsByUrl(url: string) {
			this.clearAlternateLanguageMetaTags();
			let appConfig = this.appConfig;
			let $head = $('head');
			url = url.replace(/^\/\//, '\/');
			let $tags = Object.keys(appConfig.helpCenterUrls)
			.map(lang => this.createAlternateLangTag(lang, appConfig.helpCenterUrls[lang] + url));
			$tags.push(this.createAlternateLangTag('x-default', appConfig.helpCenterUrls[appConfig.primaryLang] + url));
			$tags.forEach($tag => $head.append($tag));
		}

		public setAlternateLanguageMetaTagsByCurrentUrl() {
			return this.setAlternateLanguageMetaTagsByUrl(this.$location.url());
		}

		private insertRefTag(type, url) {
			url = this.appConfig.helpCenterUrls[this.appConfig.lang] + url.replace(/^\//, '');
			$('head').append(`<link ref="${type}" href="${url}">`);
		}

		public setNextPageUrl(url) {
			this.insertRefTag('next', url);
		}

		public setPreviousPageUrl(url) {
			this.insertRefTag('prev', url);
		}
	}
