/**
* Created by Elad_Swissa on 7/27/15.
*/

'use strict';
import CategoryService from './../../common/category/category.srv';
import Sdk from './../../common/sdk/sdk.srv';
import AnalyticsService from './../../common/analytics/analytics.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import * as ans from '@wix/answers-api';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import { shouldForceServerSideRouting } from '../../common/experiments';

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

function htmlEncode(str) {
	var entityMap = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		'\'': '&#39;',
		'/': '&#x2F;'
	};

	return String(str).replace(/[&<>"'\/]/g, function (s) {
		return entityMap[s];
	});
}

class SearchPageDirectiveController {

	searchResults: ans.SearchResult<ans.Article>;
	searchTerm: string;
	searchMethod: string;
	searchVersion: string;

	/*@ngInject*/
	constructor(private categoryService: CategoryService, sdk: Sdk, $filter, private analyticsService: AnalyticsService, private $scope, private biService: BiService, private $sanitize) {
		let htmlRegexp = /<[^>]+?>/g;
		if (htmlRegexp.test(this.searchTerm)) {
			this.searchTerm = htmlEncode(this.searchTerm);
		}

		sdk.setViewedPageData(PageType.searchResults, null, {
			results: this.searchResults,
			searchTerm: this.searchTerm,
			searchMethod: this.searchMethod,
			searchVersion: this.searchVersion
		});
		$scope.$watch('$viewContentLoaded', () => {
			this.biService.logEvent(PublicEventType.SEARCH_PAGE_LOAD, {term: this.searchTerm});
		});
	}

	getsearchTerm = (searchTerm: string) => {
		return this.$sanitize(searchTerm);
	};

	getCategoryParents = (categoryId: string) => {
		var category: ans.Category = this.categoryService.categoryMap[categoryId];
		var categoryParents: ans.Category[] = [];
		while (category) {
			categoryParents.unshift(category);
			var parentCategory = this.categoryService.categoryMap[category.parentId];
			category = parentCategory;
		}

		return categoryParents;
	};

	onResultClick(articleId: string, position: number) {
		this.analyticsService.logSearchResultArticleClicked(this.searchTerm, articleId, position);
	}

	shouldForceServerSideRoutingLink() {
		return shouldForceServerSideRouting(this.biService.logger);
	}
}

export default function articlePageDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		template: require('./article-search-page.tpl.ts'),
		scope: {
			searchResults: '=',
			searchTerm: '=',
			searchVersion: '=',
			searchMethod: '=',
		},
		restrict: 'E',
		controllerAs: 'page',
		bindToController: true,
		controller: SearchPageDirectiveController
	};
}
