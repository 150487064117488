'use strict';
import Sdk from './sdk.srv';
import SdkEventType from './sdk-events.enum';

/*@ngInject*/
export default function sdkEventTriggerDirectiveFactory(sdk: Sdk): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		bindToController: true,
		link: function (scope: ng.IScope, elem, attr: {sdkEventTrigger: string; sdkEventData: string; }) {
			var eventType: SdkEventType = SdkEventType[attr.sdkEventTrigger];
			elem.on('click', () => {
				var data = scope.$eval(attr.sdkEventData) || {};
				sdk.triggerEvent(eventType, data);
			});
		}
	};
}
