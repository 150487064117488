import Auth from './../../auth/auth.srv';
import {templateConfig} from './template-config';
import * as ans from '@wix/answers-api';


export default class TemplateConfigService {

	private templateKey = null;

	/*@ngInject*/
	constructor(private brandingConfig: ans.BrandingConfig,
		private guestContactFormEnabled: boolean,
		private auth: Auth,
		private $window) {
		this.templateKey = this.brandingConfig.templateKey;
	}

	public isMobile() {
		return this.$window.innerWidth < 767;
	}

	private getTemplateConfig() {
		if (this.isMobile()) {
			return templateConfig(this.templateKey).mobile;
		} else {
			return templateConfig(this.templateKey).desktop;
		}
	}

	public enableConditionalLinksInHome() {
		return this.getTemplateConfig().disableParentCategoryLinks;
	}

	public ignoreStickyElementsList(classes: string) {
		const classNames = classes.split(' ');
		const ignoreElements = this.getTemplateConfig().ignoreStickyElementsList;
		const ignoreSticky = classNames.filter(_className => ignoreElements.indexOf(_className) > -1).length === 0;
		return ignoreSticky;
	}

	public showSubCategoryArticlesInHome() {
		return this.getTemplateConfig().showSubCategoryArticlesInHome;
	}

	public showCollapsibleCategoryMenu() {
		return this.getTemplateConfig().showCollapsibleCategoryMenu;
	}

	public showAllCategoryTypes() {
		return this.getTemplateConfig().showAllCategoryTypes;
	}

	public showCategoryDropdownMenu() {
		return this.getTemplateConfig().showCategoryDropdownMenu;
	}

	public showCategoryBreadcrumbs() {
		return this.getTemplateConfig().showCategoryBreadcrumbs;
	}

	public showBounceCategoryMenu() {
		return this.getTemplateConfig().showBounceCategoryMenu;
	}

	public categoriesPageSize() {
		return this.getTemplateConfig().categoriesPageSize;
	}

	public useNewScrollingLogic() {
		return this.getTemplateConfig().useNewScrollingLogic;
	}
	public showCategoryTypeAnchorMenu() {
		return this.getTemplateConfig().showCategoryTypeAnchorMenu;
	}
}
