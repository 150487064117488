/**
* Created by Elad_Swissa on 7/20/15.
*/

'use strict';

import * as ans from '@wix/answers-api';
import ItemRatingActionType from './item-rating-action-type.enum';
import Sdk from './../../common/sdk/sdk.srv';
import SdkEventType from './../../common/sdk/sdk-events.enum';
import AnalyticsService from './../../common/analytics/analytics.srv';
import HelpfulState from './helpful-state.enum';
import { safelyRemoveFromLocalStorage, safelySetFromLocalStorage, safelyGetFromLocalStorage } from '../../common/local-storage';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';

export default class ItemRatingService {

	/*@ngInject*/
	constructor(private $http: ng.IHttpService,
		private $httpParamSerializer,
		private appConfig: ans.AppConfig,
		private $window,
		private sdk: Sdk,
		private biService: BiService,
		private analyticsService: AnalyticsService) {}

		public voteItemToggle (item: ans.Article, newValue: boolean) {
			this.sdk.triggerEvent(SdkEventType.itemVoted , {item: item, status: newValue});
			return (newValue) ? this.voteItem(item) : this.unvoteItem(item);
		}

		private voteItem (item: ans.Article) {
			var data = {
				locale: item.locale,
				sourceType: this.analyticsService.sourceType,
				sourceId: this.analyticsService.sourceId
			};
			const eventType = item.type === ans.ArticleType.FEATURE_REQUEST ? PublicEventType.VOTE_FOR_FEATURE
				: item.type === ans.ArticleType.KNOWN_ISSUE ? PublicEventType.VOTE_KNOWN_ISSUE : null;
			if (eventType) {
				this.biService.logEvent(eventType, {article: item});
			}
			return this.$http.post(this.appConfig.apiPath + '/articles/' + item.id + '/vote', data);
		}

		private unvoteItem (item: ans.Article) {
			const eventType = item.type === ans.ArticleType.FEATURE_REQUEST ? PublicEventType.UNVOTE_FOR_FEATURE
				: item.type === ans.ArticleType.KNOWN_ISSUE ? PublicEventType.UNVOTE_KNOWN_ISSUE : null;
			if (eventType) {
				this.biService.logEvent(eventType, {article: item});
			}
			return this.$http.delete(this.appConfig.apiPath + '/articles/' + item.id + '/vote', {
				params: {
					locale: item.locale,
					sourceType: this.analyticsService.sourceType,
					sourceId: this.analyticsService.sourceId
				}
			});
		}

		public rateItem (item: ans.Article, newRating: boolean, oldRating: boolean) {
			var promiseToReturn;
			var params = {
				newState: this.getHelpfulStateFromRating(newRating),
				state: this.getHelpfulStateFromRating(oldRating),
				locale: item.locale,
				sourceType: this.analyticsService.sourceType,
				sourceId: this.analyticsService.sourceId
			};
			promiseToReturn = this.$http.post(this.appConfig.apiPath + '/articles/' + item.id + '/helpful', params);
			this.sdk.triggerEvent(SdkEventType.itemRated, {item: item, newRating: newRating, oldRating: oldRating});
			this.biService.logEvent(
				newRating ? PublicEventType.ARTICLE_WAS_HELPFUL : PublicEventType.ARTICLE_WAS_NOT_HELPFUL,
				{article: item}
			);
			return promiseToReturn;
		}

		private getHelpfulStateFromRating(rating: boolean) {
			var helpfulState: HelpfulState;
			switch (rating) {
				case true:
				helpfulState = HelpfulState.HELPFUL;
				break;
				case false:
				helpfulState = HelpfulState.NOT_HELPFUL;
				break;
				default:
				helpfulState = HelpfulState.NONE;
			}
			return helpfulState;
		}

		public storeItemData (item: ans.Article, state: boolean): void {
			if (state === null) {
				safelyRemoveFromLocalStorage(this.$window, item.id + item.locale);
			} else {
				safelySetFromLocalStorage(this.$window, item.id + item.locale, state + '');
			}
		}

		public getItemData (item: ans.Article): boolean {
			var state = safelyGetFromLocalStorage(this.$window, item.id + item.locale);
			return (state === 'true') ? true : (state === 'false' ? false : null);
		}
	}
