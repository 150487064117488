import CategoryService from './../../common/category/category.srv';
import TemplateConfigService from './../../common/template-config/template-config.srv';
import UserToolbarService from '../../structure/user-toolbar/user-toolbar.srv';

import * as ans from '@wix/answers-api';

class CategoryPageNavDirectiveController {
	tree: ans.Category[];
	category: ans.Category;
	showParent: boolean;
	parentCategory: ans.Category;
	showCollapsibleCategoryMenu: boolean;
	showCategoryDropdownMenuHelper: boolean;
	showCategoryDropdownMenu: boolean;
	showBounceCategoryMenu: boolean;
	currentCategory: ans.Category;
	currentParentCategory: ans.Category;
	hasUserToolbar: boolean;

	/*@ngInject*/
	constructor(private categoryService: CategoryService, private $state: ng.ui.IStateService, private $rootScope, private $scope, private $location, private templateService: TemplateConfigService, private userToolbarService: UserToolbarService) {
		this.tree = categoryService.categoryTree;
		this.showCollapsibleCategoryMenu = this.templateService.showCollapsibleCategoryMenu();
		this.showCategoryDropdownMenuHelper = this.templateService.showCategoryDropdownMenu();
		this.showBounceCategoryMenu = this.templateService.showBounceCategoryMenu();
		this.hasUserToolbar = this.$rootScope.showUserToolbar;

		if (this.category) {
			let parentCategory = this.categoryService.getCachedCategoryById(this.category.parentId);
			if (parentCategory) {
				this.parentCategory = parentCategory;
				this.showParent = false;
			} else {
				this.showParent = true;
			}
		}

		$scope.$on('category-name-clicked', (_, args) => {
			args.event.preventDefault();
			args.event.stopPropagation();
			if (this.templateService.isMobile()) {
				this.openCatDropdownMenu();
			} else {
				this.navigate(args.parent);
			}
		});

		$scope.config = {
			autoHideScrollbar: true,
			theme: 'minimal-dark',
			axis: 'y',
			advanced: {
				updateOnSelectorChange: 'section',
				autoUpdateTimeout: 1
			}
		};



		if (this.templateService.useNewScrollingLogic()) {
			$scope.$watch('$viewContentLoaded', () => {
				this.setNavContainerHeight();
			});

			$rootScope.$on('$stateChangeSuccess', () => {
				this.setNavContainerHeight();
				$('.categories-nav-container').css({'height': ''});
			});
		}
	}

	navigate(category: ans.Category) {

		// toggle cat dropdown menu expand
		if (this.showCategoryDropdownMenu) {
			const changeCat = this.category ? this.category.id !== category.id && !category.children.length : (this.parentCategory ? this.parentCategory.id !== category.id && !category.children.length : false);

			if (!this.currentCategory && !this.currentParentCategory) {
				this.saveCurrectCategories();
			}

			if (this.category && this.parentCategory && category.children.length) {

				if (this.parentCategory.id === category.id) {
					this.parentCategory = null;
					this.category = null;
				} else {
					this.parentCategory = category;
					this.category = category;
				}

			} else {
				if (category.children && category.children.length) {
					this.parentCategory = category;
					this.showParent = false;
				}

				if (!changeCat && this.currentParentCategory && this.parentCategory && this.parentCategory.id === this.currentParentCategory.id) {
					this.loadCurrentCategories();

					if (category.id === this.parentCategory.id) {
						this.category = category;
					}
				} else {
					this.category = category;

					if (!category.children.length && this.parentCategory && category.parentId !== this.parentCategory.id) {
						this.parentCategory = null;
					}

					if (changeCat) {
						this.$location.path(this.category.uri);
						this.showCategoryDropdownMenu = false;

						this.saveCurrectCategories();
					}
				}

			}
		} else {
			if (category.children && category.children.length) {
				this.parentCategory = category;
				category = category.children[0];
				this.showParent = false;
			}

			this.category = category;
			this.$location.path(category.uri);
		}
	}

	allTopics() {
		this.showParent = true;

		let element = $('.current-category-title');
		if (element.hasClass('sticky')) {
			element.removeClass('sticky');
			element.css({top: ''});
		}

		this.setNavContainerHeight();
	}

	openCatDropdownMenu() {
		if (this.showCategoryDropdownMenuHelper) {
			this.showCategoryDropdownMenu = true;
		}
	}

	closeCatDropdownMenu() {
		this.showCategoryDropdownMenu = false;
		this.loadCurrentCategories();
	}

	saveCurrectCategories() {
		this.currentCategory = this.category || this.currentCategory;
		this.currentParentCategory = this.parentCategory;
	}

	loadCurrentCategories() {
		const parentHasChildren = this.currentParentCategory && this.currentParentCategory.children && this.currentParentCategory.children.length > 0;
		this.category = this.currentCategory || this.category;
		this.parentCategory = parentHasChildren ? this.currentParentCategory : this.category;
	}

	setNavContainerHeight() {
		setTimeout(function() {
			const windowHeight = $(window).height();
			const articlesHeight = $('.article-list').height();
			const footerHeight = $('main-footer').height();
			let headerHeight = $('#main-header').height() || $('#custom-header').height() || 0;

			let finalHeaderHeight = this.hasUserToolbar ? headerHeight + 10 : headerHeight;


			const initialNavHeight = articlesHeight > (windowHeight - finalHeaderHeight) ? windowHeight - finalHeaderHeight : articlesHeight < 550 ? 550 : articlesHeight;

			$('.categories-nav-container').css({'max-height': initialNavHeight + 'px'});
		}, 1);
	}

}

/*@ngInject*/
export default function categoryPageNavDirectiveFactory($rootScope: ng.IRootScopeService, templateService: TemplateConfigService, userToolbarService: UserToolbarService): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			category: '='
		},
		template: require('./category-page-nav.tpl.ts'),
		controller: CategoryPageNavDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true,
		link: function(scope: ng.IScope, element: JQuery, attr: {fixedHeight: boolean, stickyBottomSelector: string}) {
			if (templateService.useNewScrollingLogic()) {
				$(window).scroll(function() {
					const windowHeight = $(window).height();
					const hasUserToolbar = ($rootScope as any).showUserToolbar;

					let headerHeight = $('#main-header').height() || $('#custom-header').height() ||  0;
					let finalHeaderHeight = hasUserToolbar ? headerHeight + 10 : headerHeight;
					let windowScrollTop = $(window).scrollTop();
					let footerTop = $('main-footer').offset().top;

					const footerBottomOffset = windowScrollTop + windowHeight - footerTop;

					if (footerBottomOffset > 0) {
						element.find('.categories-nav-container').css('height', (windowHeight - finalHeaderHeight - footerBottomOffset - 5) + 'px');
					} else {
						element.find('.categories-nav-container').css('height', '');
					}
				});
			}
		}
	};
}
