/// <reference path="../../references.ts" />

import * as ans from '@wix/answers-api';
import SeoManager from './../../seo/seo-manager.srv';
import Auth from './../../auth/auth.srv';
import SubmitTicketService from './../submit-a-ticket-service/submit-a-ticket.srv';
import Tickets from './../../ugc/ticket-service/tickets.srv';
import TemplateConfigService from './../../common/template-config/template-config.srv';
import VisibilityService from './../../common/services/visibility/visibility.srv';
import { AppConfig } from '@wix/answers-api';

'use strict';

/*@ngInject*/
function submitTicketRouting($stateProvider: ng.ui.IStateProvider) {
	$stateProvider.state('app.submit', {
		url: '/contact?title&content&categoryId&private',
		template: '<submit-ticket-page new-ticket-title="submit.title" new-ticket-content="submit.content" new-ticket-is-private="submit.isPrivate" selected-category-id="::submit.selectedCategoryId" user-open-tickets="::submit.userOpenTickets"></submit-ticket-page>',
		resolve: {
			userOpenTickets: function(auth: Auth, submitTicketService: SubmitTicketService, visibilityService: VisibilityService, $state: ng.ui.IStateService, $q: ng.IQService, contactFormEnabled: boolean, $timeout) {
				if (visibilityService.isTicketingVisible() && contactFormEnabled) {
					return auth.init()
					.then(_ => {
						return auth.isLoggedIn() ? submitTicketService.getUserOpenTickets(auth.getCurrentUser().id) : null;
					});
				} else {
					return new $q((_, rej) => {
						rej('404');
					});
				}
			}
		},
		controller: function($stateParams, userOpenTickets) {
			this.title = $stateParams.title;
			this.content = $stateParams.content;
			this.isPrivate = $stateParams.private === 'true';
			this.selectedCategoryId = $stateParams.categoryId;
			this.userOpenTickets = userOpenTickets ? userOpenTickets.items : [];
		},
		controllerAs: 'submit'
	});
}

export default submitTicketRouting;
