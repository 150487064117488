/// <reference path="../references.ts" />

'use strict';
import {WixResponseInterceptor} from '@wix/answers-api';
import legacyRedirectsRoutingConfig from './../routing/legacy-redirects.config';

angular.module('core', ['answersTranslations'])
	.service('wixResponseInterceptor', ['$q', WixResponseInterceptor])

	.config(function ($httpProvider: ng.IHttpProvider) {
		$httpProvider.interceptors.push('wixResponseInterceptor');
	});
