import Auth from './../../../auth/auth.srv';
import * as ans from '@wix/answers-api';

export default class VisibilityService {

	private templateKey = null;

	/*@ngInject*/
	constructor(private brandingConfig: ans.BrandingConfig,
		private guestContactFormEnabled: boolean,
		private auth: Auth) {
	}


	public isTicketingVisible() {
		return true;
	}
}
