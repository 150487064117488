import ArticleService from './../article-service/article.srv';
import * as ans from '@wix/answers-api';

class ArticleInfoDirectiveController {

	article: ans.Article;
	articleCounters: ans.Counters;
	linkedTicketsCount: number;
	showArticleInfo: boolean = false;
	getCountersProcess: boolean = false;
	showDebugData: boolean = false;
	linkedTicketUrl: string;
	ArticleType;

	/* @ngInject */
	constructor(private translateFilter, private dateFilter, private articleService: ArticleService, appConfig: ans.AppConfig) {
		this.linkedTicketUrl = `${appConfig.appUrl}/${ans.appUrlsMapping.helpDeskBase}/tickets?relatedArticleIds=${this.article.id}`;
		this.ArticleType = ans.ArticleType;
	}

	getArticleCounters() {
		if (!this.articleCounters && !this.getCountersProcess) {
			this.getCountersProcess = true;
			this.articleService.getArticleItemCounters(this.article)
			.then((counters: ans.Counters) => {
				this.articleCounters = counters;
			})
			.catch(() => {
				this.getCountersProcess = false;
			});
		}
	}
}

export default function articleInfoDirectiveFactory(): ng.IDirective {
	return <ng.IDirective> {
		restrict: 'E',
		scope: {
			article: '=',
			linkedTicketsCount: '='
		},
		template: require('./article-info.tpl.ts'),
		controller: ArticleInfoDirectiveController,
		controllerAs: 'ctrl',
		bindToController: true
	};
}
