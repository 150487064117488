
/* tslint:disable */
const templateData = `
<div class="category-selector" ng-repeat="categoryLevel in categorySelection.categoryLevels">
	<ng-form name="selectCatecory">
		<select ui-select2 ng-model="categorySelection.currentLevelSelectedCategoryId[$index]"
		ng-class="{error: categorySelection.showErrors && selectCatecory.category.$invalid}"
		ng-change="categorySelection.categorySelected($index)"
		data-placeholder="{{::'public.common.select.category.placeholder' | translate}}" name="category" required="">
			<option></option>
			<option class="select-category-select" ng-repeat="category in categoryLevel" ng-if="category.id" value="{{category.id}}">{{category.name}}</option>
		</select>
		<div class="error-row">
			<div class="submit-ticket-error" ng-show="categorySelection.showErrors && selectCatecory.category.$error.required">{{::'public.common.select.category.error' | translate}}</div>
		</div>
	</ng-form>
</div>

`;
export = templateData;
