/*@ngInject*/
export default function htmlMinlengthDirectiveFactory(htmlToTextFilter): ng.IDirective {
	return <ng.IDirective> {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, elem, attr: any, ngModelController: any) {

			const minLength = parseInt(attr.htmlMinLength, 10);
			ngModelController.$parsers.unshift(function(value) {
				var valid = htmlToTextFilter(value).length >= minLength;
				ngModelController.$setValidity('htmlMinLength', valid);
				return value;
			});
		}
	};
}
