import UserToolbarService from '../user-toolbar/user-toolbar.srv';
import Auth from './../../auth/auth.srv';
import * as ans from '@wix/answers-api';
import BrandingConfig from './../branding/branding.i';
import Sdk from './../../common/sdk/sdk.srv';
import TemplateConfigService from '../../common/template-config/template-config.srv';
import { isFeatureEnabled } from '@wix/answers-experiments';
import { Article } from '@wix/answers-api';

class HeaderDirectiveController {
	isUserLoggedIn: boolean;
	isUserAgent: boolean = false;
	currentLang: string;
	supportedLangs: string[];
	helpCenterUrls;
	tenantLogo: string;
	logoTargetUrl: string;
	appUrl: string;
	showAllLang: boolean = false;
	user: ans.User;
	trustedCustomHeader: string;
	stateName: string;
	isAtHome: boolean;
	enableHeaderLogin: boolean;
	showUserToolbar: boolean = false;
	isLightTheme: boolean;
	isDarkTheme: boolean;
	isIe: boolean;

	publicAgentCheckUrl: string;
	trustedAgentCheckUrl: any;

	localeUrlMap: {[locale: string]: string} = {};

	// testSrc: any;

	/*@ngInject*/
	constructor(private $window: Window, private $scope: ng.IScope, private auth: Auth, private userToolbarService: UserToolbarService, private appConfig: ans.AppConfig, brandingConfig: BrandingConfig, supportedLangs, enableHeaderLogin, private customHeader, $sce: ng.ISCEService, private sdk: Sdk, private $state: ng.ui.IStateService, private $rootScope: ng.IRootScopeService, private templateService: TemplateConfigService, private $timeout) {
		this.supportedLangs = supportedLangs;
		this.currentLang = appConfig.lang;
		this.enableHeaderLogin = enableHeaderLogin;
		this.isUserLoggedIn = auth.isLoggedIn();
		this.isUserAgent = auth.isAgent();
		this.helpCenterUrls = appConfig.helpCenterUrls;
		this.tenantLogo = brandingConfig.logoUrl;
		this.logoTargetUrl = brandingConfig.logoTargetUrl || '';
		this.appUrl = appConfig.appUrl;
		this.isLightTheme = brandingConfig.headerFooterStyle === ans.HeaderFooterStyle.LIGHT;
		this.isDarkTheme = brandingConfig.headerFooterStyle === ans.HeaderFooterStyle.DARK;

		this.publicAgentCheckUrl = `https://${this.appConfig.defaultHost}/agent-check`;
		this.trustedAgentCheckUrl = $sce.trustAsResourceUrl(this.publicAgentCheckUrl);
		this.isIe = /Trident|MSIE/.test(window?.navigator?.userAgent);


		if (!templateService.isMobile()) {
			this.checkUserAgainstIframe(this.$window, this.$scope);
		} else {
			this.showUserToolbar = this.isUserAgent && !templateService.isMobile();
		}

		this.trustedCustomHeader = $sce.trustAsHtml(customHeader);

		$scope.$on(ans.PreviewApiActions.changeLogoUrl, (e, data) => {
			$scope.$apply(() => this.tenantLogo = data);
		});

		$scope.$on(ans.PreviewApiActions.changeHeaderFooterStyle, (e, data) => {
			$scope.$apply(() => {
				this.isLightTheme = data === ans.HeaderFooterStyle.LIGHT;
				this.isDarkTheme = data === ans.HeaderFooterStyle.DARK;
			});
		});

		$scope.$on('article-visited', (event, article: Article) => {
			this.localeUrlMap = (article.translations || []).reduce((acc, curr) => {
				acc[curr.locale] = curr.url;
				return acc;
			}, {});
		});

		this.stateName = $state.current.name;

		$rootScope.$on('$stateChangeStart', (event, data) => {
			this.stateName = data.name;
			this.setIsAtHome();
			this.localeUrlMap = {};
		});

		this.setIsAtHome();
	}

	checkUserAgainstIframe($window, $scope) {
		const agentCheckIframe = $('#agent-check-iframe').get()[0] as HTMLIFrameElement;

		if (agentCheckIframe) {
			agentCheckIframe.addEventListener('load', () => {
				agentCheckIframe.contentWindow.postMessage('getIsAgent', '*');
			});
		}

		$window.addEventListener('message', (event) => {
			const isValidOrigin = event.origin === `https://${this.appConfig.defaultHost}`;
			const data = event.data;
			const hasAgentData = data && (typeof data.isAgent === 'boolean');

			if (isValidOrigin && hasAgentData) {
				$scope.$apply(() => {
					this.showUserToolbar = data.isAgent;
				});

				if (!!this.showUserToolbar) {
					this.$rootScope.$apply(() => {
						(this.$rootScope as any).isToolbarExpanded = !!this.showUserToolbar;
						(this.$rootScope as any).showUserToolbar = !!this.showUserToolbar
					});

					this.userToolbarService.safelyRerenderToolbar();
				}
			}
		});
	}

	setIsAtHome() {
		this.isAtHome = this.stateName !== 'app.home';
	}

	triggerAppReady() {
		this.sdk.triggerAppReady();
	}

	signin() {
		return this.auth.goToSignin();
	}

	signup() {
		return this.auth.goToSignup();
	}

	logout() {
		return this.auth.goToSignout();
	}

}

function headerDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		template: require('./header.tpl.ts'),
		scope: {
			user: '='
		},
		restrict: 'E',
		controllerAs: 'header',
		bindToController: true,
		controller: HeaderDirectiveController
	};
}

export default headerDirectiveFactory;
