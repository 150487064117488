import SeoManager from '../seo/seo-manager.srv';
import Prerender from './prerender/prerender.srv';

/*@ngInject*/
export default function commonRoutingConfig($stateProvider: ng.ui.IStateProvider) {
	$stateProvider
		//oops, something went wrong
	.state('app.error', {
		params: {
			error: 'N/A'
		},
		data: {
			statusCode: 500
		},
		template: require('../structure/error-pages/500.tpl.ts'),
		controller: function($stateParams, $scope, $state) {
			$scope.error = $stateParams.error;
			if ($scope.error === '404') {
				$state.go('app.404');
			} else if ($scope.error === '403') {
				$state.go('app.403');
			}
		}
	})
	.state('app.404', {
		data: {
			statusCode: 404
		},
		template: require('../structure/error-pages/404.tpl.ts'),
		controller: function (prerender: Prerender) {
			prerender.set404();
			prerender.setPrerenderReady();
		}
	})
	.state('app.403', {
		data: {
			statusCode: 403
		},
		template: require('../structure/error-pages/403.tpl.ts'),
		controller: function (prerender: Prerender) {
			prerender.set403();
			prerender.setPrerenderReady();
		}
	});
}
