/// <reference path="../../references.ts" />

'use strict';

import UsersService from '../users.srv';
import Tickets from '../../ugc/ticket-service/tickets.srv';
import * as ans from '@wix/answers-api';
import Auth from '../../auth/auth.srv';
import FollowService from './../../common/services/follow/follow.srv';
import Sdk from './../../common/sdk/sdk.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';

class UserPageController {

	user: ans.User;
	isSameUser: boolean;
	ticketsPage: number = 1;
	followedContentPage: number = 1;
	userTickets: ans.SearchResult<ans.Ticket>;
	followedContent: ans.SearchResult<ans.Article>;

	/*@ngInject*/
	constructor(private auth: Auth,
							private tickets: Tickets,
							private $window: Window,
							$scope,
							private followService: FollowService,
							private sdk: Sdk,
							private biService: BiService,
							private usersService: UsersService) {
		var currentUser = auth.getCurrentUser();

		if (auth.isLoggedIn() && currentUser && this.user.id === currentUser.id) {
			this.isSameUser = true;
		} else {
			this.isSameUser = false;
		}

		this.sdk.setViewedPageData(PageType.userProfile, this.user.id);
		$scope.$watch('$viewContentLoaded', () => {
			this.biService.logEvent(PublicEventType.USER_PAGE_LOAD, {});
		});
	}

	viewMoreTickets() {
		this.tickets.searchByUser(this.user.id, ++this.ticketsPage)
		.then((tickets) => this.userTickets.items = this.userTickets.items.concat(tickets.items));
	}

	viewMoreFollowedContent() {
		this.usersService.getUserFollowedContent(this.user.id, ++this.followedContentPage)
		.then((content) => this.followedContent.items = this.followedContent.items.concat(content.items));
	}

	unfollowItem(item: ans.Article) {
		this.followService.followToggle(item, false)
		.then((data) => {
			var index = this.followedContent.items.indexOf(item);
			this.followedContent.items.splice(index, 1);
			this.followedContent.itemsCount--;
		});
	}
}

export default function userPageDirectiveFactory(): ng.IDirective {
	return {
		template: require('./user-page.tpl.ts'),
		scope: {
			userTickets: '=',
			user: '=',
			followedContent: '='
		},
		restrict: 'E',
		controllerAs: 'page',
		bindToController: true,
		controller: UserPageController
	};
}
