/**
* Created by Elad_Swissa on 7/21/15.
*/
/// <reference path="../../references.ts" />
'use strict';

import ArticleService from '../article-service/article.srv';
import * as ans from '@wix/answers-api';
import Prerender from '../../common/prerender/prerender.srv';
import SeoManager from '../../seo/seo-manager.srv';
import CategoryService from './../../common/category/category.srv';
import AnalyticsService from './../../common/analytics/analytics.srv';
import {htmlToText} from './../../common/html-text/html-text.fit';
import Sdk from '../../common/sdk/sdk.srv';
import { isArticlePermitted, filterArticles } from '../../common/content-filters/index';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';

const articleUri = '/article/';

/*@ngInject*/
export default function articleRoutingConfig($stateProvider: ng.ui.IStateProvider) {
	$stateProvider.state('app.article', {
		url: articleUri + ':articleUri',
		template: '<article-page article="articlePage.article" related-items="articlePage.relatedItems"></article-page>',
		resolve: {
			articleData: function(articleService: ArticleService, $stateParams, $q: ng.IQService, $state: ng.ui.IStateService, appConfig, prerender: Prerender, $location: ng.ILocationService, $window: Window, seoManager: SeoManager, categoryService: CategoryService, $http: ng.IHttpService, analyticsService: AnalyticsService, sdk: Sdk, biService: BiService, $rootScope: ng.IRootScopeService) {
				return articleService.get($stateParams.articleUri)
				.then((article) => {
					let promises = [];
					let articleCategory: ans.Category = categoryService.getCachedCategoryById(article.categoryId);
					let articleParentCategory = articleCategory ? categoryService.getCachedCategoryById(articleCategory.parentId) : null;

					const placeholderKeys = ans.pagePlaceholderKeys.ARTICLE;
					let seoPlaceholders = {};
					seoPlaceholders[placeholderKeys.ARTICLE_TITLE] = article.title;
					seoPlaceholders[placeholderKeys.ARTICLE_PREVIEW] = htmlToText(article.content).slice(0, 160);
					seoPlaceholders[placeholderKeys.CATEGORY_TITLE] = (articleCategory) ? articleCategory.name : '';
					seoPlaceholders[placeholderKeys.PARENT_CATEGORY_TITLE] = (articleParentCategory) ? articleParentCategory.name : '';

					const isFilteredInSdk = !isArticlePermitted(article, sdk.getContentFilters());
					var forceNoIndex = !!article.excludedFromHelpCenterContentScope || isFilteredInSdk;
					seoManager.fetchAndSetData(ans.PageType.ARTICLE, article.locale, seoPlaceholders, forceNoIndex);

					if (articleUri + $stateParams.articleUri !== article.uri) {
						if (prerender.isPrerenderMode()) {
							prerender.setPermanentRedirect(article.url);
						}
						$location.path(article.uri).replace();
					}

					// quick hack to make https://jira.wixpress.com/browse/WA-8257 work. Timeout is for a race condition in first load
					setTimeout(() => {
						$rootScope.$broadcast('article-visited', article);
					}, 1500);

					seoManager.setAlternateLanguageMetaTags(article.translations || []);
					promises.push(articleService.getRelatedItems(article.id, article.locale));
					return $q.all(promises).then((allData) => {
						return {
							article: article,
							relatedItems: filterArticles(allData[0] || [], sdk.getContentFilters())
						};
					});
				})
				.catch((res) => {
					biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'article', reason: `${res.status}`, url: $location.absUrl()}});
					switch (res.status) {
						case ans.HttpStatus.NOT_FOUND:
							$state.go('app.article-not-found');
							break;
						default:
							$state.go('app.error', {error: res});
							break;
					}
				});
			},
		},
		controller: function($http, articleData, analyticsService: AnalyticsService) {
			this.article = articleData.article;
			this.relatedItems = articleData.relatedItems;
			analyticsService.logPageView(this.article.id);
		},
		controllerAs: 'articlePage'
	})
	.state('app.kb-id-dispatcher', {
		url: '/articles/id/:id?locale',
		resolve: {
			article: function ($stateParams, articleService: ArticleService, appConfig: ans.AppConfig) {
				return articleService.getById($stateParams.id, $stateParams.locale || appConfig.lang, true);
			}
		},
		onEnter: function (article, appConfig, $window: any) {
			$window.location.href = article.url + location.hash;
		}
	})
	.state('app.kb', {
		url: '/kb/:kbUri',
		onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
			if (prerender.isPrerenderMode()) {
				var correctUrl = appConfig.helpCenterUrls[appConfig.lang] + '/article/' + $stateParams.kbUri;
				prerender.setPermanentRedirect(correctUrl);
			} else {
				$state.go('app.article', {'articleUri': $stateParams.kbUri}, {location: 'replace'});
			}
		}
	})
	.state('app.articles-redirect', {
		url: '/articles/:articlesUri',
		onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
			if (prerender.isPrerenderMode()) {
				var correctUrl = appConfig.helpCenterUrls[appConfig.lang] + '/article/' + $stateParams.articlesUri;
				prerender.setPermanentRedirect(correctUrl);
			} else {
				$state.go('app.article', {'articleUri': $stateParams.articlesUri}, {location: 'replace'});
			}
		}
	})
	.state('app.zendesk-articles-redirect', { //links from zendesk come like that..
		url: '/hc/:langPart/articles/:uri',
		onEnter: function($stateParams, $window, prerender: Prerender, appConfig) {
			const lang = $stateParams.langPart.split('-')[0];
			const correctUrl = appConfig.helpCenterUrls[lang] + '/article/' + $stateParams.uri;
			prerender.setPermanentRedirect(correctUrl);
			$window.location.href = correctUrl;
		}
	})
	.state('app.kb-1', {
		url: '/:category/kb/:kbUri',
		onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
			var correctUrl = appConfig.helpCenterUrls[appConfig.lang] + '/article/' + $stateParams.kbUri;
			prerender.setPermanentRedirect(correctUrl);
			$state.go('app.article', {'articleUri': $stateParams.kbUri}, {location: 'replace'});
		}
	})
	.state('app.kb-2', {
		url: '/:main/:sub/kb/:kbUri',
		onEnter: function($state, $stateParams, prerender: Prerender, appConfig) {
			var correctUrl = appConfig.helpCenterUrls[appConfig.lang] + '/article/' + $stateParams.kbUri;
			prerender.setPermanentRedirect(correctUrl);
			$state.go('app.article', {'articleUri': $stateParams.kbUri}, {location: 'replace'});
		}
	})
	.state('app.article-not-found', {
		onEnter: function ($state) {
			$state.go('app.404');
		}
	});
}
