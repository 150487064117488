import { AppConfig } from '@wix/answers-api';

function getEventHostname(event: MessageEvent) {
	try {
		return new URL(event.origin).hostname;
	} catch (e) {
		return '';
	}
}

/*@ngInject*/
function previewEventsBinder($rootScope: ng.IRootScopeService, $window: Window, appConfig: AppConfig) {
	$window.addEventListener('message', (event) => {
		// Limit message events to ones coming from the back-office
		// only to be used for preview inside helpcenter settings
		const eventHostname = getEventHostname(event);
		if (eventHostname !== appConfig.defaultHost) {
			return;
		}
		try {
			var data = JSON.parse(event.data);
			if (data.action) {
				$rootScope.$broadcast(data.action, data.data);
			}
		} catch (e) {
			// console.error(e);
		}
	}, false);
};
export = previewEventsBinder;
