const {create} =  require('@wix/fedops-logger');

export type HelpcenterInteraction = 'article-view' | 'search';

type FedopsLogger = {
	appLoaded: () => void;
	interactionStarted: (name: HelpcenterInteraction) => void;
	interactionEnded: (name: HelpcenterInteraction) => void;
}

export const initFedopsLogger = () => {
	const w: any = window;
	w.__fedopsLogger = create('answers-helpcenter');
}

const getLogger = (): FedopsLogger | null => {
	const w: any = window;
	return w.__fedopsLogger;
};

export const triggerAppLoaded = () => {
	const logger = getLogger();
	if (!logger) {
		console.warn('Cannot log app loaded because fedops logger was not initialized');
	} else {
		getLogger().appLoaded();
	}
};


export const startInteraction = (name: HelpcenterInteraction) => {
	const logger = getLogger();
	if (!logger) {
		console.warn('Cannot log interaction started because fedops logger was not initialized');
	} else {
		logger.interactionStarted(name);
	}
};

export const endInteraction = (name: HelpcenterInteraction) => {
	const logger = getLogger();
	if (!logger) {
		console.warn('Cannot log interaction ended because fedops logger was not initialized');
	} else {
		logger.interactionEnded(name);
	}
};
