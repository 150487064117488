import {AppConfig} from '@wix/answers-api';

'use strict';

/*@ngInject*/
export default function innerLinkDirectiveFactory(appConfig: AppConfig): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		bindToController: true,
		link: function(scope, elem, attr: any) {
			attr.$observe('innerLink', function (value) {
				if ((attr.forceDisableInnerLink === 'false' || attr.forceDisableInnerLink === undefined) && value) {
					elem.attr('href', appConfig.helpCenterUrls[appConfig.lang] + value);
					if (attr.forceServerSideRoutingLink === 'true') {
						elem.attr('target', '_self')
					}
				} else {
					elem.attr('href', null);
				}
			});
		}
	};
}
