	/**
* Created by Elad_Swissa on 7/8/15.
*/
/// <reference path="../../references.ts"/>
'use strict';

import UserToolbarService from '../../structure/user-toolbar/user-toolbar.srv';
import ArticleService from '../article-service/article.srv';
import FollowService from '../../common/services/follow/follow.srv';
import * as ans from '@wix/answers-api';
import Auth from './../../auth/auth.srv';
import CategoryService from './../../common/category/category.srv';
import Prerender from './../../common/prerender/prerender.srv';
import Sdk from './../../common/sdk/sdk.srv';
import {PageType} from './../../common/sdk/sdk.srv';
import * as Serdes from '@wix/answers-article-html-serdes';
import PublicContactSettings from '../../common/contact-settings/public-contact-settings.i';
import {ArticleContext} from '@wix/answers-common-components';
import { ContactMethodType } from '@wix/answers-api';
import { startInteraction } from '../../fedops';
import BiService from '../../common/bi/bi.srv';
import { PublicEventType } from '@wix/answers-bi';
import SubmitTicketService from '../../submit/submit-a-ticket-service/submit-a-ticket.srv';

class PageDirectiveController {

	article: ans.Article;
	relatedItems: ans.Article[];
	isFollowedByUser: boolean = false;
	notHelpful: boolean = null;
	isFollowComplete: boolean = true;
	categoryParents: ans.Category[];
	isUserAgent: boolean = false;
	isLoggedIn: boolean = false;
	hideRating: boolean = false;
	appUrl: string;
	articleComponents: any;
	currentAnchorId: string;
	submitTicketInProcess: boolean = false;
	isSubmited: boolean = false;
	isSelfResolvedIssue: boolean = false;
	guestContactMode: boolean = false;
	articleSeoVersion: string;
	textEditorOptions;
	textEditorFocus: boolean = false;
	hasAnchors: boolean = false;
	anchorLinks: any;
	isSeoVersion: boolean = false;
	submitedTicket: ans.Ticket;
	reCaptchaValue: string;
	contactFormLocale: string;

	newContactForm: boolean = true;
	isTicketingEnabled: boolean = true;

	// ... NEW!
	contactFromArticlePageEnabled: boolean = true;
	calculatedContactUsLink: string;

	guestContactFormEnabled: boolean = false;

	/*@ngInject*/
	constructor(private articleService: ArticleService,
		textEditorOptions,
		private auth: Auth,
		appConfig: ans.AppConfig,
		private followService: FollowService,
		private userToolbarService: UserToolbarService,
		private brandingConfig,
		private submitTicketService: SubmitTicketService,
		private reCaptchaKey: string,
		contactUsLink: string,
		$window: Window,
		$document,
		private $rootScope,
		private $scope,
		$timeout,
		private $filter,
		private $location,
		prerender: Prerender,
		private sdk: Sdk,
		private biService: BiService,
		helpcenterContactSettings: PublicContactSettings,
		private categoryService: CategoryService) {
			this.isLoggedIn = this.auth.isLoggedIn();
			this.contactFormLocale = appConfig.lang;

			if (this.isLoggedIn) {
				this.followService.isFollowedByUser(this.article)
				.then((isFollowed) => {
					this.isFollowedByUser = isFollowed;
					this.updateFollowContext(isFollowed);
				});
			}

			textEditorOptions.toolbarExternal = '.text-editor-toolbar';
			this.textEditorOptions = textEditorOptions;

			this.isUserAgent = auth.isAgent();

			this.setCategoryParents(this.article.categoryId);
			this.appUrl = `${appConfig.appUrl}/${ans.appUrlsMapping.helpCenterBase}/article/${this.article.id}?locale=${this.article.locale}`;

			this.articleComponents = Serdes.deserialize(this.article.content, this.article.locale);
			this.hasAnchors = this.articleComponents.filter(c => c.type === 'heading' && !!c.value.anchorId).length > 0;

			this.hideRating = this.article.resolution && this.article.resolution === ans.ArticleResolution.RELEASED;

			this.guestContactFormEnabled = this.submitTicketService.isGuestContactFormEnabled();

			this.guestContactMode = !this.isLoggedIn && this.guestContactFormEnabled;

			this.sdk.setViewedPageData(PageType.article, this.article.id, {article: this.article});
			$scope.$watch('$viewContentLoaded', () => {
				this.biService.logEvent(PublicEventType.ARTICLE_PAGE_LOAD, {
					article: this.article,
					category: this.categoryParents[0],
					subcategory: this.categoryParents[1]
				});
			});

			this.$scope.$on('itemRated', (event, value) =>  {
				this.isFollowedByUser = value || this.isFollowedByUser;
			});

			this.$scope.$on('helpful', (event, value) =>  {
				this.notHelpful = value === false;
			});

			if (this.newContactForm && helpcenterContactSettings) {
				const contactMethod = helpcenterContactSettings.contactMethod;

				if (contactMethod.type === ContactMethodType.EXTERNAL) {
					this.calculatedContactUsLink = contactMethod.externalUrl;
				} else if (contactMethod.type === ContactMethodType.EMAIL) {
					this.calculatedContactUsLink = `mailto:${contactMethod.email}`;
				}
			}

			this.contactFromArticlePageEnabled = this.newContactForm ? helpcenterContactSettings.contactFromArticlePageEnabled : true;
			this.calculatedContactUsLink = this.newContactForm ? this.calculatedContactUsLink : contactUsLink;

			this.$rootScope.$watch('showUserToolbar', (newValue, oldValue) => {
				if (newValue) {
					this.userToolbarService.setArticleContextView(this.getUserToolbarArticleContext());
				}
			});

			startInteraction('article-view');

			$timeout(() => {
				this.anchorLinks = $('.text-component a').each((idx, elem) => {
					if ($(elem).attr('href').replace(/#(.*)$/g, '') === $location.absUrl().replace(/#(.*)$/g, '')) {
						$(elem).unbind('click').click(function (e) {
							if (!this.anchorChanging) {
								this.anchorChanging = true;
								e.preventDefault();
								e.stopPropagation();
								var $this = $(this);
								if ($this.attr('href').length > 0 ) {
									var newHash = $this.attr('href').match(/#(.*)$/g)[0];
									var anchorId = `[id=${newHash.replace('#', '')}]`;

									$('body,html').stop(true, true).animate({
										scrollTop: $(anchorId).offset().top - 150
									}, 500, 'swing', () => {
										this.anchorChanging = false;

										if ($(anchorId).length > 0) {
											$window.history.pushState({}, '', document.location.pathname + newHash);
										}
									});
								}

								return false;
							}
						});
					}
				});
			}, 1000);
		}

		getUserToolbarArticleContext = (): ArticleContext => {
			return {
				article: this.article,
				updated: this.$filter('amDateFormat')(this.article.lastUpdateDate, 'LL'),
				created: this.$filter('amDateFormat')(this.article.creationDate, 'LL'),
				onFollowToggle: (toggleFollow: boolean) => this.follow(),
				isFollowing: this.isFollowedByUser
			};
		};

		setCategoryParents = (categoryId: string) => {
			this.categoryParents = [];
			var category = this.categoryService.getCachedCategoryById(categoryId);
			while (category) {
				this.categoryParents.unshift(category);
				category = this.categoryService.getCachedCategoryById(category.parentId);
			}
		};

		onContactFormClose() {
			this.notHelpful = false;
			this.$scope.$apply();
		}

		submitLinkedTicket(ticketData: ans.ContactFormSubmitData): ng.IPromise<string> {
			if (!this.submitTicketInProcess) {
				this.submitTicketInProcess = true;

				return this.articleService.submitLinkedTicket(this.article, ticketData, this.guestContactMode)
				.then((newTicket) => {
					this.submitedTicket = newTicket;
					this.isSubmited = true;
					return newTicket.id
				});
			}
		}

		logEvent = (type: PublicEventType, data: any) => {
			this.biService.logEvent(type, {...data, contactFormLocation: 'article', sourceArticle: this.article});
		}

		handleIssueResolved() {
			this.isSelfResolvedIssue = true;
			this.$scope.$apply();
		}

		signin() {
			return this.auth.accountRedirect('signin');
		}

		updateFollowContext(isFollowed: boolean) {
			const updatedContext: ArticleContext = {...this.getUserToolbarArticleContext(), isFollowing: isFollowed};
			this.userToolbarService.toggleArticleFollow(updatedContext);
		}

		follow() {
			if (this.auth.isLoggedIn()) {
				this.isFollowComplete = false;
				let oldState = this.isFollowedByUser;
				let newState = !oldState;

				this.updateFollowContext(newState);
				this.isFollowedByUser = newState;
				this.followService.followToggle(this.article, newState)
				.catch(() => {
					this.isFollowedByUser = oldState;
					this.updateFollowContext(oldState);
				})
				.finally(() => this.isFollowComplete = true);
			} else {
				return this.auth.accountRedirect('signin');
			}
		}
	}

	function articlePageDirectiveFactory(): ng.IDirective {
		return <ng.IDirective>{
			template: require('./article-page.tpl.ts'),
			scope: {
				article: '=',
				// articleCounters: '=',
				relatedItems: '='
			},
			restrict: 'E',
			controllerAs: 'page',
			bindToController: true,
			controller: PageDirectiveController
		};
	}

	export default articlePageDirectiveFactory;
