
/* tslint:disable */
const templateData = `
<div class="user-avatar avatar-{{::avatar.getDefaultAvatarClass()}}" ng-class="{'default-avatar avatar': !avatar.user.profileImage}">
    <img ng-if="avatar.user.profileImage" ng-src="{{::avatar.user.profileImage}}" alt="{{::avatar.user | userDisplayName}}'s profile image" ng-error="avatar.user.profileImage = ''">
    <div ng-if="!avatar.user.profileImage" class="default-avatar-inner">{{::avatar.user | userDisplayName | limitTo: 1}}</div>
</div>

`;
export = templateData;
