/// <reference path="../../references.ts" />
import {userDisplayName} from '../common.mdl';

'use strict';

import * as ans from '@wix/answers-api';

export default function userLinkDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		scope: {
			user: '=',
			prefixKey: '@'
		},
		template: require('./user-link.tpl.ts'),
		link: function (scope: any) {
			scope.linkHtml = '<a href="ugc/' + scope.user.id + '">' + userDisplayName(scope.user) + '</a>';
		}
	};
}
