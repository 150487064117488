/**
* Created by Elad_Swissa on 7/20/15.
*/

import * as ans from '@wix/answers-api';
import ItemRatingService from './item-rating.srv';

class ItemRatingDirectiveController {

	item: ans.Article;
	showUnhelpful: boolean;
	ratingValue: boolean = null;
	itemWasVoted: boolean = false;
	ArticleType;

	/*@ngInject*/
	constructor (
		private itemRatingService: ItemRatingService,
		private $scope: ng.IScope) {

		if (!this.item) {
			throw 'You must include item to use this directive';
		}

		this.ArticleType = ans.ArticleType;
		this.ratingValue = this.itemRatingService.getItemData(this.item);
	}

	rateItem (newRating) {
		if (newRating !== this.ratingValue) {
			this.itemRatingService.storeItemData(this.item, newRating);
			this.itemRatingService.rateItem(this.item, newRating, this.ratingValue);
			this.ratingValue = newRating;
		}
		this.itemWasVoted = true;
		this.$scope.$emit('helpful', newRating);
	}

	voteItem () {
		// var newValue = (this.ratingValue) ? null : true;
		this.ratingValue = !this.ratingValue;
		this.itemRatingService.storeItemData(this.item, this.ratingValue);
		this.itemRatingService.voteItemToggle(this.item, this.ratingValue);
		this.ratingValue = this.ratingValue;
		this.itemWasVoted = true;
		this.$scope.$emit('itemRated', this.ratingValue);
	}
}

export default function itemRatingDirectiveFactory(): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'E',
		replace: true,
		transclude: true,
		template: require('./item-rating.tpl.ts'),
		scope: {
			item: '=',
			showUnhelpful: '='
		},
		bindToController: true,
		controllerAs: 'vm',
		controller: ItemRatingDirectiveController
	};
}
