export const safelyGetFromLocalStorage = (window: Window, item: string) => {
	try {
		return window.localStorage.getItem(item);
	} catch (e) {
		return null;
	}
};

export const safelySetFromLocalStorage = (window: Window, item: string, val: string) => {
	try {
		return window.localStorage.setItem(item, val);
	} catch (e) {
		return null;
	}
};

export const safelyGetLocalStorageKeys = (window: Window) => {
	try {
		return Object.keys(window.localStorage);
	} catch (e) {
		return [];
	}
}

export const safelyRemoveFromLocalStorage = (window: Window, item: string) => {
	try {
		return window.localStorage.removeItem(item);
	} catch (e) {
		return null;
	}
};
