import * as ans from '@wix/answers-api';

/*@ngInject*/
export default function previewChangeableText($filter, $timeout, $window): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		bindToController: true,
		link: function (scope, elem, attr: {previewChangeableText: string, replaceAttr: string}) {
			var textKey = attr.previewChangeableText;
			if (attr.replaceAttr) {
				elem.attr(attr.replaceAttr, $filter('translate')(textKey));
			} else {
				elem.text($filter('translate')(textKey));
			}

			scope.$on(ans.PreviewApiActions.changeText, (e, data) => {
				if (data.key === textKey) {
					scope.$apply(() => {
						if (attr.replaceAttr) {
							elem.attr(attr.replaceAttr, data.value);
						} else {
							elem.html(data.value);
						}
					});
				}
			});

			scope.$on(ans.PreviewApiActions.focusText, (e, data) => {
				if (data === textKey) {
					scope.$apply(() => {
						var windowHeight = Math.max(document.documentElement.clientHeight, $window.innerHeight || 0);
						$('body,html').stop(true, true).animate({ scrollTop: elem.offset().top - elem.height() / 2 - windowHeight / 2 }, 500);
						elem.addClass('change-text-active');
					});
				}
			});

			scope.$on(ans.PreviewApiActions.leaveText, (e, data) => {
				if (data === textKey) {
					scope.$apply(() => {
						elem.removeClass('change-text-active');
						// var currTop = $($window).scrollTop();
						// $timeout(() => {
						// 	if ($($window).scrollTop() === currTop) {
						// 		$('body,html').stop(true, true).animate({ scrollTop: 0 }, 500);
						// 	}
						// }, 2000);
					});
				}
			});
		}
	};
};
