/// <reference path="../references.ts" />

'use strict';

import * as ans from '@wix/answers-api';

export default class UsersService {
	/*@ngInject*/
	constructor(private $http: ng.IHttpService, private appConfig: ans.AppConfig, private $httpParamSerializer, private Upload) { }

	getCurrentUser(): ng.IPromise<ans.User> {
		return this.$http.get(this.appConfig.apiPath + '/users/me')
		.then((response) => response.data as any);
	}

	getUserFollowedContent(userId: string, page: number = 1, pageSize: number = 10): ng.IPromise<ans.SearchResult<ans.Article>> {
		return this.$http.get(this.appConfig.apiPath + '/articles/followed', {
			params: {
				userId: userId,
				page: page,
				pageSize: pageSize,
				locale: this.appConfig.lang
			}
		})
		.then((response) => response.data as any);
	}
}
