
/* tslint:disable */
const templateData = `
<div class="item-rating">
    <div ng-if="vm.item.type === vm.ArticleType.ARTICLE">
        <span class="rating-lable">{{'public.article.rating.vote.question' | translate}}</span>
        <a href="" ng-click="vm.rateItem(true)" class="yes" ng-class="{'active': vm.ratingValue}"><svg-icon name="v" class="middle"></svg-icon><span>{{'common.yes' | translate}}</span></a>
        <a href="" ng-click="vm.rateItem(false)" ng-class="{'active': vm.ratingValue === false}" class="no"><svg-icon name="x" class="middle"></svg-icon><span>{{'common.no' | translate}}</span></a>
        <div class="note bubble-yes" ng-show="vm.itemWasVoted && vm.ratingValue === true">
            <span>{{'public.article.rating.voted.question' | translate}}</span>
		</div>
		<div class="note bubble-no" ng-show="vm.itemWasVoted && (vm.showUnhelpful && vm.ratingValue === false)">
            <span>{{'public.article.rating.voted.no.question' | translate}}</span>
        </div>
    </div>
    <div ng-if="vm.item.type === vm.ArticleType.FEATURE_REQUEST">
        <a href="" ng-click="vm.voteItem()" class="vote iconed feature" ng-class="{'active': vm.ratingValue, 'voted': vm.itemWasVoted}">
			<svg-icon name="feature-request"></svg-icon>
			<span>{{'public.article.rating.vote.feature_request' | translate}}</span>
		</a>
        <div class="note" ng-show="vm.ratingValue">{{'public.article.rating.voted.feature_request' | translate}}</div>
    </div>
    <div ng-if="vm.item.type === vm.ArticleType.KNOWN_ISSUE">
        <a href="" ng-click="vm.voteItem()" class="vote iconed known" ng-class="{'active': vm.ratingValue, 'voted': vm.itemWasVoted}">
			<svg-icon name="known-issue"></svg-icon>
			<span>{{'public.article.rating.vote.bug' | translate}}</span>
		</a>
        <div class="note" ng-show="vm.ratingValue">{{'public.article.rating.voted.bug' | translate}}</div>
    </div>
</div>

`;
export = templateData;
