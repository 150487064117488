/// <reference path="../references.ts" />

'use strict';

import UserSessionInfo from '../auth/user-session-info.i';
import * as ans from '@wix/answers-api';
import { User } from '@wix/answers-api';
import { safelySetFromLocalStorage, safelyGetFromLocalStorage, safelyRemoveFromLocalStorage, safelyGetLocalStorageKeys } from '../common/local-storage';
import { exposedSdk } from '../common/sdk/sdk.srv';
var md5Imp = require('blueimp-md5');
var md5 = typeof md5Imp === 'function' ? md5Imp : md5Imp.md5;
var url = require('url');

export default class Auth {

	private clientCookieName = '_AC';
	private userInfoPrefix = 'AUI_'; //Answers ans.User Info
	private getUserApiUrl;
	private accountUrl: string;
	private currentUser: ans.User = null;
	private agentUser: User | null = null;

	/*@ngInject*/
	constructor(private $cookies,
		private appConfig: ans.AppConfig,
		private $window: Window,
		private $q: ng.IQService,
		private $http: ng.IHttpService,
		private previewMode: boolean,
		private $log: ng.ILogService) {
			this.getUserApiUrl = this.appConfig.apiPath + '/users/me';
			this.accountUrl = `${this.appConfig.path}/account`;
		}

		isLoggedIn() {
			return !this.previewMode && !!this.$cookies.get(this.clientCookieName);
		}

		init() {
			if (this.isLoggedIn()) {
				return this.getCurrentUserFromCacheOrServer()
				.then((user) => {
					this.currentUser = user;

					const onLoadCbs = exposedSdk.userLoadedCb || [];

					onLoadCbs.forEach(cb => {
						try {
							cb(user);
						} catch (e) {
							console.error('Error running user loaded cb', e);
						}
					});

					safelySetFromLocalStorage(this.$window, this.getUserInfoKey(), JSON.stringify(user));
					return user;
				});
			} else {
				const onLoadCbs = exposedSdk.userLoadedCb || [];
				var dfd = this.$q.defer();
				onLoadCbs.forEach(cb => {
					try {
						cb();
					} catch (e) {
						console.error('Error running user loaded cb', e);
					}
				});
				dfd.resolve();
				return dfd.promise;
			}
		}

		getCurrentUser() {
			return this.currentUser;
		}

		private updateCurrentUser() {
			safelySetFromLocalStorage(this.$window, this.getUserInfoKey(), JSON.stringify(this.currentUser));
		}

		updateCurrentUserFullName() {
			this.updateCurrentUser();
		}

		isAgent() {
			var isAgent: boolean = false;
			if (this.currentUser && this.currentUser.permissionLevel) {
				isAgent = this.currentUser.permissionLevel >= ans.PermissionLevel.AGENT;
			}
			return isAgent;
		}

		getUserInfoKey(): string {
			var cookieValue = this.$cookies.get(this.clientCookieName);
			return cookieValue ? this.userInfoPrefix + md5(cookieValue) : null;
		}

		getUserFromServer() {
			return this.$http.get(this.getUserApiUrl)
				.then((response) => response.data, () => null);
		}

		getCurrentUserFromCacheOrServer(): ng.IPromise<ans.User> {
			var userInfoKey = this.getUserInfoKey();
			var lsValue = safelyGetFromLocalStorage(this.$window, userInfoKey) || '';
			var localStoredUserInfo = null;
			var dfd = this.$q.defer();

			if (lsValue) {
				try {
					localStoredUserInfo = JSON.parse(lsValue);
				} catch (e) {
					this.$log.warn('ans.User info value invalid!', e);
				}
			}

			if (localStoredUserInfo) {
				dfd.resolve(localStoredUserInfo);
			} else {
				this.$http.get(this.getUserApiUrl)
				.then((response) => dfd.resolve(response.data), (err) => dfd.resolve(null));
			}

			return dfd.promise as ng.IPromise<ans.User>;
		}

		removeFromStorage() {
			safelyGetLocalStorageKeys(this.$window)
				.filter((key) => key && key.indexOf(this.userInfoPrefix) === 0)
				.forEach((key) => safelyRemoveFromLocalStorage(this.$window, key));
		}

		logout() {
			let parsedUrl = url.parse(this.appConfig.baseUrl);
			this.$cookies.remove(this.clientCookieName, {
				domain: this.appConfig.baseHost,
				path: parsedUrl.path
			});
			this.removeFromStorage();
		}

		accountRedirect(action: string) {
			const currentUrl = this.$window.location.href;
			const locale = this.appConfig.lang;
			this.$window.location.href = `${this.accountUrl}/${action}?redirectUrl=${encodeURIComponent(currentUrl)}&locale=${locale}`;
		}

		goToSignin() {
			return this.accountRedirect('signin');
		}

		goToSignup() {
			return this.accountRedirect('signup');
		}

		goToSignout() {
			this.removeFromStorage();
			return this.accountRedirect('logout');
		}

		setAgentUser(user: User) {
			this.agentUser = user;
		}

		getAgentUser() {
			return this.agentUser;
		}
	}
