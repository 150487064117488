
/* tslint:disable */
const templateData = `
<footer>
    <div id="need-more-help" ng-class="{'hf-light': footer.isLightTheme, 'hf-dark': footer.isDarkTheme}" ng-if="::footer.isContactButtonVisible">
		<div class="need-help-contact">
			<div class="need-help-titles">
		        <div class="need-more-help-title" preview-changeable-text="public.footer.need.help.title">{{::'public.footer.need.help.title' | translate}}</div>
		        <div class="need-more-help-text" preview-changeable-text="public.footer.need.help.text">{{::'public.footer.need.help.text' | translate}}</div>
			</div>
	        <a class="need-more-help-btn btn"
				href="{{footer.calculatedContactUsLink}}"
				ng-mousedown="footer.onBeforeContactClick()"
				preview-changeable-text="public.footer.need.help.btn">
					{{::'public.footer.need.help.btn' | translate}}
			</a>
		</div>
	</div>
    <!-- <div class="branding">
		<div class="branding-content">
	        <div id="footer-tenant-name">
	            <span class="copyright">{{::'public.footer.copyright' | translate}} © {{::footer.date | date: 'yyyy'}}</span>
	            <span class="tenant-name">{{::footer.companyName}}</span>
	        </div>
	        <div id="answers-branding" ng-if="::!footer.brandingConfig.whiteLabel">
				<a href="http://www.wixanswers.com/" target="_blank">
					<span class="powered-by" ng-bind-html="::'common.powered-by-answers.colored' | translate"></span>
				</a>
	        </div>
		</div>
    </div> -->
	<div id="custom-footer" ng-if="footer.trustedFooter" ng-bind-html="footer.trustedFooter">
	</div>
	<div id="freemium-banner" ng-if="::footer.showAnswersBanner">
		<span class="banner-text first">{{::'public.footer.freemium-banner.text' | translate}}</span>
		<span class="banner-text mobile first">{{::'public.footer.freemium-banner.text-mobile' | translate}}</span>
		<svg-icon name="answers"></svg-icon>
		<span class="banner-text last">{{::'public.footer.freemium-banner.extra-text' | translate}}</span>
		<span class="banner-text mobile last">{{::'public.footer.freemium-banner.extra-text-mobile' | translate}}</span>
		<a class="try-free-link" href="{{footer.marketingSiteUrl}}" target="_blank">{{::'public.footer.freemium-banner.link' | translate}}</a>
		<a class="try-free-link mobile" href="{{footer.marketingSiteUrl}}" target="_blank">{{::'public.footer.freemium-banner.link-mobile' | translate}}</a>
	</div>
</footer>

`;
export = templateData;
