
/* tslint:disable */
const templateData = `
<main-header user="main.currentUser" ng-class="{'toolbar-expanded': main.$rootScope.isToolbarExpanded, 'with-user-toolbar': main.$rootScope.showUserToolbar}"></main-header>
<hero></hero>
<loader ignore-by-state-data="true" class="main-loader"></loader>
<ui-view class="main main-view"></ui-view>
<main-footer></main-footer>
`;
export = templateData;
