import ArticleService from './../../article/article-service/article.srv';
import * as ans from '@wix/answers-api';
import CategoryPageService from './../category-page.srv';
import Prerender from './../../common/prerender/prerender.srv';
import CategoryService from './../../common/category/category.srv';
import SeoManager from './../../seo/seo-manager.srv';
import TemplateConfigService from '../../common/template-config/template-config.srv';
import Sdk from '../../common/sdk/sdk.srv';
import { filterCategories } from '../../common/content-filters';
import { PublicEventType } from '@wix/answers-bi';
import BiService from '../../common/bi/bi.srv';

const looksLikeUnsupportedLocale = (path: string) => {
	const PROBABLE_LANG_LENGTH = 2;
	const stripSlash = path => path.replace(/^\//, '');
	return stripSlash(path).length === PROBABLE_LANG_LENGTH;
};

/*@ngInject*/
function categoryPageRouting($stateProvider: ng.ui.IStateProvider) {

	$stateProvider
	.state('app.kb-cat-id', {
		url: '/categories/id/:id?locale',
		resolve: {
			category: function ($stateParams, $state: ng.ui.IStateService, $location: ng.ILocationService, $window: Window, categoryService: CategoryService, prerender: Prerender, appConfig: ans.AppConfig, biService: BiService): any {
				return categoryService.getTree($stateParams.locale || appConfig.lang)
					.then(() => Promise.resolve(categoryService.getCachedCategoryById($stateParams.id)))
					.then(category => category || categoryService.getTree(appConfig.lang)
						.then(() => categoryService.getCachedCategoryById($stateParams.id)))
					.then(category => {
						if (!category) {
							prerender.setStatusCode(ans.HttpStatus.NOT_FOUND);
							$state.go('app.404');
							biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'category', reason: '404', url: $location.absUrl()}});
							return;
						}
						if (prerender.isPrerenderMode()) {
							prerender.setPermanentRedirect(category.url);
						}
						$location.path(category.uri).search('locale', null).replace();
					})
					.catch(() => {
						prerender.setStatusCode(ans.HttpStatus.NOT_FOUND);
						$state.go('app.404');
						biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'category', reason: '404', url: $location.absUrl()}});
					})
			}
		},
	})
	.state('app.category', {
		template: '<div class="category-page"><category-page-nav category="ctrl.category" ng-class="{\'new-cat-page\': ctrl.newCategoryPage}"></category-page-nav><loader class="category-loader"></loader><ui-view></ui-view></div>',
		resolve: {
			category: function ($stateParams, $state: ng.ui.IStateService, $location: ng.ILocationService, $window: Window, categoryService: CategoryService, prerender: Prerender, appConfig: ans.AppConfig, biService: BiService): any {
				let uri = $location.path();
				return categoryService.getByUri(uri)
				.then((category: any) => {
					// if has children go to first child
					return categoryService.init()
					.then(() => {
						var cachedCategory = categoryService.getCachedCategoryById(category.id);
						if (cachedCategory && cachedCategory.children && cachedCategory.children.length) {
							var firstChildrenCategory = cachedCategory.children[0];
							prerender.setPermanentRedirect(firstChildrenCategory.url);
							// $state.go('app.category.content', {categoryUri: firstChildrenCategory.uri});
							$location.path(firstChildrenCategory.uri);
						} else {
							if (uri !== category.uri) {
								if (prerender.isPrerenderMode()) {
									prerender.setPermanentRedirect(category.url);
								}
								$location.path(category.uri).replace();
							}

							return category;
						}
					});
				})
				.catch(res => {
					let currentLang = appConfig.lang;
					// console.log('sdkl;ckssld;c', res.status);

					switch (res.status) {
						case ans.HttpStatus.NOT_FOUND:
							if (looksLikeUnsupportedLocale(uri)) {
								const newUrl = `/${currentLang}`;
								prerender.setPermanentRedirect(newUrl);
								$window.location.replace(newUrl);
								return;
							}
							prerender.setStatusCode(ans.HttpStatus.NOT_FOUND);
							$state.go('app.404');
							biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'category', reason: '404', url: $location.absUrl()}});
							break;
						case ans.HttpStatus.BAD_REQUEST:
							let errorPayload = res.data && res.data.payload || {};
							var correctPermalink = errorPayload.uri;
							var correctLang = errorPayload.locale;
							let newUrl = appConfig.helpCenterUrls[correctLang] + correctPermalink;
							if (correctPermalink && correctLang) {
								prerender.setPermanentRedirect(newUrl);
								if (currentLang === correctLang) {
									$location.path(correctPermalink).replace();
									$state.go('app.category.content', {categoryUri: correctPermalink}, {reload: true});
								} else {
									$window.location.replace(newUrl);
								}
							} else {
								$state.go('app.error', {error: res});
								biService.logger.logEvent({type: PublicEventType.PAGE_LOAD_ERROR, data: {pageType: 'category', reason: `${res.status}`, url: $location.absUrl()}});
							}
							break;
					}
				});
			}
		},
		controller: function (category: ans.Category, templateService: TemplateConfigService) {
			this.rand = Math.random();
			this.category = category;
			this.newCategoryPage = templateService.useNewScrollingLogic();
		},
		controllerAs: 'ctrl'
	})
	.state('app.category.content', {
		// url: '/{categoryPermalink:.+, }',
		url: '/{categoryUri:[^?]+}?type',
		template: '<category-page results="ctrl.results" category="ctrl.category" available-types="ctrl.availableTypes" type="ctrl.type" uri="ctrl.uri" ng-class="{\'new-cat-page\': ctrl.newCategoryPage, \'show-anchor-menu\': ctrl.showAnchorMenu}"></category-page>',
		controllerAs: 'ctrl',
		reloadOnSearch: false,
		data: {
			ignoreLoader: true
		},
		resolve: {
			categoryAndResults: function(categoryPageService: CategoryPageService,
				$stateParams, categoryService: CategoryService,
				sdk: Sdk,
				seoManager: SeoManager) {
				const uri = '/' + $stateParams.categoryUri;
				var type;
				if ($stateParams.type) {
					type = ans.ArticleType[$stateParams.type.toUpperCase().replace(/-/g, '_')];
				}
				return categoryPageService.searchsByCategoryUri(uri, type).then((res) => {
					const category = res.category;
					const parentCategory = categoryService.getCachedCategoryById(category.parentId);
					const placeholderKeys = ans.pagePlaceholderKeys.CATEGORY;
					let seoPlaceholders = {};
					seoPlaceholders[placeholderKeys.CATEGORY_TITLE] = category.name;
					seoPlaceholders[placeholderKeys.PARENT_CATEGORY_TITLE] = (parentCategory ? parentCategory.name : '');

					const hiddenInSdk = filterCategories([category], sdk.getContentFilters()).length !== 1;

					seoManager.fetchAndSetData(ans.PageType.CATEGORY, category.locale, seoPlaceholders, hiddenInSdk);
					seoManager.setAlternateLanguageMetaTags(category.translations || []);
					return res;
				});
			}
		},
		controller: function($stateParams, categoryAndResults, categoryService: CategoryService, $location: ng.ILocationService, templateService: TemplateConfigService, sdk: Sdk) {
			this.tree = filterCategories(categoryService.categoryTree, sdk.getContentFilters());
			this.category = categoryAndResults.category;
			this.availableTypes = categoryAndResults.availableTypes.sort();
			this.uri = $stateParams.categoryUri;
			this.results = categoryAndResults.results;
			this.type = categoryAndResults.availableTypes[0];
			this.newCategoryPage = templateService.useNewScrollingLogic();
			this.showAnchorMenu = templateService.showCategoryTypeAnchorMenu();
		}
	});
}

export default categoryPageRouting;
