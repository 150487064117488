/// <reference path="../../references.ts" />

'use strict';

/*@ngInject*/
export default function timeStampDirectiveFactory(dateFilter): ng.IDirective {
	return <ng.IDirective>{
		restrict: 'A',
		bindToController: true,
		link: function (scope, elem, attr: any) {
			var timestamp = (/^\d+$/.test(attr.timestamp)) ? parseInt(attr.timestamp, 10) : attr.timestamp;
			var date = new Date(timestamp);
			elem.text(dateFilter(date, 'longDate'));
		}
	};
}
