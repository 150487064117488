const iconsMap = {
	'category-article': '',
	'article': require('./article-icon-light.svg'),
	'article-bold': require('./article-icon-bold.svg'),
	'article-followed': require('./followed-article.svg'),
	'category-home-icon': '',
	'ticket': require('./ticket.svg'),
	'ticket-followed': require('./followed-ticket.svg'),
	'search': require('./search.svg'),
	'home': require('./home.svg'),
	'follow': '',
	'v': '',
	'x': require('./x-icon.svg'),
	'feature-request': require('./vote.svg'),
	'back': require('./back-btn.svg'),
	'back-home': require('./back-btn.svg'),
	'known-issue': require('./issue.svg'),
	'stats': require('./stats.svg'),
	'manage': '',
	'notifications': '',
	'open': require('./open.svg'),
	'cancel': require('./cancel.svg'),
	'unfollow': '',
	'reply': '',
	'empty-search': '',
	'globus': require('./globus.svg'),
	'clock': require('./clock.svg'),
	'cat-dd-menu': require('./cat-dd-menu.svg'),
	'answers': require('./answers.svg'),
};

export default iconsMap;
