
/* tslint:disable */
const templateData = `
<section class="tickets-container">
	<span class="showing-result" ng-show="ctrl.searchTerm" ng-bind-html="'public.tickets.search.showing-result' | translate: {searchTerm: ctrl.searchTerm}"></span>
	<ul class="tickets">
		<li class="ticket" ng-repeat="ticket in ctrl.results.items"
		sdk-event-trigger="ticketLinkClick"
		sdk-event-data="{ticket: ticket, order: $index}" ng-if="ctrl.results.itemsCount > 0" data-type="ticket">
			<a inner-link="{{::ticket.uri}}" class="ticket-link" data-view="{{::'common.view' | translate}}">
				<div class="ticket-details">
					<div class="author">
						<user-avatar user="ticket.user"></user-avatar>
						<div class="creation-date">{{::ticket.creationDate | amTimeAgo}}</div>
						<span class="by-author-display-name">
							<span>{{::'common.by' | translate}}</span>
							<span class="author-display-name">{{ticket.user | userDisplayName}}</span>
						</span>
					</div>
					<div class="replys" ng-show="ticket.repliesCount">
						<svg-icon name="reply"></svg-icon>
						<span class="replys-count">{{::ticket.repliesCount}}</span> <span ng-if="ticket.repliesCount > 1">{{::'common.replies' | translate}}</span><span ng-if="ticket.repliesCount === 1">{{::'common.reply' | translate}}</span>
					</div>
					<div class="answered-label" ng-if="ticket.hasAgentReply">
						{{'public.tickets.answered'| translate}}
					</div>
				</div>
				<div class="ticket-title">{{::ticket.subject}}</div>
				<div class="ticket-content">{{::ticket.content | htmlToText}}</div>
			</a>
		</li>
	</ul>

	<div class="empty-search" ng-if="ctrl.results.itemsCount === 0">
		<div class="empty-search-icon">
			<svg-icon name="empty-search"></svg-icon>
		</div>
		<div class="empty-search-msg" ng-bind-html="'public.search.no.results' | translate"></div>
		<div class="empty-search-msg-sub" ng-bind-html="'public.search.no.results-sub' | translate"></div>
	</div>
</section>
<section class="bottom-ticket">
	<ul id="pagination" ng-if="ctrl.results.itemsCount > ctrl.pageSize">
		<a class="first arrows"
		ng-hide="ctrl.pageNum < 3"
		sdk-event-trigger="ticketsPaginationClick"
		ui-sref=".({page: 1})">&laquo;</a>
		<a class="previous arrows"
		ng-hide="ctrl.pageNum < 2"
		sdk-event-trigger="ticketsPaginationClick"
		ui-sref=".({page: ctrl.pageNum - 1})">&lsaquo;</a>
		<a class="page"
		ng-repeat="num in ctrl.visiblePages"
		sdk-event-trigger="ticketsPaginationClick"
		ng-class="{active: num === ctrl.pageNum}"
		ui-sref=".({page: num})">{{num}}</a>
		<a class="next arrows"
		ng-hide="ctrl.pageNum > ctrl.totalPages - 1"
		sdk-event-trigger="ticketsPaginationClick"
		ui-sref=".({page: ctrl.pageNum + 1})">&rsaquo;</a>
		<a class="last arrows"
		ng-hide="ctrl.pageNum > ctrl.totalPages - 2"
		sdk-event-trigger="ticketsPaginationClick"
		ui-sref=".({page: ctrl.totalPages})">&raquo;</a>
	</ul>
	<a ui-sref="app.submit" class="submit-btn" sdk-event-trigger="submitTicketClicked">{{'public.ticket.submit.new' | translate}}</a>
</section>

`;
export = templateData;
