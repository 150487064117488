import Tickets from './../../ugc/ticket-service/tickets.srv';
import VisibilityService from './../services/visibility/visibility.srv';

/*@ngInject */
export default function isTicketingEnabledDirectiveFactory(visibilityService: VisibilityService): ng.IDirective {
	return <ng.IDirective>{
	restrict: 'A',
	link: function(scope, elem) {
		if (!visibilityService.isTicketingVisible()) {
				elem.remove();
			}
		}
	};
}
